import { Box, useBreakpointValue } from '@gluestack-ui/themed';
import { Pressable } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import { calcSizeForDevice } from 'utils/index';

import { Icons } from 'components/Icons';

type SectionArrowsProps = {
  side: 'right' | 'left';
  onPress: () => void;
  isVisible: boolean;
};

const SectionArrows: React.FC<SectionArrowsProps> = ({ side, onPress, isVisible }) => {
  const gradientColors = { right: ['#070d31', 'transparent'], left: ['transparent', '#070d31'] };
  const controllerVisibility = useBreakpointValue({ xl: 'flex', lg: 'flex', base: 'none' });
  const isRightSide = side === 'right';
  const iconsSize = {
    width: calcSizeForDevice(24, 32).toString(),
    height: calcSizeForDevice(24, 32).toString(),
  };

  return (
    <Pressable
      w={calcSizeForDevice(64)}
      top="$0"
      h="$full"
      left={isRightSide ? undefined : '$0'}
      right={isRightSide ? '$0' : undefined}
      position="absolute"
      alignItems="center"
      onPress={onPress}
      justifyContent="center"
      display={isVisible ? 'flex' : 'none'}>
      <LinearGradient
        end={{ x: 0, y: 0 }}
        start={{ x: 1, y: 0 }}
        tvParallaxShiftDistanceX={10}
        colors={gradientColors[side]}
        style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
      />

      <Box display={isVisible ? controllerVisibility : 'none'} zIndex={2}>
        {isRightSide ? <Icons.ChevronRight {...iconsSize} /> : <Icons.ChevronLeft {...iconsSize} />}
      </Box>
    </Pressable>
  );
};

export default SectionArrows;
