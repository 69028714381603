/* eslint-disable import/order */
import { Text, View } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect } from 'react';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import { usePlayer } from './context';

import { Cancel, Next } from 'components/Button/AutoNext';
import FastImage from 'components/FastImage';

let AUTO_NEXT_TIMEOUT: NodeJS.Timeout | undefined;

export function useAutoNext(props: {
  duration: number;
  currentTime: number;
  forceMobileView?: boolean;
}) {
  const { nextTrack } = usePlayer();
  const { duration, currentTime, forceMobileView } = props;
  const [showNextTrack, setShowNextTrack] = React.useState(false);
  const [autoNextTriggered, setAutoNextTriggered] = React.useState(false);

  // auto next when 96% is watched
  useEffect(() => {
    if (!duration) return;
    const watchedPercentage = (currentTime * 100) / duration;
    const timeRemaining = duration - currentTime;

    if (nextTrack && !autoNextTriggered && (watchedPercentage >= 96 || timeRemaining <= 30)) {
      setAutoNextTriggered(true);
      setShowNextTrack(true);

      if (Platform.OS === 'web') {
        const { setMemory } = (window as any).useMemory();
        setTimeout(() => setMemory([1, 1]), 0);
      }
    }
  }, [duration, currentTime, autoNextTriggered]);

  useEffect(() => {
    if (showNextTrack && AUTO_NEXT_TIMEOUT === undefined) {
      AUTO_NEXT_TIMEOUT = setTimeout(() => {
        nextTrack?.play();
        clearTimeout(AUTO_NEXT_TIMEOUT);
        AUTO_NEXT_TIMEOUT = undefined;
      }, 10000);
    }

    if (!showNextTrack) {
      clearTimeout(AUTO_NEXT_TIMEOUT);
      AUTO_NEXT_TIMEOUT = undefined;
    }

    return () => {
      clearTimeout(AUTO_NEXT_TIMEOUT);
      AUTO_NEXT_TIMEOUT = undefined;
    };
  }, [showNextTrack]);

  const webTvNextCard =
    showNextTrack && nextTrack ? (
      <LinearGradient
        style={{ width: '100%', flex: 1 }}
        colors={['transparent', '#000000bc']}
        locations={[0.1, 0.4]}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}>
        <View flex={1} w="$full" padding="$8" alignItems="flex-end" justifyContent="flex-end">
          <View w={calcSizeForDevice(520)} gap={calcSizeForTv(15)}>
            <View
              w="$full"
              rounded="$md"
              borderWidth={3}
              borderStyle="solid"
              borderColor="$white"
              h={calcSizeForDevice(290)}>
              <FastImage
                size="md"
                aspect="16x9"
                source={{ uri: nextTrack.cover }}
                style={{ width: '100%', height: '100%' }}
              />
            </View>

            <Text fontWeight="bold" fontSize="$xl" color="$white">
              {nextTrack?.title}
            </Text>

            <Text numberOfLines={2} fontSize="$md" color="$white">
              {nextTrack?.description}
            </Text>

            <View flexDirection="row" gap="$4" mt="$4">
              <Cancel onPress={() => setShowNextTrack(false)} />
              <Next useTVFocus onPress={nextTrack.play} />
            </View>
          </View>
        </View>
      </LinearGradient>
    ) : null;

  const nativeTvNextCard =
    showNextTrack && nextTrack ? (
      <View flex={1} w="$full" alignItems="flex-end" justifyContent="flex-end">
        <View w={calcSizeForTv(650)} gap={calcSizeForTv(15)}>
          <View
            w="$full"
            rounded="$md"
            borderWidth={3}
            borderStyle="solid"
            borderColor="$white"
            h={calcSizeForDevice(362.5)}>
            <FastImage
              size="md"
              aspect="16x9"
              source={{ uri: nextTrack.cover }}
              style={{ width: '100%', height: '100%' }}
            />
          </View>

          <Text fontWeight="bold" fontSize="$xl" color="$white">
            {nextTrack?.title}
          </Text>

          <Text numberOfLines={2} fontSize="$md" color="$white">
            {nextTrack?.description}
          </Text>

          <View flexDirection="row" gap="$4" mt="$4">
            <Cancel useTVFocus onPress={() => setShowNextTrack(false)} />
            <Next useTVFocus onPress={nextTrack.play} />
          </View>
        </View>
      </View>
    ) : null;

  const nativeNextCard =
    showNextTrack && nextTrack ? (
      <View flex={1} w="$full" mb="$2" alignItems="flex-end" justifyContent="flex-end">
        <Text fontWeight="bold" fontSize="$lg" color="$white">
          {nextTrack?.program}
        </Text>

        <Text fontSize="$md" color="$white">
          {nextTrack?.title}
        </Text>

        <View flexDirection="row" gap="$3" mt="$3">
          <Cancel mini onPress={() => setShowNextTrack(false)} />
          <Next mini useTVFocus onPress={nextTrack.play} />
        </View>
      </View>
    ) : null;

  if (Platform.OS === 'web') {
    return {
      showNextTrack,
      autoNextCard: forceMobileView ? nativeNextCard : webTvNextCard,
    };
  }

  if (Platform.isTV) {
    return {
      showNextTrack,
      autoNextCard: nativeTvNextCard,
    };
  }

  return {
    showNextTrack,
    autoNextCard: nativeNextCard,
  };
}
