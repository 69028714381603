import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Video: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12.88 20.8606H6.81C3.26 20.8606 2 18.3706 2 16.0506V7.95062C2 4.49062 3.35 3.14062 6.81 3.14062H12.88C16.34 3.14062 17.69 4.49062 17.69 7.95062V16.0506C17.69 19.5106 16.34 20.8606 12.88 20.8606ZM6.81 4.66062C4.2 4.66062 3.52 5.34062 3.52 7.95062V16.0506C3.52 17.2806 3.95 19.3406 6.81 19.3406H12.88C15.49 19.3406 16.17 18.6606 16.17 16.0506V7.95062C16.17 5.34062 15.49 4.66062 12.88 4.66062H6.81Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M20.7797 18.1105C20.3497 18.1105 19.7997 17.9705 19.1697 17.5305L16.4997 15.6605C16.2997 15.5205 16.1797 15.2905 16.1797 15.0405V8.96048C16.1797 8.71048 16.2997 8.48048 16.4997 8.34048L19.1697 6.47048C20.3597 5.64048 21.2297 5.88048 21.6397 6.09048C22.0497 6.31048 22.7497 6.88048 22.7497 8.33048V15.6605C22.7497 17.1105 22.0497 17.6905 21.6397 17.9005C21.4497 18.0105 21.1497 18.1105 20.7797 18.1105ZM17.6897 14.6405L20.0397 16.2805C20.4897 16.5905 20.8097 16.6205 20.9397 16.5505C21.0797 16.4805 21.2297 16.2005 21.2297 15.6605V8.34048C21.2297 7.79048 21.0697 7.52048 20.9397 7.45048C20.8097 7.38048 20.4897 7.41048 20.0397 7.72048L17.6897 9.36048V14.6405Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.5 11.75C10.26 11.75 9.25 10.74 9.25 9.5C9.25 8.26 10.26 7.25 11.5 7.25C12.74 7.25 13.75 8.26 13.75 9.5C13.75 10.74 12.74 11.75 11.5 11.75ZM11.5 8.75C11.09 8.75 10.75 9.09 10.75 9.5C10.75 9.91 11.09 10.25 11.5 10.25C11.91 10.25 12.25 9.91 12.25 9.5C12.25 9.09 11.91 8.75 11.5 8.75Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Video;
