import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ClockRotateLeft: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9531 11.5151C21.9612 6.31597 17.7294 2.0632 12.5302 2.04692C9.34663 2.03698 6.52845 3.60111 4.80859 6.00406V3.33595C4.80859 2.95195 4.49728 2.64063 4.11328 2.64063H3.74219C3.35819 2.64063 3.04688 2.95195 3.04688 3.33595V8.6797C3.04688 9.06369 3.35819 9.37501 3.74219 9.37501H9.08594C9.46993 9.37501 9.78125 9.06369 9.78125 8.6797V8.3086C9.78125 7.92461 9.46993 7.61329 9.08594 7.61329H5.86145C7.19732 5.33569 9.67062 3.8086 12.5 3.8086C16.765 3.8086 20.1914 7.26797 20.1914 11.5C20.1914 15.765 16.732 19.1914 12.5 19.1914C10.5207 19.1914 8.71852 18.4454 7.35656 17.2196C7.08149 16.9721 6.66146 16.985 6.4009 17.2455L6.13857 17.5078C5.85557 17.7908 5.87087 18.2508 6.16567 18.5171C7.84174 20.031 10.0635 20.9531 12.5 20.9531C17.7158 20.9531 21.945 16.729 21.9531 11.5151ZM12.3516 5.60938C11.9676 5.60938 11.6562 5.9207 11.6562 6.3047V12.5234L14.9092 14.8892C15.2198 15.1151 15.6546 15.0464 15.8805 14.7358L16.0551 14.4957C16.281 14.1852 16.2123 13.7503 15.9018 13.5245L13.3438 11.6641V6.3047C13.3438 5.9207 13.0324 5.60938 12.6484 5.60938H12.3516Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ClockRotateLeft;
