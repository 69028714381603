import mobileOrDesktop from 'hoc/mobileOrDesktop';

import HeroNative from './native';
import HeroWeb from './web';

export type HeroProgramProps = {
  children?: React.ReactNode;
  program: CmsProgram;
  media?: any;
  jsxBottom?: React.ReactNode;
  actualPodcast?: any;
  hideCover?: boolean;
  mediaId?: string;
  response?: {
    data: BackendFavorite;
  };
  numItems?: number;
  renderItem?: () => JSX.Element;
};

const Hero = mobileOrDesktop(HeroNative, HeroWeb);

export type HeroHeaderProps = {
  children?: React.ReactNode;
  program: CmsProgram;
  mediaId?: string;
  Aside?: React.ReactNode;
  isVideoOpen?: boolean;
};

const HeroHeader = mobileOrDesktop(HeroNative.Header, HeroWeb.Header);

export default Object.assign(Hero, {
  Header: HeroHeader,
});
