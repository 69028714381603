import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidUser: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12 12.5C13.364 12.5 14.6721 11.9469 15.6365 10.9623C16.601 9.97775 17.1429 8.64239 17.1429 7.25C17.1429 5.85761 16.601 4.52226 15.6365 3.53769C14.6721 2.55312 13.364 2 12 2C10.636 2 9.32792 2.55312 8.36345 3.53769C7.39898 4.52226 6.85714 5.85761 6.85714 7.25C6.85714 8.64239 7.39898 9.97775 8.36345 10.9623C9.32792 11.9469 10.636 12.5 12 12.5ZM10.1638 14.4688C6.20625 14.4688 3 17.7418 3 21.7818C3 22.4545 3.53438 23 4.1933 23H19.8067C20.4656 23 21 22.4545 21 21.7818C21 17.7418 17.7938 14.4688 13.8362 14.4688H10.1638Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidUser;
