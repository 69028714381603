import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidPauseCircular: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.52378 20.3779C9.63749 20.8492 10.8349 21.0908 12.0442 21.0884C13.2535 21.0907 14.4508 20.8489 15.5643 20.3774C16.6389 19.9231 17.6153 19.265 18.4396 18.4393C19.2654 17.6154 19.9235 16.6391 20.3777 15.5646C20.8489 14.4509 21.0906 13.2535 21.0884 12.0442C21.0906 10.8351 20.849 9.6379 20.3779 8.5243C19.9239 7.44975 19.2657 6.47347 18.4399 5.64957C17.616 4.82335 16.6395 4.16505 15.5646 3.71123C14.451 3.23951 13.2536 2.9976 12.0442 3.00002C10.8349 2.99738 9.63749 3.23893 8.52378 3.71018C7.44903 4.16401 6.4727 4.82231 5.64904 5.64852C4.82312 6.47241 4.16485 7.44869 3.7107 8.52325C3.23942 9.63715 2.9977 10.8347 3.00002 12.0442C2.9977 13.2537 3.23942 14.4512 3.7107 15.5651C4.16494 16.6396 4.8232 17.6159 5.64904 18.4399C6.47304 19.2656 7.44929 19.9237 8.52378 20.3779ZM9.51053 9.90061C9.51053 9.52567 9.81448 9.22172 10.1894 9.22172C10.5644 9.22172 10.8683 9.52567 10.8683 9.90061V14.3778C10.8683 14.7527 10.5644 15.0567 10.1894 15.0567C9.81448 15.0567 9.51053 14.7527 9.51053 14.3778V9.90061ZM13.1957 9.90061C13.1957 9.52567 13.4996 9.22172 13.8745 9.22172C14.2495 9.22172 14.5534 9.52567 14.5534 9.90061V14.3778C14.5534 14.7527 14.2495 15.0567 13.8745 15.0567C13.4996 15.0567 13.1957 14.7527 13.1957 14.3778V9.90061Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidPauseCircular;
