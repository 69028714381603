import { StyleSheet } from 'react-native'
import React from 'react'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, { runOnJS, useAnimatedStyle, withSpring } from 'react-native-reanimated'
import { Platform } from 'utils/platform'
import { HEADER_HEIGHT, MAX_TRANSLATE_Y, MID_TRANSLATE_Y, MIN_TRANSLATE_Y, SCREEN_HEIGHT, useBottomSheet } from 'context/BottomSheetContext'

export const BottomSheet: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { translateY, context, setVisible } = useBottomSheet()

    const gesture = Gesture.Pan()
        .onStart(() => {
            context.value = { y: translateY.value };
        })
        .onUpdate(e => {
            translateY.value = e.translationY + context.value.y;
            translateY.value = Math.max(translateY.value, -MAX_TRANSLATE_Y);
        })
        .onEnd(e => {
            if (translateY.value > -MID_TRANSLATE_Y * 1.3 && translateY.value < -MID_TRANSLATE_Y * 0.5) {
                translateY.value = withSpring(-MID_TRANSLATE_Y);
            } else if (translateY.value > -MIN_TRANSLATE_Y) {
                translateY.value = withSpring(SCREEN_HEIGHT);
                runOnJS(setVisible)(false);
            } else if (translateY.value < -MIN_TRANSLATE_Y) {
                translateY.value = withSpring(-MAX_TRANSLATE_Y);
            }
        })

    const reanimatedBottomStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateY: translateY.value }]
        }
    })

    return (
        <GestureDetector gesture={gesture}>
            <Animated.View style={[styles.bottomsheet_container, reanimatedBottomStyle]}>
                {children}
            </Animated.View>
        </GestureDetector>
    )
}

const styles = StyleSheet.create({
    bottomsheet_container: {
        width: '100%',
        height: SCREEN_HEIGHT - (Platform.OS !== 'ios' ? 70 : 90) - HEADER_HEIGHT,
        backgroundColor: "#F5F7FA",
        position: 'absolute',
        top: SCREEN_HEIGHT / 1.5,
        zIndex: 3,
        borderTopRightRadius: 25,
        borderTopLeftRadius: 25,
        paddingTop: 20
    },
})