import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidFowardStep: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7.19307L5 16.1981V16.1999C5.00001 16.627 5.2285 17.0216 5.59939 17.2352C5.97029 17.4487 6.42725 17.4487 6.79814 17.2352L14.5961 12.7294C14.9664 12.5156 15.1944 12.1213 15.1944 11.6947C15.1944 11.268 14.9664 10.8737 14.5961 10.66L6.79814 6.16017C6.42762 5.94683 5.97117 5.9466 5.60043 6.15956C5.22969 6.37252 5.00087 6.76639 5 7.19307ZM16.6875 6.0179V17.375H18.4856V6.0179H16.6875Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidFowardStep;
