import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import theme from 'theme';

import { useStream } from '../context';

const StreamPlay: React.FC = () => {
  const { device, castDialog } = useStream();

  // listener do casting
  return (
    <TouchableOpacity onPress={castDialog}>
      <View
        style={{
          backgroundColor: theme.colors.gray[500],
          height: 160,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{ color: '#fff' }}>Reproduzindo em {device?.friendlyName}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default StreamPlay;
