import { Box } from '@gluestack-ui/themed';
import { FlatList } from 'react-native';
import { calcSizeForTv } from 'utils/index';

import FocusView from 'components/FocusView';

type TvSeasonsMenuProps = {
  items: CmsTag[];
  renderItem: any;
};

const TvSeasonsMenu: React.FC<TvSeasonsMenuProps> = ({ items, renderItem }) => {
  return (
    <Box borderBottomColor="#7A8CFF" borderBottomWidth={1} mb={calcSizeForTv(10)}>
      <FocusView>
        <FlatList
          data={items}
          keyExtractor={(item) => item.name}
          renderItem={renderItem}
          initialNumToRender={items.length}
          contentContainerStyle={{ paddingHorizontal: 20 }}
          showsHorizontalScrollIndicator={false}
          horizontal
        />
      </FocusView>
    </Box>
  );
};

export default TvSeasonsMenu;
