import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { setAgeRating } from 'store/slices/profileSlice';
import { AgeRatingDefinitions, AgeRatingKey } from 'utils/ageRating';

export function useAgePreference(props: { toggleModal: CallableFunction; isOpen?: boolean }) {
  const dispatch = useDispatch();
  const { ageRating: savedAgeRatingPreference } = useSelector((state: RootState) => state.profile);

  const [ageRatingPreference, setAgeRatingPreference] = useState<AgeRatingKey>('18');
  const [isAgeRatingPreferenceLoaded, setIsAgeRatingPreferenceLoaded] = useState(true);

  const availableAgeRatingKeys: AgeRatingKey[] = useMemo(
    () => ['L', '10', '12', '14', '16', '18'],
    [ageRatingPreference]
  );

  const loadAgePreference = async () => {
    try {
      setAgeRatingPreference(savedAgeRatingPreference || '18');
    } catch (err) {
      setAgeRatingPreference('18');
    } finally {
      setIsAgeRatingPreferenceLoaded(true);
    }
  };

  useEffect(() => {
    loadAgePreference();
  }, [savedAgeRatingPreference]);

  const ageRatingPreferenceMaxIndex = useMemo(
    () => availableAgeRatingKeys.findIndex((k) => k === ageRatingPreference),
    [ageRatingPreference]
  );

  const ageRatingPreferenceHelperText = useMemo(() => {
    if (ageRatingPreference === '18') {
      return 'Mostrando títulos de todas as classificações etárias para este perfil';
    }

    if (ageRatingPreference === 'L') {
      return 'Mostrando títulos somente da classificação etária Livre';
    }

    const picks = availableAgeRatingKeys.filter((_k, i) => i <= ageRatingPreferenceMaxIndex);
    const picksTitle = picks.map((key) => AgeRatingDefinitions[key].title);
    return `Mostrando titulos das classificações etárias ${picksTitle.join(', ')}`;
  }, [ageRatingPreferenceMaxIndex]);

  const isAgeRatingKeyActive = (key: AgeRatingKey) => {
    const keyIndex = availableAgeRatingKeys.findIndex((k) => k === key);
    return keyIndex <= ageRatingPreferenceMaxIndex;
  };

  const commitAgeRatingPreference = async () => {
    dispatch(setAgeRating({ ageRating: ageRatingPreference }));
    props.toggleModal();
  };

  return {
    isAgeRatingKeyActive,
    availableAgeRatingKeys,
    setAgeRatingPreference,
    commitAgeRatingPreference,
    isAgeRatingPreferenceLoaded,
    ageRatingPreferenceHelperText,
  };
}
