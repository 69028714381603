import { Box, Button, ButtonText, HStack, Text } from '@gluestack-ui/themed';
import { RenderProps } from 'react-native-spotlight-tour';
import { useDispatch } from 'react-redux';
import { setHasCompletedNavigationTour } from 'store/slices/userDeviceSessionData';

type SpotlightProps = RenderProps & {
  spotlightTitle: string;
  spotlightDescription: string;
  isLastItem?: boolean;
  arrowAlign?: 'left' | 'center' | 'right' | 'none';
  arrowPosition?: 'top' | 'bottom';
};

const SpotlightComponent: React.FC<SpotlightProps> = ({
  spotlightTitle,
  spotlightDescription,
  isLastItem,
  arrowAlign = 'none',
  arrowPosition,
  ...rest
}) => {
  const dispatch = useDispatch();

  const getArrowAlign = (arrow: 'left' | 'center' | 'right' | 'none'): number => {
    switch (arrow) {
      case 'left':
        return 250;
      case 'center':
        return 108;
      case 'right':
        return 15;
      case 'none':
        return 0;
      default:
        return 0;
    }
  };

  function CompletSpotLightTour() {
    rest.stop();
    dispatch(setHasCompletedNavigationTour());
  }

  return (
    <Box position="relative" backgroundColor="transparent" py={10}>
      <Box
        width={0}
        height={0}
        borderRightWidth={15}
        borderBottomWidth={arrowPosition === 'top' ? 20 : 0}
        borderLeftWidth={15}
        borderTopColor={arrowPosition === 'bottom' ? '#001FE6' : 'transparent'}
        borderTopWidth={arrowPosition === 'bottom' ? 15 : 0}
        borderLeftColor="transparent"
        borderRightColor="transparent"
        borderBottomColor={arrowPosition === 'top' ? '#001FE6' : 'transparent'}
        position="absolute"
        right={getArrowAlign(arrowAlign)}
        top={arrowPosition === 'top' ? 0 : null}
        bottom={arrowPosition === 'bottom' ? 0 : null}
      />
      <Box maxWidth={300} backgroundColor="#001FE6" padding={20} rounded="$lg" {...rest}>
        <Text color="$white" fontSize="$md" fontWeight="bold" mb={5}>
          {spotlightTitle}
        </Text>
        <Text color="$white" fontSize="$sm">
          {spotlightDescription}
        </Text>
        {isLastItem ? (
          <HStack justifyContent="center" alignItems="center" mt={15}>
            <Button
              onPress={CompletSpotLightTour}
              width={140}
              height={40}
              backgroundColor="white"
              rounded="$2xl">
              <ButtonText color="#001FE6" fontSize="$sm">
                Finalizar!
              </ButtonText>
            </Button>
          </HStack>
        ) : (
          <HStack space="lg" mt={15}>
            <Button
              onPress={CompletSpotLightTour}
              width={120}
              height={40}
              rounded="$2xl"
              backgroundColor="transparent"
              borderWidth={1}
              borderColor="white">
              <ButtonText fontSize="$sm">Pular</ButtonText>
            </Button>
            <Button
              onPress={rest.next}
              width={120}
              height={40}
              backgroundColor="white"
              rounded="$2xl">
              <ButtonText color="#001FE6" fontSize="$sm">
                Próximo
              </ButtonText>
            </Button>
          </HStack>
        )}
      </Box>
    </Box>
  );
};

export default SpotlightComponent;
