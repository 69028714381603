import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Youtube: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.6317 23.3274H6.83698C5.28943 23.3257 3.80572 22.6856 2.71143 21.5475C1.61714 20.4094 1.00165 18.8664 1 17.2569V7.07046C1.00165 5.461 1.61714 3.91795 2.71143 2.77989C3.80572 1.64183 5.28943 1.00171 6.83698 1H16.6317C18.1792 1.00171 19.6629 1.64183 20.7572 2.77989C21.8515 3.91795 22.467 5.461 22.4687 7.07046V17.2569C22.467 18.8664 21.8515 20.4094 20.7572 21.5475C19.6629 22.6856 18.1792 23.3257 16.6317 23.3274ZM6.83698 2.61793C5.70202 2.61964 4.61402 3.08929 3.81148 3.92394C3.00894 4.75858 2.55735 5.8901 2.5557 7.07046V17.2569C2.55735 18.4373 3.00894 19.5688 3.81148 20.4035C4.61402 21.2381 5.70202 21.7078 6.83698 21.7095H16.6317C17.7666 21.7078 18.8546 21.2381 19.6572 20.4035C20.4597 19.5688 20.9113 18.4373 20.913 17.2569V7.07046C20.9113 5.8901 20.4597 4.75858 19.6572 3.92394C18.8546 3.08929 17.7666 2.61964 16.6317 2.61793H6.83698Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M9.49875 16.4566C9.0694 16.4534 8.65841 16.2945 8.35356 16.0138C8.04871 15.7331 7.87416 15.3529 7.86719 14.9544V9.36776C7.87029 9.09457 7.95294 8.82724 8.10638 8.5941C8.25982 8.36096 8.47833 8.17069 8.73876 8.04344C8.9992 7.9162 9.29187 7.85672 9.58575 7.8713C9.87964 7.88588 10.1638 7.97399 10.4081 8.12629L14.8749 10.901C15.0969 11.0403 15.2788 11.228 15.4045 11.4477C15.5302 11.6674 15.5959 11.9123 15.5959 12.1611C15.5959 12.4098 15.5302 12.6548 15.4045 12.8745C15.2788 13.0942 15.0969 13.2819 14.8749 13.4212L10.4081 16.1959C10.1404 16.3662 9.82331 16.4571 9.49875 16.4566ZM9.55893 9.45467V14.8675L13.912 12.1611L9.55893 9.45467Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Youtube;
