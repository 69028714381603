import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Lock: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M19.1176 10.1719H17.683V6.90887C17.683 3.65073 14.9108 1 11.5 1C8.08921 1 5.31696 3.65247 5.31696 6.91273V10.1719H3.89831C3.40675 10.1719 3 10.5447 3 11.0141V20.873C3 22.0446 4.00642 22.9969 5.23333 22.9969H17.7826C19.0095 22.9969 20 22.0446 20 20.873V11.0141C20 10.5447 19.6091 10.1719 19.1176 10.1719ZM7.09509 6.91273C7.09509 4.58973 9.07075 2.69982 11.5 2.69982C13.9292 2.69982 15.9049 4.58798 15.9049 6.90887V10.1719H7.09509V6.91273ZM18.2219 20.873C18.2219 21.1074 18.028 21.2989 17.7826 21.2989H5.23333C4.98795 21.2989 4.77813 21.1074 4.77813 20.873V11.8699H18.2219V20.873Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.4984 19.1305C11.99 19.1305 12.3875 18.75 12.3875 18.2806V15.221C12.3875 14.7516 11.99 14.3711 11.4984 14.3711C11.0069 14.3711 10.6094 14.7516 10.6094 15.221V18.2806C10.6094 18.75 11.0069 19.1305 11.4984 19.1305Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Lock;
