import {
  Toast,
  ToastDescription,
  ToastTitle,
  VStack,
  useToast as useGluestackToast,
} from '@gluestack-ui/themed';
import theme from 'theme';

type ToastModes = 'error' | 'warning' | 'success';
type ToastConfig = { backgroundColor: string; contentColor: string };
type ToastConfigs = { title: string; mode: ToastModes; description: string };

const ToastModesDefinitions: Record<ToastModes, ToastConfig> = {
  error: { backgroundColor: theme.colors.error[700], contentColor: theme.colors.base.white },
  success: { backgroundColor: theme.colors.success[200], contentColor: theme.colors.success[600] },
  warning: { backgroundColor: theme.colors.warning[100], contentColor: theme.colors.warning[600] },
};

export const useToast = () => {
  const toast = useGluestackToast();

  const showToastProxy = (config: ToastConfigs) => {
    const def = ToastModesDefinitions[config.mode];

    toast.show({
      placement: 'bottom',
      render: () => {
        return (
          <Toast bg={def.backgroundColor}>
            <VStack space="xs">
              <ToastTitle color={def.contentColor}>{config.title}</ToastTitle>
              <ToastDescription color={def.contentColor}>{config.description}</ToastDescription>
            </VStack>
          </Toast>
        );
      },
    });
  };

  return { show: showToastProxy };
};
