import { Box, Text as NativeBaseText } from '@gluestack-ui/themed';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import theme from 'theme';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import { useProgram } from './context';

import Button from 'components/Button';
import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';
import Radio from 'components/Radio/Radio';

type VideoButtonProps = {
  mediaIdValue: string | undefined;
  icon: React.ReactNode;
  text: string;
};

type ProgramRadioProps = {
  mediaId?: string;
  openVideo: boolean;
  setMediaId?: (mediaId?: string) => void;
  setOpenVideo?: Function;
};

const ProgramRadio: React.FC<ProgramRadioProps> = ({
  mediaId,
  setMediaId,
  openVideo,
  setOpenVideo,
}) => {
  const program = useProgram();
  const { pause, isReady, isFullScreen, isVisible, setFullScreen, setIsVisible } = usePlayer();

  const isFocused = useIsFocused();
  const iconSize = {
    width: calcSizeForDevice(24, 30).toString(),
    height: calcSizeForDevice(24, 30).toString(),
  };

  const handleVideoView = (item: string) => {
    if (!openVideo) pause();
    setMediaId?.(item);
    setOpenVideo?.(!openVideo);

    if (Platform.isAndroidTV) {
      setFullScreen(!isFullScreen);
      setIsVisible(!isVisible);
    }
  };

  useEffect(() => {
    if (!isFocused || (Platform.isTV && !isVisible)) {
      setOpenVideo?.(false);
      setMediaId?.(undefined);
    }
  }, [isFocused, isVisible]);

  useEffect(() => {
    if (isFullScreen) {
      setOpenVideo?.(true);
    } else if (isReady && mediaId) {
      setOpenVideo?.(true);
    }
  }, []);

  const VideoButtonComponent: React.FC<VideoButtonProps> = ({ mediaIdValue, icon, text }) => {
    return (
      <Button variant="ghost" useTVFocus role="link" onPress={() => handleVideoView(mediaIdValue!)}>
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          my={calcSizeForDevice(8)}
          mx={calcSizeForDevice(15)}>
          {icon}
          <NativeBaseText
            color={theme.colors.primary[300]}
            fontFamily={theme.fonts.inter.bold}
            fontSize={calcSizeForDevice(16, 24)}
            ml={calcSizeForDevice(4)}>
            {` ${text}`}
          </NativeBaseText>
        </Box>
      </Button>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <Radio
        openVideo={openVideo}
        setCloseVideo={() => {
          setMediaId?.(undefined);
          setOpenVideo?.(false);
        }}
        setOpenVideo={setOpenVideo}
        program={program}
        jsxItem={
          program.mediaId != undefined && (
            <View>
              {openVideo ? (
                <VideoButtonComponent
                  mediaIdValue={undefined}
                  text="Desabilitar vídeo"
                  icon={<Icons.VideoSlash color="#7A8CFF" {...iconSize} />}
                />
              ) : (
                <VideoButtonComponent
                  mediaIdValue={program.mediaId}
                  text="Habilitar vídeo"
                  icon={<Icons.Video color="#7A8CFF" {...iconSize} />}
                />
              )}
            </View>
          )
        }
      />
    </View>
  );
};

export default ProgramRadio;
