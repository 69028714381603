import { Platform, useWindowDimensions } from 'react-native';

export const useMobileOrDesktop = <T, U>(
  mobile: T,
  desktop: U
): [T, undefined] | [undefined, U] => {
  const { width } = useWindowDimensions();
  return (Platform.OS !== 'web' && !Platform.isTV) || (!Platform.isTV && width < 996)
    ? [mobile, undefined]
    : [undefined, desktop];
};

const mobileOrDesktop =
  <P extends object>(
    MobileComponent: React.ComponentType<P>,
    DesktopComponent: React.ComponentType<P>
  ): React.FC<P> =>
  (props) => {
    const [A, B] = useMobileOrDesktop(MobileComponent, DesktopComponent);
    const Component = A || B;
    return <Component {...props} />;
  };

export default mobileOrDesktop;
