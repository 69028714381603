import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Bell: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.5 7.33398C16.5 5.87529 15.9205 4.47635 14.8891 3.4449C13.8576 2.41345 12.4587 1.83398 11 1.83398C9.54131 1.83398 8.14236 2.41345 7.11091 3.4449C6.07946 4.47635 5.5 5.87529 5.5 7.33398C5.5 13.7507 2.75 15.584 2.75 15.584H19.25C19.25 15.584 16.5 13.7507 16.5 7.33398Z"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.5857 19.25C12.4246 19.5278 12.1933 19.7584 11.9149 19.9187C11.6366 20.079 11.3211 20.1634 10.9999 20.1634C10.6787 20.1634 10.3632 20.079 10.0849 19.9187C9.80654 19.7584 9.57522 19.5278 9.41406 19.25"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Bell;
