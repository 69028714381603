import { Text, View } from '@gluestack-ui/themed';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import EasterEggEnvs from 'components/EasterEgg/EasterEggEnvs';

const TvEasterEggScreen: React.FC = () => {
  return (
    <View m={calcSizeForTv(120)}>
      <Text
        color="white"
        fontSize={calcSizeForTv(48)}
        lineHeight={calcSizeForTv(60)}
        fontFamily={theme.fonts.inter.bold}>
        {Platform.isWebTV ? 'Ambientes de testes' : 'Menu de desenvolvedor'}
      </Text>
      {Platform.isWebTV && <EasterEggEnvs />}
      {!Platform.isWebTV && (
        <Text fontSize={calcSizeForTv(32)} lineHeight={calcSizeForTv(40)} mt={calcSizeForTv(40)}>
          Ainda não temos nada por aqui =/
        </Text>
      )}
    </View>
  );
};

export default TvEasterEggScreen;
