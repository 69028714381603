import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

const Number05: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 507.1 638.5" {...props}>
    <Path
      fill={innerColor || 'none'}
      d="M252.3,638c-48.1,0-90.9-8.6-127.2-25.5-36.3-16.9-65.4-41.3-86.5-72.4-21-31-33-68.3-35.5-110.8h162.3c0,15.3,4,29.2,11.7,41.3,7.8,12.3,18.4,22,31.6,28.9,13.2,6.9,28.3,10.4,45,10.4s32.4-3.9,45.4-11.5c13-7.6,23.2-18.5,30.6-32.3,7.3-13.8,11-30.1,11-48.3s-3.8-35.1-11.2-49.2c-7.5-14.1-17.8-25.2-30.8-33.1-13-7.9-28.1-11.9-45-11.9s-39.4,4.7-54.5,14c-15,9.2-25.3,21.8-30.7,37.5l-154.4-6.7L32.9.5h432.4v135.7H182.6l-8.9,110.7h2.5v-.2c12.1-13.9,28.4-25,48.5-32.8,20.1-7.8,42.9-11.8,67.8-11.8s54.6,5.4,80,16c25.4,10.6,48.3,25.6,68,44.6,19.7,19,35.4,41.5,46.7,66.9,11.3,25.4,17,53.3,17,82.9,0,43.6-10.5,82.8-31.2,116.6-20.7,33.8-50.2,60.7-87.8,79.8-37.6,19.1-82.3,28.9-132.9,28.9Z"
    />
    <Path
      fill={color || 'white'}
      d="M464.8,1v134.7H182.1v.9c0,0-8.9,109.7-8.9,109.7v1.1c0,0,1,0,1,0h2.1l.3-.3c11.9-13.9,28.1-24.9,48.1-32.6,20.1-7.8,42.8-11.7,67.6-11.7s54.4,5.4,79.8,16c25.4,10.6,48.2,25.6,67.8,44.5,19.6,19,35.3,41.4,46.6,66.8,11.3,25.4,17,53.2,17,82.7,0,43.5-10.5,82.7-31.1,116.4-20.6,33.7-50.1,60.5-87.6,79.6-37.5,19.1-82.2,28.8-132.7,28.8s-90.8-8.6-127-25.4c-36.2-16.9-65.3-41.2-86.3-72.2-20.9-30.8-32.8-67.8-35.4-110.1h161.3c.2,15.2,4.1,29,11.8,41.1,7.8,12.3,18.5,22.1,31.8,29,13.3,6.9,28.5,10.5,45.2,10.5s32.6-3.9,45.7-11.5c13-7.7,23.4-18.6,30.8-32.5,7.4-13.9,11.1-30.2,11.1-48.6s-3.8-35.3-11.3-49.4c-7.5-14.2-17.9-25.4-31-33.3-13-7.9-28.3-12-45.3-12s-39.6,4.7-54.8,14.1c-15,9.2-25.3,21.8-30.8,37.4l-153.5-6.7L33.3,1h431.5M465.8,0H32.4L13.4,368.8l155.3,6.8c5.3-15.8,15.5-28.3,30.6-37.6,15-9.3,33.1-13.9,54.2-13.9s31.8,3.9,44.7,11.8c12.9,7.9,23.1,18.9,30.6,32.9,7.5,14.1,11.2,30.4,11.2,49s-3.7,34.3-11,48.1c-7.3,13.8-17.4,24.5-30.4,32.1-12.9,7.6-28,11.4-45.2,11.4s-31.5-3.4-44.7-10.3c-13.2-6.9-23.7-16.5-31.4-28.7-7.7-12.2-11.6-26.1-11.6-41.6H2.4c2.5,43,14.4,80.3,35.7,111.6,21.2,31.4,50.1,55.6,86.7,72.6,36.6,17,79.1,25.5,127.4,25.5s95.3-9.6,133.1-28.9c37.8-19.3,67.2-45.9,88-80,20.8-34,31.2-73,31.2-116.9s-5.7-57.5-17.1-83.1c-11.4-25.6-27-48-46.8-67.1-19.8-19.1-42.6-34-68.2-44.7-25.6-10.7-52.3-16-80.2-16s-47.7,3.9-67.9,11.8c-20.3,7.9-36.4,18.9-48.5,32.9h-1.7l8.9-109.7h282.7V0h0Z"
    />
    <Path
      fill={color || 'white'}
      d="M252.6,638.5c-48.1,0-91.1-8.6-127.7-25.7-36.9-17.2-66.6-42-88.1-73.8C15.4,507.3,3.2,469.2.6,425.9l-.6-10.6h178.8v10c0,13.2,3.2,24.6,9.7,34.9,6.6,10.4,15.3,18.4,26.6,24.3,11.3,5.9,24.3,8.9,38.7,8.9s27.8-3.3,38.7-9.7c11-6.5,19.4-15.3,25.7-27.1,6.3-11.8,9.5-25.9,9.5-41.9s-3.3-30.6-9.7-42.7c-6.4-12.1-14.9-21.3-26-28-10.9-6.6-23.7-10-38.1-10s-34.4,4-47.3,12c-12.7,7.8-21,18-25.4,31.1l-2.4,7.1-168-7.3L30.1,0h439.3v152.4H194.8l-5.9,73.2c9.6-7.2,20.7-13.2,33.1-18.1,20.7-8,44.1-12.1,69.4-12.1s55.6,5.5,81.5,16.3c25.8,10.8,49.1,26.1,69.1,45.4,20.1,19.3,36.1,42.3,47.6,68.1,11.6,26,17.4,54.4,17.4,84.6,0,44.2-10.7,84-31.7,118.5-21.1,34.4-51.1,61.7-89.2,81.2-37.9,19.3-82.8,29.1-133.5,29.1ZM21.4,435.3c3.5,35.3,14.3,66.4,32,92.5,19.4,28.7,46.3,51.2,79.9,66.9,33.9,15.8,74,23.8,119.2,23.8s89.4-9.1,124.4-26.9c34.8-17.7,62.1-42.5,81.2-73.8,19.1-31.2,28.8-67.6,28.8-108s-5.3-53.1-15.7-76.5c-10.5-23.5-25-44.3-43.2-61.9-18.2-17.6-39.4-31.5-62.9-41.3-23.5-9.8-48.3-14.8-73.8-14.8s-43.8,3.6-62.2,10.8c-18,7-32.5,16.8-43.1,29.1l-3,3.5h-17.1l10.2-126.3h273.1V20H49.1l-17.4,337.7,133.2,5.8c6.4-14.2,16.8-25.8,31.1-34.6,16.1-9.9,35.5-15,57.8-15s34.5,4.3,48.5,12.9c14,8.5,25.2,20.6,33.3,35.7,8,15,12,32.6,12,52.1s-4,36.5-11.8,51.3c-7.9,15-19.1,26.7-33.2,35-14,8.2-30.5,12.4-48.8,12.4s-33.8-3.8-48-11.1c-14.3-7.5-25.9-18-34.3-31.3-6.8-10.7-10.9-22.7-12.3-35.6H21.4Z"
    />
  </Svg>
);

export default Number05;
