import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const DownLeftAndUpRightToCenter: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5448 10.9827L21.061 9.89765C21.4112 9.84231 21.4923 9.58746 21.2438 9.3339L19.3743 7.46503L23 3.83798L21.1627 2L17.5344 5.62576L15.665 3.75689C15.4165 3.5072 15.1564 3.58442 15.0997 3.93966L14.0143 10.4537C13.9538 10.6879 14.0903 10.9222 14.3195 10.9891C14.3916 11.0046 14.4676 11.0046 14.5448 10.9827Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M3.93492 14.2205C3.5796 14.2834 3.50236 14.5708 3.75082 14.8496L5.62523 16.9311L2 20.9585L3.83836 23L7.46488 18.9712L9.33414 21.0485C9.58904 21.3259 9.84394 21.2344 9.90059 20.8455L10.9846 13.6101C11.0051 13.5314 11.0051 13.4399 10.9846 13.3613C10.924 13.1011 10.6846 12.9496 10.449 13.0153L3.93492 14.2205Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default DownLeftAndUpRightToCenter;
