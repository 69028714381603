import { Text, View } from '@gluestack-ui/themed';
import theme from 'theme';
import { calcSizeForDevice } from 'utils/index';

type ListNumberProps = {
  number: number;
};

const ListNumber: React.FC<ListNumberProps> = ({ number }) => {
  const twoDigitNumber = number.toString().padStart(2, '0');

  return (
    <View height={calcSizeForDevice(60)} alignItems="center" flexDirection="row">
      <Text
        color="white"
        width={calcSizeForDevice(70)}
        fontSize={calcSizeForDevice(48)}
        lineHeight={calcSizeForDevice(52)}
        fontFamily={theme.fonts.inter.bold}
        mr={calcSizeForDevice(32)}>
        {twoDigitNumber}
      </Text>
      <View width={10} backgroundColor="#7A8CFF" height="$full" />
    </View>
  );
};

export default ListNumber;
