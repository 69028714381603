import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Play: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M7.87 21.2805C7.08 21.2805 6.33 21.0905 5.67 20.7105C4.11 19.8105 3.25 17.9805 3.25 15.5705V8.44048C3.25 6.02048 4.11 4.20048 5.67 3.30048C7.23 2.40048 9.24 2.57048 11.34 3.78048L17.51 7.34048C19.6 8.55048 20.76 10.2105 20.76 12.0105C20.76 13.8105 19.61 15.4705 17.51 16.6805L11.34 20.2405C10.13 20.9305 8.95 21.2805 7.87 21.2805ZM7.87 4.22048C7.33 4.22048 6.85 4.34048 6.42 4.59048C5.34 5.21048 4.75 6.58048 4.75 8.44048V15.5605C4.75 17.4205 5.34 18.7805 6.42 19.4105C7.5 20.0405 8.98 19.8605 10.59 18.9305L16.76 15.3705C18.37 14.4405 19.26 13.2505 19.26 12.0005C19.26 10.7505 18.37 9.56048 16.76 8.63048L10.59 5.07048C9.61 4.51048 8.69 4.22048 7.87 4.22048Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Play;
