import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const TurnBack: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M19.164 19.5471C17.523 17.0471 15.495 16.2621 13 16.0631V17.5001C13 18.0341 12.792 18.5361 12.414 18.9141C11.658 19.6701 10.337 19.6651 9.591 18.9191L3.298 12.7121C3.107 12.5231 3 12.2681 3 11.9991C3 11.7301 3.107 11.4751 3.298 11.2871L9.586 5.08408C10.34 4.32908 11.659 4.32808 12.415 5.08508C12.792 5.46308 13 5.96508 13 6.49908V8.20308C17.619 9.13608 21 13.2001 21 17.9991V18.9991C21 19.4411 20.71 19.8311 20.286 19.9571C20.191 19.9841 20.096 19.9991 20 19.9991C19.669 19.9991 19.354 19.8341 19.164 19.5471ZM12.023 14.0111C14.23 14.0671 16.661 14.4051 18.781 16.1321C18.013 12.9161 15.304 10.4301 11.888 10.0521C11.384 9.99608 11 10.0001 11 10.0001V6.50308L5.424 11.9991L11 17.4991V14.0001C11 14.0001 11.738 14.0101 12.023 14.0111Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default TurnBack;
