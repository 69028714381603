import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Platform } from 'utils/platform';

import FastImage from 'components/FastImage';

interface HeroImageProps {
  title: string;
  source: string;
  program?: CmsProgram;
}

const HeroImage: React.FC<HeroImageProps> = ({ title, source, program }) => {
  const { width: screenWidth } = useWindowDimensions();

  return (
    <Box h="$full" w={Platform.isAndroidTV ? screenWidth - 54 : '$full'}>
      <FastImage
        alt={title}
        source={{ uri: source }}
        style={{
          width: '100%',
          height: '100%',
          opacity: program?.ads?.remove_mask === true ? 1 : Platform.isTV ? 0.7 : 0.4,
        }}
      />
    </Box>
  );
};

export default HeroImage;
