import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const FaceSmile: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12.001 22.7198C9.33104 22.7198 6.65103 21.7599 4.62103 19.8299C2.49103 17.8099 1.29104 15.0898 1.25104 12.1698C1.21104 9.23984 2.33103 6.48984 4.40103 4.41984C8.59103 0.229844 15.411 0.229844 19.601 4.41984C21.671 6.48984 22.791 9.23984 22.751 12.1698C22.711 15.0998 21.511 17.8199 19.381 19.8299C17.351 21.7599 14.671 22.7198 12.001 22.7198ZM12.001 2.77989C9.63104 2.77989 7.26103 3.67984 5.46103 5.47984C3.68103 7.25984 2.72104 9.62988 2.75104 12.1499C2.78104 14.6699 3.81103 17.0099 5.65103 18.7399C9.15103 22.0599 14.851 22.0499 18.351 18.7399C20.181 17.0099 21.211 14.6599 21.251 12.1499C21.291 9.63988 20.321 7.25984 18.541 5.47984C16.741 3.67984 14.371 2.77989 12.001 2.77989Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12.0007 18.3209C10.4207 18.3209 8.85073 17.7509 7.65073 16.6209C7.35073 16.3309 7.34073 15.8609 7.62073 15.5609C7.91073 15.2609 8.38073 15.2509 8.68073 15.5309C10.5107 17.2609 13.4907 17.2609 15.3207 15.5309C15.6207 15.2509 16.1007 15.2609 16.3807 15.5609C16.6607 15.8609 16.6507 16.3409 16.3507 16.6209C15.1507 17.7509 13.5807 18.3209 12.0007 18.3209Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default FaceSmile;
