import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SquareMusic: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M8.42157 17.8607C7.14157 17.8607 6.10156 16.8207 6.10156 15.5407C6.10156 14.2607 7.14157 13.2207 8.42157 13.2207C9.70157 13.2207 10.7416 14.2607 10.7416 15.5407C10.7416 16.8207 9.70157 17.8607 8.42157 17.8607ZM8.42157 14.7207C7.97157 14.7207 7.60156 15.0907 7.60156 15.5407C7.60156 15.9907 7.97157 16.3607 8.42157 16.3607C8.87157 16.3607 9.24158 15.9907 9.24158 15.5407C9.24158 15.0907 8.87157 14.7207 8.42157 14.7207Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M9.99219 16.2901C9.58219 16.2901 9.24219 15.9501 9.24219 15.5401V9.21011C9.24219 8.25011 9.84221 7.47006 10.7622 7.22006L13.9022 6.36007C14.8522 6.10007 15.4522 6.35006 15.7822 6.60006C16.2622 6.97006 16.5022 7.55011 16.5022 8.34011V14.4801C16.5022 14.8901 16.1622 15.2301 15.7522 15.2301C15.3422 15.2301 15.0022 14.8901 15.0022 14.4801V8.34011C15.0022 8.01011 14.9322 7.83006 14.8722 7.79006C14.8222 7.75006 14.6422 7.71007 14.2922 7.80007L11.1522 8.66006C10.8722 8.74006 10.7422 8.9101 10.7422 9.2001V15.5301C10.7422 15.9501 10.4022 16.2901 9.99219 16.2901Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M14.1794 16.8099C12.8994 16.8099 11.8594 15.7699 11.8594 14.4899C11.8594 13.2099 12.8994 12.1699 14.1794 12.1699C15.4594 12.1699 16.4994 13.2099 16.4994 14.4899C16.4994 15.7699 15.4594 16.8099 14.1794 16.8099ZM14.1794 13.6699C13.7294 13.6699 13.3594 14.0399 13.3594 14.4899C13.3594 14.9399 13.7294 15.3099 14.1794 15.3099C14.6294 15.3099 14.9994 14.9399 14.9994 14.4899C14.9994 14.0399 14.6294 13.6699 14.1794 13.6699Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M9.98888 11.7893C9.65888 11.7893 9.35891 11.5694 9.26891 11.2394C9.15891 10.8394 9.39888 10.4294 9.79888 10.3194L15.5589 8.74937C15.9689 8.63937 16.3689 8.8794 16.4789 9.2794C16.5889 9.6794 16.3489 10.0894 15.9489 10.1994L10.1889 11.7694C10.1189 11.7794 10.0489 11.7893 9.98888 11.7893Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SquareMusic;
