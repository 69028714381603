import styled from 'styled-components/native';
import { SCREEN_SIZE, calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

type SkeletonProps = {
  type: string;
};

const halfScreenHeight = SCREEN_SIZE().height / 2;
const heroHeightForTVDevices = 374 - (calcSizeForDevice(halfScreenHeight) - 50);

const SkeletonStyleSize = {
  hero: {
    width: `${SCREEN_SIZE().width}px`,
    height: Platform.isTV ? `${heroHeightForTVDevices}px` : '550px',
  },
  mid: {
    width: '106px',
    height: '50px',
  },
  medium: {
    width: '106px',
    height: '152px',
  },
  big: {
    width: '222px',
    height: '126px',
  },
  boxed: {
    width: `${SCREEN_SIZE().width}px`,
    height: '126px',
  },
};

const SkeletonSize = (type: string) => {
  return SkeletonStyleSize[type as keyof typeof SkeletonStyleSize];
};

export const SkeletonContainer = styled.View<SkeletonProps>`
  height: ${(props) => SkeletonSize(props.type).height};
  width: ${(props) => SkeletonSize(props.type).width};
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;
