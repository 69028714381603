import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

const Number03: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 517.9 647.9" {...props}>
    <Path
      fill={innerColor || 'none'}
      d="M256.8,647.4c-57.3,0-105.3-10.3-142.8-30.5-37.4-20.2-65.7-47.4-84-80.6-18.2-33.1-27.5-69.4-27.6-108.1h164.8c0,17.8,3.8,33.5,11,46.6,7.3,13.2,17.7,23.6,31,30.8,13.2,7.2,29.1,10.8,47.1,10.8s31.4-3,44.1-8.9c12.7-5.9,22.7-14.2,29.7-24.6,7.1-10.4,10.6-22.5,10.6-35.7s-4-26.4-11.9-36.6c-7.9-10.1-19.1-18.2-33.3-24-14.2-5.8-31.2-8.7-50.6-8.7h-61.1v-117.1h61.1c18.3,0,34.3-2.7,47.6-8.1,13.4-5.3,23.8-13,31-22.7,7.2-9.7,10.9-21.4,10.9-34.7s-3.4-25.1-10-35.3c-6.6-10.1-15.7-18.2-27-24-11.3-5.8-24.4-8.7-39-8.7s-29.5,3.1-41.8,9.3c-12.3,6.2-22.1,15-29.1,26.1-7,11-10.5,23.9-10.6,38.4H14.4c0-38.1,10-72.6,29.4-102.6,19.5-30.1,47.9-54.3,84.4-71.7C164.7,9.4,208.5.5,258.5.5s87.5,7.6,123,22.5c35.5,14.9,63.6,35.8,83.5,62.1,19.9,26.2,30.1,56.9,30.1,91.2s-12.2,68-36.1,91c-24,23-54.3,36.8-90,41h-.4v3.1h.5c31.8,3.1,58.9,11.8,80.3,25.9,21.5,14.1,38,32.1,49.2,53.4,11.2,21.3,16.8,44.8,16.8,69.6,0,35.7-11,68.1-32.6,96.3-21.6,28.2-52.2,50.6-90.7,66.7-38.6,16.1-84.1,24.2-135.2,24.2Z"
    />
    <Path
      fill={color || 'white'}
      d="M258.5,1c46,0,87.3,7.6,122.8,22.5,35.4,14.9,63.4,35.7,83.3,61.9,19.9,26.1,30,56.7,30,90.9s-3,35.7-9,50.7c-6,15-15,28.4-27,39.9-23.9,22.9-54.1,36.7-89.7,40.9h-.9v4h.9c31.7,3.2,58.7,11.8,80.1,25.9,21.4,14,37.9,31.9,49,53.2,11.1,21.3,16.8,44.6,16.8,69.3,0,35.6-10.9,67.9-32.5,96-21.6,28.1-52,50.5-90.5,66.5-38.5,16-83.9,24.2-135,24.2s-105.2-10.2-142.5-30.5c-37.3-20.2-65.5-47.2-83.8-80.4-18.1-32.9-27.3-69-27.5-107.3h163.8c.1,17.7,3.9,33.3,11.1,46.3,7.4,13.3,17.9,23.7,31.2,31,13.3,7.2,29.2,10.9,47.3,10.9s31.6-3,44.3-9c12.8-6,22.8-14.3,29.9-24.8,7.1-10.5,10.7-22.6,10.7-36s-4-26.6-12-36.9c-7.9-10.2-19.2-18.3-33.5-24.1-14.3-5.8-31.4-8.7-50.8-8.7h-60.6v-116.1h60.6c18.3,0,34.4-2.7,47.8-8.1,13.5-5.4,24-13.1,31.2-22.9,7.3-9.8,11-21.6,11-35s-3.4-25.3-10.1-35.6c-6.7-10.2-15.8-18.4-27.2-24.2-11.3-5.8-24.6-8.8-39.3-8.8s-29.7,3.2-42,9.4c-12.3,6.2-22.2,15.1-29.3,26.3-6.9,11-10.5,23.8-10.7,38.1H14.9c.2-37.8,10-72,29.4-101.8,19.5-30.1,47.8-54.1,84.2-71.6C164.8,9.8,208.6,1,258.5,1M258.5,0c-50.3,0-93.9,8.8-130.5,26.4-36.7,17.6-64.9,41.5-84.6,71.9-19.7,30.4-29.5,64.8-29.5,103.3h163.7c0-14.6,3.5-27.5,10.5-38.6,7-11.1,16.7-19.8,28.9-25.9,12.2-6.2,26.1-9.3,41.5-9.3s27.6,2.9,38.8,8.6c11.2,5.8,20.2,13.7,26.8,23.8,6.6,10.1,9.9,21.8,9.9,35s-3.6,24.7-10.8,34.4c-7.2,9.7-17.4,17.2-30.8,22.6-13.4,5.3-29.2,8-47.5,8h-61.6v118.1h61.6c19.4,0,36.2,2.9,50.4,8.6,14.2,5.8,25.2,13.7,33.1,23.8,7.9,10.1,11.8,22.2,11.8,36.3s-3.5,25-10.5,35.4c-7,10.4-16.9,18.6-29.5,24.5-12.7,5.9-27.3,8.9-43.9,8.9s-33.6-3.6-46.8-10.8c-13.2-7.2-23.5-17.4-30.8-30.6-7.3-13.2-11-28.8-11-46.8H2.1c0,39.1,9.2,75.4,27.6,108.8,18.4,33.5,46.5,60.4,84.2,80.8,37.7,20.4,85.3,30.6,143,30.6s96.6-8.1,135.4-24.3c38.8-16.2,69.1-38.5,90.9-66.9,21.8-28.4,32.7-60.6,32.7-96.6s-5.6-48.3-16.9-69.8c-11.3-21.5-27.7-39.4-49.4-53.6-21.7-14.2-48.5-22.8-80.6-25.9v-2.1c36-4.2,66.1-17.9,90.3-41.1,24.2-23.2,36.3-53.6,36.3-91.3s-10.1-65.1-30.2-91.5c-20.1-26.4-48-47.2-83.7-62.2C346,7.5,304.9,0,258.5,0h0Z"
    />
    <Path
      fill={color || 'white'}
      d="M256.9,647.9c-57.3,0-105.5-10.4-143.3-30.8-38.1-20.6-66.9-48.3-85.6-82.3C9.4,501,0,463.9,0,424.5v-10h180.7v10c0,15.7,3.2,29.3,9.4,40.5,6.2,11.2,14.7,19.6,25.9,25.7,11.3,6.1,24.9,9.2,40.6,9.2s27.4-2.6,38.3-7.6c10.7-5,18.7-11.6,24.6-20.2,5.7-8.5,8.5-17.9,8.5-28.7s-3.1-20.9-9.3-29c-6.5-8.4-15.9-15.1-28-20-12.5-5.1-27.7-7.6-45.1-7.6h-69.7v-134.5h69.7c16.4,0,30.6-2.4,42.3-7.1,11.1-4.5,19.7-10.7,25.5-18.5,5.7-7.7,8.5-16.7,8.5-27.4s-2.6-20.2-8-28.5c-5.5-8.4-12.7-14.8-22.1-19.7-9.4-4.8-20.5-7.3-33-7.3s-25.4,2.7-35.8,7.9c-10.3,5.2-18.2,12.3-24.1,21.6-5.8,9.2-8.7,19.7-8.7,32.1v10H11.4v-10c0-39.1,10.2-74.7,30.2-105.6,20-30.9,48.9-55.5,86-73.3C164.5,8.9,208.5,0,258.5,0s87.6,7.6,123.2,22.7c36.1,15.2,64.8,36.6,85.2,63.5,20.8,27.3,31.3,59.2,31.3,94.8s-12.9,71.4-38.2,95.7c-15,14.4-32.4,25.3-51.9,32.7,15.2,4.6,28.9,10.9,41,18.8,22.3,14.6,39.6,33.4,51.2,55.6,11.6,22.2,17.5,46.5,17.5,72.3,0,37-11.4,70.5-33.7,99.7-22.1,28.8-53.1,51.7-92.2,67.9-38.7,16.1-84.1,24.3-135.1,24.3ZM20.2,434.5c1.4,32.3,9.9,62.7,25.3,90.6,16.8,30.6,42.9,55.6,77.6,74.3,34.9,18.9,79.9,28.4,133.8,28.4s91.2-7.6,127.4-22.7c35.8-14.9,64.1-35.7,84-61.6,19.7-25.6,29.6-55.1,29.6-87.5s-5.1-43.7-15.2-63c-10-19.2-25-35.4-44.5-48.2-19.5-12.8-44.3-20.7-73.6-23.5l-9-.9v-20l8.8-1c32.5-3.8,60-16.3,81.7-37.1,21.6-20.7,32.1-47.3,32.1-81.3s-9.1-58.9-27.2-82.7c-18.3-24.1-44.3-43.3-77.1-57.1-33.2-14-72.1-21.1-115.5-21.1s-88.2,8.3-122.2,24.6c-33.7,16.2-59.9,38.4-77.9,66.1-16.2,25-25.2,53.5-26.8,84.7h138.9c1.3-12.1,5.1-23,11.2-32.8,7.8-12.3,18.5-21.9,31.9-28.7,13.2-6.7,28.3-10.1,44.8-10.1s29.9,3.2,42.2,9.5c12.4,6.4,22.4,15.3,29.8,26.5,7.5,11.4,11.2,24.7,11.2,39.4s-4.2,28.1-12.4,39.3c-8,10.9-19.5,19.4-34.2,25.2-14.1,5.6-30.8,8.5-49.7,8.5h-49.7v94.5h49.7c20,0,37.7,3.1,52.6,9.1,15.4,6.2,27.5,15.1,36.2,26.2,9,11.6,13.6,25.5,13.6,41.3s-4,28.2-11.9,39.9c-7.8,11.6-18.8,20.7-32.7,27.2-13.5,6.3-29.3,9.5-46.7,9.5s-35.9-3.9-50.1-11.6c-14.5-7.8-25.8-19.1-33.8-33.6-6.5-11.7-10.3-25.2-11.5-40.2H20.2Z"
    />
  </Svg>
);

export default Number03;
