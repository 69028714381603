import React, { memo } from 'react';

import BigCard from './BigCard';
import MediumBoxedCard from './MediumBoxedCard';
import MediumCard from './MediumCard';
import MidBoxedCard from './MidBoxedCard';
import MidCard from './MidCard';

const Card: React.FC<CardProps & { type: string; isSelected?: boolean }> = ({ type, ...props }) => {
  const cardProps = { label: props.title || props.imgTitle, ...props };
  switch (type) {
    case 'big':
      return <BigCard {...cardProps} />;
    case 'mid':
      return <MidCard {...cardProps} />;
    case 'medium':
      return <MediumCard {...cardProps} />;
    case 'midBoxed':
      return <MidBoxedCard {...cardProps} />;
    case 'mediumBoxed':
      return <MediumBoxedCard {...cardProps} />;
    default:
      return <MediumCard {...cardProps} />;
  }
};

export default memo(Card);
