import TvHelpScreenLayout from 'components/Help/TvHelpScreenLayout.ctv';
import TvQrContentLayout from 'components/Help/TvQRContentLayout.ctv';

const TvLegalTermsScreen: React.FC = () => {
  return (
    <TvHelpScreenLayout>
      <TvQrContentLayout
        title="Termos e Condições"
        url="bandplay.com/termos"
        qrCodeValue="https://bandplay.com/termos"
      />
    </TvHelpScreenLayout>
  );
};

export default TvLegalTermsScreen;
