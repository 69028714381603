import { createContext, useContext } from 'react';

export type VideoPlayerState = {
  controlsVisible: boolean;
  showSettings?: boolean;
};

export type VideoPlayerAction =
  | { type: 'SHOW_SETTINGS'; payload: boolean }
  | { type: 'CONTROLS_VISIBLE'; payload: boolean };

type VideoPlayerContextProps = VideoPlayerState & {
  openSettings: () => void;
  closeSettings: () => void;
};

const VideoPlayerContext = createContext<VideoPlayerContextProps>({} as VideoPlayerContextProps);

export const useVideoPlayer = () => useContext(VideoPlayerContext);

export default VideoPlayerContext;
