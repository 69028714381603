import JsonView from '@uiw/react-json-view';
import { darkTheme } from '@uiw/react-json-view/dark';
import { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import theme from 'theme';

const object = {
  string: 'Lorem ipsum dolor sit amet',
  integer: 42,
  float: 114.514,
  boolean: true,
  null: null,
  nan: NaN,
  url: new URL('https://example.com'),
};

export default function AnalyticsScreen() {
  const [logs, setLogs] = useState([]);
  const fetchData = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch('http://localhost:3008/logs', options);

    const data = await response.json();
    data.reverse();
    setLogs(data);
  };

  useEffect(() => {
    setInterval(() => {
      fetchData();
    }, 2000);
  }, []);

  return (
    <View>
      {logs.map((log: any, index) => {
        return (
          <View style={{ marginVertical: 20 }}>
            <Text
              style={{
                color: 'white',
                fontFamily: theme.fonts.inter.bold,
                fontSize: 18,
                marginVertical: 20,
              }}>
              Data: {log?.date}
            </Text>
            <JsonView value={log} key={index} />
          </View>
        );
      })}
    </View>
  );
}
