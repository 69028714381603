import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const XTwitter: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.6317 23.3274H6.83698C5.28943 23.3257 3.80572 22.6856 2.71143 21.5475C1.61714 20.4094 1.00165 18.8664 1 17.2569V7.07046C1.00165 5.461 1.61714 3.91795 2.71143 2.77989C3.80572 1.64183 5.28943 1.00171 6.83698 1H16.6317C18.1792 1.00171 19.6629 1.64183 20.7572 2.77989C21.8515 3.91795 22.467 5.461 22.4687 7.07046V17.2569C22.467 18.8664 21.8515 20.4094 20.7572 21.5475C19.6629 22.6856 18.1792 23.3257 16.6317 23.3274ZM6.83698 2.61793C5.70202 2.61964 4.61402 3.08929 3.81148 3.92394C3.00894 4.75858 2.55735 5.8901 2.5557 7.07046V17.2569C2.55735 18.4373 3.00894 19.5688 3.81148 20.4035C4.61402 21.2381 5.70202 21.7078 6.83698 21.7095H16.6317C17.7666 21.7078 18.8546 21.2381 19.6572 20.4035C20.4597 19.5688 20.9113 18.4373 20.913 17.2569V7.07046C20.9113 5.8901 20.4597 4.75858 19.6572 3.92394C18.8546 3.08929 17.7666 2.61964 16.6317 2.61793H6.83698Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M6.03076 6L10.912 12.5265L6 17.8329H7.10557L11.4061 13.187L14.8807 17.8329H18.6427L13.4868 10.9393L18.0588 6H16.9533L12.9928 10.2787L9.79281 6H6.03076ZM7.65656 6.81428H9.38482L17.0167 17.0186H15.2884L7.65656 6.81428Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default XTwitter;
