import { View } from '@gluestack-ui/themed';
import { useRef, useState } from 'react';
import {
  FlatList,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  useWindowDimensions,
} from 'react-native';

export const useHorizontalPagination = (props: { renderItem: any; items: any }) => {
  const [canGoForward, setCanGoForward] = useState(true);
  const [canGoBackward, setCanGoBackward] = useState(false);

  let itemsWidth = useRef<number[]>([]).current;
  let currentScrollPosition = useRef<number>(0).current;
  let flatListRef = useRef<FlatList | null>(null);

  const { width } = useWindowDimensions();
  const safeScreenViewArea = width - width / 6;

  function getSweetIndexesToScroll() {
    const accumulateOffset = (i: number) => itemsWidth.slice(0, i).reduce((a, c) => a + c, 0);
    const accumulatedOffsets = itemsWidth.map((_v, index) => accumulateOffset(index));

    return accumulatedOffsets.reduce(
      (points, currentOffset, index) => {
        const currentItemWidth = itemsWidth[index];
        const accumulatedScreenViewArea = safeScreenViewArea * points.length;
        const offsetDiff = currentOffset - accumulatedScreenViewArea;

        if (
          (offsetDiff >= 0 && offsetDiff <= currentItemWidth) ||
          index === accumulatedOffsets.length - 1
        ) {
          return [...points, { index, offset: currentOffset }];
        }

        return points;
      },
      [{ index: 0, offset: 0 }]
    );
  }

  function getClosestIndexToScroll(mode: 'forward' | 'backward') {
    const sweetIndexesToScroll = getSweetIndexesToScroll();

    const result = sweetIndexesToScroll.reduce((closesOptionIndex, sweetIndex, index) => {
      const previousSweetIndex = sweetIndexesToScroll[closesOptionIndex];
      const diff = Math.abs(sweetIndex.offset - currentScrollPosition);
      const previousDiff = Math.abs(previousSweetIndex.offset - currentScrollPosition);
      const isCurrentDiffLessThanPreviousDiff = diff < previousDiff;
      return isCurrentDiffLessThanPreviousDiff ? index : closesOptionIndex;
    }, 0);

    const maxOptionIndex = sweetIndexesToScroll.length - 1;
    const closestOptionIndex = mode === 'forward' ? result + 1 : result - 1;

    if (closestOptionIndex < 0) return sweetIndexesToScroll[0].index;
    if (closestOptionIndex > maxOptionIndex) return sweetIndexesToScroll[maxOptionIndex].index;
    return sweetIndexesToScroll[closestOptionIndex].index;
  }

  function moveTo(index: number, animated = true) {
    flatListRef.current?.scrollToIndex({ index, animated });
  }

  function goForward() {
    const closestIndex = getClosestIndexToScroll('forward');
    return moveTo(closestIndex);
  }

  function goBackward() {
    const closestIndex = getClosestIndexToScroll('backward');
    return moveTo(closestIndex);
  }

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    currentScrollPosition = event.nativeEvent.contentOffset.x;
    const layoutWidth = event.nativeEvent.layoutMeasurement.width;
    const scrollFullPosition = layoutWidth + currentScrollPosition;
    const contentWidth = event.nativeEvent.contentSize.width;
    const visibilityOffset = 50;

    setCanGoForward(scrollFullPosition < contentWidth - visibilityOffset);
    setCanGoBackward(currentScrollPosition > visibilityOffset);
  }

  function renderItemProxy(parameters: any) {
    return (
      <View onLayout={(event) => onLayoutItem(event, parameters.index)}>
        {props.renderItem(parameters)}
      </View>
    );
  }

  function onLayoutItem(event: LayoutChangeEvent, index: number) {
    if (index === props.items.length - 1) {
      const rootNode = flatListRef.current?.getScrollableNode();
      const innerView = rootNode.getInnerViewRef();
      innerView.measureInWindow(onLayoutMeasured);
    }

    itemsWidth[index] = event.nativeEvent.layout.width;
  }

  function onLayoutMeasured(_left: number, _top: number, contentWidth: number, _height: number) {
    if (contentWidth < width) {
      setCanGoForward(false);
      setCanGoBackward(false);
      return;
    }

    moveTo(0, false);
    setCanGoForward(true);
    setCanGoBackward(false);
  }

  function getItemLayout(_data: any, index: number) {
    const length = itemsWidth[index];
    const offset = itemsWidth.slice(0, index).reduce((a, c) => a + c, 0);
    return { length, offset, index };
  }

  return {
    onScroll,
    goForward,
    goBackward,
    canGoForward,
    canGoBackward,
    getItemLayout,
    ref: flatListRef,
    renderItem: renderItemProxy,
  };
};
