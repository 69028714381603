import { Box } from '@gluestack-ui/themed';
import React from 'react';

import Button from 'components/Button';
import { usePlayer } from 'components/Player/context';

const SectionVideo: React.FC<CmsHomeSectionVideo> = (props) => {
  const { play, isPlaying, media } = usePlayer();
  return (
    <Box position="relative">
      {(!isPlaying || media?.id != props.mediaId) && (
        <Box position="absolute" top={'50%'} left={15}>
          <Button title={props.title} onPress={play} />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(SectionVideo);
