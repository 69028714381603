import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Subtitles: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M17.4984 17.8301H15.6484C15.2384 17.8301 14.8984 17.4901 14.8984 17.0801C14.8984 16.6701 15.2384 16.3301 15.6484 16.3301H17.4984C17.9084 16.3301 18.2484 16.6701 18.2484 17.0801C18.2484 17.4901 17.9084 17.8301 17.4984 17.8301Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12.97 17.8301H6.5C6.09 17.8301 5.75 17.4901 5.75 17.0801C5.75 16.6701 6.09 16.3301 6.5 16.3301H12.97C13.38 16.3301 13.72 16.6701 13.72 17.0801C13.72 17.4901 13.39 17.8301 12.97 17.8301Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M17.4987 14.0703H11.9688C11.5588 14.0703 11.2188 13.7303 11.2188 13.3203C11.2188 12.9103 11.5588 12.5703 11.9688 12.5703H17.4987C17.9087 12.5703 18.2487 12.9103 18.2487 13.3203C18.2487 13.7303 17.9087 14.0703 17.4987 14.0703Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M9.27 14.0703H6.5C6.09 14.0703 5.75 13.7303 5.75 13.3203C5.75 12.9103 6.09 12.5703 6.5 12.5703H9.27C9.68 12.5703 10.02 12.9103 10.02 13.3203C10.02 13.7303 9.68 14.0703 9.27 14.0703Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Subtitles;
