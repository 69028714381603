import { useRoute } from '@react-navigation/native';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';

const WebViewScreen = () => {
  const route = useRoute();
  const data = route.params?.data;

  return <WebView source={{ uri: data.productUrl }} style={{ flex: 1 }} />;
};

export default WebViewScreen;
