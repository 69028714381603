import { forwardRef, useImperativeHandle } from 'react';
import { Text, View } from 'react-native';

import AudioPlayButton from './UI/PlayButton';

const AudioPlayer = forwardRef<CustomPlayerRef, CustomPlayerProps>((props, ref) => {
  useImperativeHandle(
    ref,
    () => ({
      seek(time: number) {},
    }),
    []
  );

  return (
    <>
      <View>
        <Text>Audio</Text>
      </View>
      <AudioPlayButton />
    </>
  );
});

AudioPlayer.displayName = 'AudioPlayer';

export default AudioPlayer;
