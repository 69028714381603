import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const VolumeHigh: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12.55 20.5906C11.76 20.5906 10.89 20.3106 10.02 19.7606L7.1 17.9306C6.9 17.8106 6.67 17.7406 6.44 17.7406H5C2.58 17.7406 1.25 16.4106 1.25 13.9906V9.99059C1.25 7.57059 2.58 6.24059 5 6.24059H6.43C6.66 6.24059 6.89 6.17059 7.09 6.05059L10.01 4.22059C11.47 3.31059 12.89 3.14059 14.01 3.76059C15.13 4.38059 15.74 5.67059 15.74 7.40059V16.5706C15.74 18.2906 15.12 19.5906 14.01 20.2106C13.57 20.4706 13.07 20.5906 12.55 20.5906ZM5 7.75059C3.42 7.75059 2.75 8.42059 2.75 10.0006V14.0006C2.75 15.5806 3.42 16.2506 5 16.2506H6.43C6.95 16.2506 7.45 16.3906 7.89 16.6706L10.81 18.5006C11.77 19.1006 12.68 19.2606 13.29 18.9206C13.9 18.5806 14.25 17.7306 14.25 16.6006V7.41059C14.25 6.27059 13.9 5.42059 13.29 5.09059C12.68 4.75059 11.77 4.90059 10.81 5.51059L7.88 7.33059C7.45 7.61059 6.94 7.75059 6.43 7.75059H5Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M18.0003 16.7503C17.8403 16.7503 17.6903 16.7003 17.5503 16.6003C17.2203 16.3503 17.1503 15.8803 17.4003 15.5503C18.9703 13.4603 18.9703 10.5403 17.4003 8.45027C17.1503 8.12027 17.2203 7.65027 17.5503 7.40027C17.8803 7.15027 18.3503 7.22027 18.6003 7.55027C20.5603 10.1703 20.5603 13.8303 18.6003 16.4503C18.4503 16.6503 18.2303 16.7503 18.0003 16.7503Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M19.8284 19.2503C19.6684 19.2503 19.5184 19.2003 19.3784 19.1003C19.0484 18.8503 18.9784 18.3803 19.2284 18.0503C21.8984 14.4903 21.8984 9.51027 19.2284 5.95027C18.9784 5.62027 19.0484 5.15027 19.3784 4.90027C19.7084 4.65027 20.1784 4.72027 20.4284 5.05027C23.4984 9.14027 23.4984 14.8603 20.4284 18.9503C20.2884 19.1503 20.0584 19.2503 19.8284 19.2503Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default VolumeHigh;
