/* eslint-disable import/order */
import { Box, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import { useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import TitleCard from './TitleCard';

import FastImage from 'components/FastImage';

const MediumCard: React.FC<CardProps & { isRelated?: boolean; style?: object }> = ({
  uri,
  crops,
  title,
  imgTitle,
  reference,
  cardWidth,
  ...rest
}) => {
  const mediumCardAspectRatio = 3 / 4;

  const mediumCardWidth = useBreakpointValue({
    base: 135,
    xs: 150,
    sm: Platform.isAndroidTV ? 130 : 174,
    lg: 189,
    xl: calcSizeForDevice(201, rest.isRelated ? 201 : 260),
  });

  const mediumCardHeight = useMemo(
    () => calcHeightWithWidth(cardWidth || mediumCardWidth, mediumCardAspectRatio),
    [cardWidth, mediumCardWidth, mediumCardAspectRatio]
  );

  return (
    <Box
      width={cardWidth || mediumCardWidth}
      height={mediumCardHeight}
      borderRadius={8}
      overflow="hidden"
      {...rest}>
      {uri ? (
        <FastImage
          size="sm"
          aspect="3x4"
          source={{ uri }}
          style={{ width: '100%', height: '100%', borderRadius: 8, overflow: 'hidden' }}
        />
      ) : (
        <TitleCard title={title || imgTitle} reference={reference} />
      )}
    </Box>
  );
};

export default withLink(MediumCard, 'MediumCard');
