import { Button, Text, View } from '@gluestack-ui/themed';
import React from 'react';
import { PressableProps } from 'react-native';
import { calcSizeForTv } from 'utils/index';

import { ButtonProps } from '.';

import { getIconByName } from 'components/Icons';

export type MenuButtonProps = {
  isActivated?: boolean;
  isOpen?: boolean;
  setActiveMenuItem?: Function;
  setIsOpen?: Function;
  setIsSubmenuOpen?: Function;
  onButtonEnter?: () => void;
};

type SidebarMenuButtonProps = Omit<ButtonProps, 'gradientVariant' | 'variant' | 'backgroundColor'> &
  MenuButtonProps;

const SidebarMenuButton = React.forwardRef<PressableProps, SidebarMenuButtonProps>(
  (
    {
      children,
      iconStyle,
      iconType,
      isActivated,
      isFullWidth = false,
      isOpen,
      onButtonEnter,
      textStyle,
      textTransform,
      ...rest
    },
    ref
  ) => {
    const Icon = iconType ? getIconByName(iconType) : null;
    const contentColor = isActivated ? '#7A8CFF' : '#FFF';
    const iconProps = {
      ...iconStyle,
      color: contentColor,
    };

    return (
      <Button
        ref={ref}
        id="sidebar-menu__button"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        backgroundColor="transparent"
        height={calcSizeForTv(60)}
        px={calcSizeForTv(16)}
        my={calcSizeForTv(12)}
        borderRadius="$md"
        width={isFullWidth ? '100%' : undefined}
        sx={{ ':hover': { backgroundColor: 'background: rgba(209, 215, 255, 0.20);' } }}
        onPress={onButtonEnter}
        {...rest}>
        <View>{Icon && <Icon {...iconProps} />}</View>
        {children && (
          <Text
            opacity={isOpen ? 1 : 0}
            pl={calcSizeForTv(16)}
            fontSize={calcSizeForTv(20)}
            fontWeight="600"
            textTransform={textTransform}
            color={contentColor}
            numberOfLines={1}
            {...textStyle}>
            {children}
          </Text>
        )}
      </Button>
    );
  }
);

export default SidebarMenuButton;
