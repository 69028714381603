import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const UpRightAndDownLeftFromCenter: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.719 2.01704L15.3118 3.08426C14.9675 3.1387 14.8877 3.38936 15.1321 3.63876L16.9703 5.47697L13.4053 9.04451L15.2118 10.8523L18.7794 7.28606L20.6176 9.12427C20.8619 9.36987 21.1176 9.29391 21.1733 8.9445L22.2406 2.53735C22.3001 2.30695 22.1659 2.07654 21.9405 2.01071C21.8696 1.99551 21.7949 1.99551 21.719 2.01704ZM9.12875 20.6188C9.37309 20.8657 9.29713 21.1201 8.94772 21.1758L2.54184 22.2431C2.31017 22.3013 2.07469 22.1671 2.01519 21.9367C1.99494 21.8671 1.99494 21.7861 2.01519 21.7164L3.08115 15.3093C3.13685 14.9649 3.38752 14.8839 3.63818 15.1295L5.47639 16.969L9.04267 13.4014L10.8505 15.2093L7.28548 18.7755L9.12875 20.6188Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default UpRightAndDownLeftFromCenter;
