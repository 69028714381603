export const AGE_RATINGS = [
  'AL',
  'L',
  'A10',
  '10',
  'A12',
  '12',
  'A14',
  '14',
  'A16',
  '16',
  'A18',
  '18',
] as const;

export type AgeRatingKey = (typeof AGE_RATINGS)[number];

export type AgeRatingDefinition = {
  title: string;
  longTitle: string;
  description: string;
  badge: { label: string; color: string };
};

export const AgeRatingDefinitions: Record<AgeRatingKey, AgeRatingDefinition> = {
  L: {
    title: 'Livre',
    longTitle: 'Livre para todos os públicos',
    description: 'Não expõe nenhuma faixa etária a nenhum conteúdo potencialmente prejudicial',
    badge: {
      color: '#00A651',
      label: 'L',
    },
  },
  AL: {
    title: 'Livre',
    longTitle: 'Autoclassificação Livre para todos os públicos',
    description: 'Não expõe nenhuma faixa etária a nenhum conteúdo potencialmente prejudicial',
    badge: {
      color: '#00A651',
      label: 'AL',
    },
  },
  '10': {
    title: '10 Anos',
    longTitle: 'Não recomendado para menores de 10 anos',
    description: 'Conteúdo violento ou linguagem inapropriada para crianças, em menor intensidade',
    badge: {
      color: '#0095DA',
      label: '10',
    },
  },
  A10: {
    title: '10 Anos',
    longTitle: 'Autoclassificação Não recomendado para menores de 10 anos',
    description: 'Conteúdo violento ou linguagem inapropriada para crianças, em menor intensidade',
    badge: {
      color: '#0095DA',
      label: 'A10',
    },
  },
  '12': {
    title: '12 Anos',
    longTitle: 'Não recomendado para menores de 12 anos',
    description: 'As cenas podem conter agressão física, consumo de drogas e insinuação sexual',
    badge: {
      color: '#FBC115',
      label: '12',
    },
  },
  A12: {
    title: '12 Anos',
    longTitle: 'Autoclassificação Não recomendado para menores de 12 anos',
    description: 'As cenas podem conter agressão física, consumo de drogas e insinuação sexual',
    badge: {
      color: '#FBC115',
      label: 'A12',
    },
  },
  '14': {
    title: '14 Anos',
    longTitle: 'Não recomendado para menores de 14 anos',
    description: 'Conteúdo mais violentos e/ou de linguagem sexual mais acentuada',
    badge: {
      color: '#F58220',
      label: '14',
    },
  },
  A14: {
    title: '14 Anos',
    longTitle: 'Autoclassificação Não recomendado para menores de 14 anos',
    description: 'Conteúdo mais violentos e/ou de linguagem sexual mais acentuada',
    badge: {
      color: '#F58220',
      label: 'A14',
    },
  },
  '16': {
    title: '16 Anos',
    longTitle: 'Não recomendado para menores de 16 anos',
    description:
      'Conteúdo violento e/ou sexual intenso. Pode conter cenas de tortura, suicídio ou nudez total',
    badge: {
      color: '#D4080F',
      label: '16',
    },
  },
  A16: {
    title: '16 Anos',
    longTitle: 'Autoclassificação Não recomendado para menores de 16 anos',
    description:
      'Conteúdo violento e/ou sexual intenso. Pode conter cenas de tortura, suicídio ou nudez total',
    badge: {
      color: '#D4080F',
      label: 'A16',
    },
  },
  '18': {
    title: '18 Anos',
    longTitle: 'Não recomendado para menores de 18 anos',
    description:
      'Conteúdo violento e/ou sexual extremo. Pode conter cenas de sexo explícito, mutilação ou abuso sexual',
    badge: {
      color: '#000000',
      label: '18',
    },
  },
  A18: {
    title: '18 Anos',
    longTitle: 'Autoclassificação Não recomendado para menores de 18 anos',
    description:
      'Conteúdo violento e/ou sexual extremo. Pode conter cenas de sexo explícito, mutilação ou abuso sexual',
    badge: {
      color: '#000000',
      label: 'A18',
    },
  },
};

/**
 * Monta a string para filtrar por faixa etaria no CMS
 * @param key AgeRatingKey
 * @returns string
 */
export const getAgeRatingFilterString = (key: AgeRatingKey): string => {
  const items: string[] = AGE_RATINGS.slice(0, AGE_RATINGS.indexOf(key) + 1);

  // inclui programas sem rating definido
  if (key == '18') {
    items.push('null');
  }

  return items.map((item) => `rating=${item}`).join('&');
};
