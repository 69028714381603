import { Flowplayer as FlowplayerType } from '@flowplayer/player';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import VideoPlayer from '../Video/index';
import { usePlayer } from '../context';
import { CustomControls } from './controls';

import { WebDiv } from 'components/WebDiv';

const CTVPlayer = React.forwardRef<
  CustomPlayerRef,
  CustomPlayerProps & { disableControls?: boolean }
>((props) => {
  const { ref: playerRef, isPlaying, isVisible, setIsVisible } = usePlayer();
  const [isInitializing, setIsInitializing] = React.useState(true);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const containerClass = React.useMemo(() => {
    if (isVisible) return 'navigation-container fullscreen';
    return 'navigation-container navigation-ignore';
  }, [isVisible]);

  const playerApi = React.useMemo(
    () => playerRef?.current?.getNativePlayerRef() as FlowplayerType.Player,
    [playerRef.current]
  );

  useEffect(() => {
    if (!playerApi) return;
    setIsInitializing(false);
  }, [playerApi]);

  useEffect(() => {
    if (isPlaying) {
      setIsVisible(true);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!isVisible) playerApi?.pause();
  }, [isVisible, playerApi]);

  return createPortal(
    <WebDiv className={containerClass}>
      <VideoPlayer {...props} ref={playerRef} />
      {!isInitializing && <CustomControls api={playerApi} exit={() => setIsVisible(false)} />}
    </WebDiv>,
    document.querySelector('#root')!
  );
});

export default CTVPlayer;
