import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const PlayerLibrary: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M9.03392 18.8859L9.03403 18.886C9.35939 19.0628 9.72702 19.15 10.1108 19.15C10.6345 19.15 11.198 18.99 11.7673 18.6836L11.7679 18.6832L14.5868 17.1479C14.5868 17.1479 14.5869 17.1479 14.5869 17.1479C15.5728 16.6116 16.15 15.8543 16.15 15.0022C16.15 14.1497 15.5679 13.3925 14.5871 12.8565L14.5869 12.8564L11.7679 11.3211L11.7678 11.3211C10.7808 10.7842 9.80615 10.6935 9.03392 11.1141C8.25895 11.5361 7.85 12.382 7.85 13.4625V16.5375C7.85 17.6136 8.25885 18.4638 9.03392 18.8859ZM9.51978 11.934L9.51978 11.934L9.52048 11.9337C9.69206 11.8395 9.88596 11.7926 10.1108 11.7926C10.4529 11.7926 10.8484 11.9072 11.2821 12.1411C11.2821 12.1411 11.2822 12.1411 11.2823 12.1412L14.1007 13.6762C14.1007 13.6762 14.1007 13.6762 14.1007 13.6762C14.8178 14.0672 15.1647 14.5408 15.1647 14.9978C15.1647 15.4549 14.8178 15.9285 14.1007 16.3195C14.1007 16.3195 14.1007 16.3195 14.1007 16.3195L11.2818 17.8548L11.2817 17.8548C10.5664 18.2448 9.94922 18.2981 9.52067 18.0621C9.09422 17.8273 8.83532 17.3066 8.83532 16.5332V13.4625C8.83532 12.6883 9.09464 12.1644 9.51978 11.934Z"
      fill={color || '#FFF'}
      stroke={color || '#FFF'}
      strokeWidth="0.3"
    />
    <Path
      d="M18.75 8.78003C18.67 8.78003 18.6 8.76999 18.52 8.73999C18.01 8.57999 17.41 8.48999 16.75 8.48999H6.75C6.09 8.48999 5.48998 8.56999 4.97998 8.73999C4.74998 8.80999 4.5 8.77 4.31 8.63C4.12 8.49001 4 8.26002 4 8.02002V6.75C4 5.23 5.23 4 6.75 4H16.75C18.27 4 19.5 5.23 19.5 6.75V8.03003C19.5 8.27003 19.39 8.49001 19.19 8.64001C19.06 8.73001 18.91 8.78003 18.75 8.78003ZM6.75 5.5C6.06 5.5 5.5 6.06 5.5 6.75V7.09003C5.89 7.03003 6.31 7 6.75 7H16.75C17.19 7 17.61 7.03003 18 7.09003V6.75C18 6.06 17.44 5.5 16.75 5.5H6.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M15.75 5.5H7.75C7.34 5.5 7 5.16 7 4.75V3.26001C7 2.01001 8.01001 1 9.26001 1H14.24C15.49 1 16.5 2.01001 16.5 3.26001V4.75C16.5 5.16 16.16 5.5 15.75 5.5ZM8.5 4H15V3.26001C15 2.84001 14.66 2.5 14.24 2.5H9.26001C8.84001 2.5 8.5 2.84001 8.5 3.26001V4Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M16.75 22.5H6.75C3.1 22.5 1 20.4 1 16.75V12.75C1 9.15 2.92003 7.81 4.53003 7.31C5.18003 7.1 5.93 7 6.75 7H16.75C17.57 7 18.32 7.11001 18.98 7.32001C20.58 7.81001 22.5 9.16 22.5 12.75V16.75C22.5 20.4 20.4 22.5 16.75 22.5ZM6.75 8.5C6.09 8.5 5.48998 8.58 4.97998 8.75C3.30998 9.26 2.5 10.57 2.5 12.75V16.75C2.5 19.61 3.89 21 6.75 21H16.75C19.61 21 21 19.61 21 16.75V12.75C21 10.57 20.19 9.26 18.53 8.75C18.01 8.58 17.41 8.5 16.75 8.5H6.75Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default PlayerLibrary;
