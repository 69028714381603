import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Bookmark: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/Svg"
    {...props}>
    <Path
      d="M4.34844 23.3617C4.19844 23.3617 4.04844 23.3242 3.93594 23.2492C3.67344 23.0992 3.52344 22.8367 3.52344 22.4992V2.99922C3.52344 1.72422 4.57344 0.636719 5.88594 0.636719H18.2234C19.4984 0.636719 20.5859 1.68672 20.5859 2.99922V22.4617C20.5859 22.7617 20.4359 23.0242 20.1734 23.1742C19.9109 23.3242 19.5734 23.3242 19.3484 23.1742L11.9984 19.0117L4.76094 23.2117C4.61094 23.2867 4.46094 23.3617 4.34844 23.3617ZM5.84844 2.36172C5.47344 2.36172 5.17344 2.66172 5.17344 3.03672V21.0742L11.5859 17.3617C11.8484 17.2117 12.1859 17.2117 12.4484 17.3617L18.8234 20.9992V2.99922C18.8234 2.62422 18.5234 2.32422 18.1484 2.32422H5.84844V2.36172Z"
      fill={color || 'white'}
    />
  </Svg>
);

export default Bookmark;
