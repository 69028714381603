import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Envelope: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M18.75 20.25H5.25C3.6 20.25 2.25 18.9 2.25 17.25V6.75C2.25 5.1 3.6 3.75 5.25 3.75H18.75C20.4 3.75 21.75 5.1 21.75 6.75V17.25C21.75 18.9 20.4 20.25 18.75 20.25Z"
      stroke={color || '#FFF'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M2.25 7.5L12 13.5L21.75 7.5"
      stroke={color || '#FFF'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Envelope;
