import { ImageBackground } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.ImageBackground`
  flex: 1;
  background-color: #181a20;
`;

export const WrappedImage = styled(ImageBackground)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.Image`
  align-self: center;
  justify-content: center;
`;

export const SplashBg = styled.ImageBackground`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #070d32;
`;

export const LogoBand = styled.Image`
  height: 65px;
  width: 65px;
  margin-bottom: 110px;
`;

export const LogoText = styled.Image`
  height: 250px;
  width: 250px;
  margin-bottom: -30px;
`;
