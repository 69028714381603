import { Text, TouchableOpacity } from 'react-native';

import { usePlayer } from 'components/Player/context';

const AudioPlayButton: React.FC = () => {
  const { play } = usePlayer();
  return (
    <TouchableOpacity onPress={play}>
      <Text>Play</Text>
    </TouchableOpacity>
  );
};

export default AudioPlayButton;
