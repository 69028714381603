import { Image, Pressable, View } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import { ImageBackground } from 'react-native';
import { calcSizeForTv } from 'utils/index';

import BackgroundImage from 'assets/background.png';
import Logo from 'assets/loginLogo2x.png';

import Button from 'components/Button';

type TvHelpScreenLayoutProps = {
  children?: React.ReactNode;
};

const TvHelpScreenLayout: React.FC<TvHelpScreenLayoutProps> = ({ children }) => {
  const linkTo = useLinkTo();

  const onBackButtonPress = () => {
    if (document.referrer.includes(window.location.host)) window.history.back();
    linkTo('/');
  };

  return (
    <ImageBackground
      source={BackgroundImage}
      alt="Imagem de fundo com conteúdos do bandplay em tons azul escuro"
      style={{ height: '100%', backgroundColor: 'rgba(22, 18, 51, 0.8)' }}>
      <View my={calcSizeForTv(120)} ml={calcSizeForTv(70)} mr={calcSizeForTv(200)}>
        <View flexDirection="row" alignItems="center">
          <Button
            variant="back"
            iconType="ChevronLeft"
            backgroundColor="transparent"
            style={{ marginRight: calcSizeForTv(30) }}
            onPress={onBackButtonPress}
          />
          <Image
            source={Logo}
            alt="Logo do B do Bandplay"
            width={calcSizeForTv(290)}
            height={calcSizeForTv(82)}
          />
        </View>
        <View mt={calcSizeForTv(130)}>{children}</View>
      </View>
    </ImageBackground>
  );
};

export default TvHelpScreenLayout;
