import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const BandMore: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.6498 11.175H12.8623V7.35005C12.8623 6.90005 12.4873 6.48755 11.9998 6.48755C11.5498 6.48755 11.1373 6.86255 11.1373 7.35005V11.1375H7.34981C6.89981 11.1375 6.4873 11.5125 6.4873 12C6.4873 12.45 6.86231 12.8625 7.34981 12.8625H11.1373V16.65C11.1373 17.1 11.5123 17.5125 11.9998 17.5125C12.4498 17.5125 12.8623 17.1375 12.8623 16.65V12.8625H16.6498C17.0998 12.8625 17.5123 12.4875 17.5123 12C17.4748 11.55 17.0998 11.175 16.6498 11.175Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M20.9998 0.675049H2.9998C1.7248 0.675049 0.674805 1.72505 0.674805 3.00005V21C0.674805 22.275 1.7248 23.3625 3.0373 23.3625H21.0373C22.3123 23.3625 23.3998 22.3125 23.3998 21V3.00005C23.3623 1.72505 22.2748 0.675049 20.9998 0.675049ZM21.6748 21C21.6748 21.375 21.3748 21.675 20.9998 21.675H2.9998C2.6248 21.675 2.3248 21.375 2.3248 21V3.00005C2.3623 2.62505 2.6248 2.36255 2.9998 2.36255H20.9998C21.3748 2.36255 21.6748 2.66255 21.6748 3.03755V21Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default BandMore;
