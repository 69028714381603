import { FlatList, View } from '@gluestack-ui/themed';
import { useId, useMemo } from 'react';

import FocusView from 'components/FocusView';
import { WebDiv } from 'components/WebDiv';

type GridProps<T> = {
  data: T[];
  numColumns: number;
  renderItem: (item: T) => React.ReactElement;
  gap?: number;
  onEndReached?: () => void;
  isPlaceholderNeeded?: boolean | undefined;
};

type GridItem<T> = {
  placeholder: boolean;
  itemData?: T;
};

const Grid = <T,>(props: GridProps<T>): React.ReactElement => {
  const { data, numColumns, gap = 4, isPlaceholderNeeded = undefined } = props;
  const gridId = useId();

  const dataWithPlaceholder = useMemo<GridItem<T>[]>(() => {
    const lenDivisionByNumCols = data.length % numColumns;
    const needsPlaceholder = isPlaceholderNeeded ?? lenDivisionByNumCols !== 0;
    const placeholderQty = needsPlaceholder ? Math.abs(lenDivisionByNumCols - numColumns) : 0;

    const placeholderCreator = (): GridItem<T> => ({ placeholder: true });
    const placeholders = Array.from({ length: placeholderQty }, placeholderCreator);

    const buildedData = data.map((itemData) => ({ placeholder: false, itemData }));
    return [...buildedData, ...placeholders];
  }, [data, numColumns, isPlaceholderNeeded]);

  const renderItemProxy = (item: GridItem<T>) => {
    const { placeholder, itemData } = item;
    if (placeholder) return <View flex={1} p={gap} />;

    return (
      <View flex={1} p={gap}>
        {props.renderItem(itemData!)}
      </View>
    );
  };

  return (
    <FocusView>
      <WebDiv className="navigation-container navigation-grid">
        <FlatList
          gap="$4"
          key={numColumns}
          numColumns={numColumns}
          data={dataWithPlaceholder}
          removeClippedSubviews
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(i, index) => `${gridId}-${index}`}
          renderItem={({ item }) => renderItemProxy(item as GridItem<T>)}
          onEndReached={props.onEndReached}
          onEndReachedThreshold={0.2}
        />
      </WebDiv>
    </FocusView>
  );
};

export default Grid;
