import { Box, Button, ButtonSpinner, ButtonText } from '@gluestack-ui/themed';
import React from 'react';
import { PressableProps } from 'react-native';

import { ButtonProps } from '.';

import { getIconByName } from 'components/Icons';

type SolidButtonProps = Omit<ButtonProps, 'gradientVariant' | 'variant'>;

const SolidButton = React.forwardRef<PressableProps, SolidButtonProps>(
  (
    {
      backgroundColor = '#1240ff',
      children,
      iconStyle,
      iconType,
      isFullWidth = false,
      isLoading = false,
      size = 'md',
      textStyle,
      textTransform,
      ...rest
    },
    ref
  ) => {
    const Icon = iconType ? getIconByName(iconType) : null;
    return (
      <Button
        ref={ref}
        size={size}
        backgroundColor={backgroundColor}
        width={isFullWidth ? '100%' : undefined}
        {...rest}>
        {Icon && (
          <Box mr="$1">
            <Icon {...iconStyle} />
          </Box>
        )}
        {children && (
          <ButtonText textTransform={textTransform} {...textStyle}>
            {children}
          </ButtonText>
        )}
        {isLoading && <ButtonSpinner color="white" size="small" marginLeft="$1" />}
      </Button>
    );
  }
);

export default SolidButton;
