import React from 'react';
import { ProgramType } from 'utils/enum';
import { Platform } from 'utils/platform';

import Search from './Search';
import SearchTV from './Search.ctv';

export const SEARCH_MENU_OPTION = [
  { title: 'Tudo', value: undefined },
  { title: 'Filmes', value: ProgramType.MOVIE },
  { title: 'Séries', value: ProgramType.TV_SHOW },
  { title: 'Programas', value: ProgramType.PROGRAM },
  { title: 'Ao Vivo', value: ProgramType.LIVE },
  { title: 'Podcast', value: ProgramType.PODCAST },
  { title: 'Documentário', value: ProgramType.DOCUMENTARY },
  { title: 'Rádio', value: ProgramType.RADIO },
];

const SearchScreen: React.FC = () => (Platform.isTV ? <SearchTV /> : <Search />);

export default SearchScreen;
