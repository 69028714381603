import { Box, Button, Image, Modal, ModalBody, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useModalConfig } from 'context/ModalContext';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import useFirebaseAnalytics from 'services/analytics';
import { Platform } from 'utils/platform';

const BandMore: React.FC<any> = () => {
  const { isModalVisible, closeModal } = useModalConfig();
  const { BandMoreTab } = useGlobalConfig();
  const { height } = useWindowDimensions();
  const navigation = useNavigation();
  const { logEventActionName } = useFirebaseAnalytics();

  return (
    <Modal
      backgroundColor="#070D31"
      isOpen={isModalVisible}
      height={BandMoreTab?.product?.length! * 100}
      marginTop="auto"
      marginBottom={Platform.OS === 'ios' ? 90 : 70}>
      <Box
        width="$full"
        height={height}
        position="absolute"
        backgroundColor="black"
        opacity={0.3}
        bottom={BandMoreTab?.product?.length! * 100}
      />
      <ModalBody height="$full">
        <VStack space="4xl" height={BandMoreTab?.product?.length! * 100} justifyContent="center">
          {BandMoreTab?.product.map((data, index) => (
            <Button
              key={index}
              backgroundColor="transparent"
              onPress={() => {
                if (Platform.OS === 'web') {
                  if (typeof window !== 'undefined') {
                    window.open(data.productUrl, '_self');
                  } else {
                    WebBrowser.openBrowserAsync(data.productUrl);
                  }
                } else {
                  logEventActionName(
                    {
                      reference: data?.title,
                      component: 'band+',
                    },
                    'action'
                  );
                  navigation.navigate('WebView', { data });
                  closeModal();
                }
              }}>
              <Image
                w={data.productImage.width}
                h={data.productImage.height}
                alt={data.productImage.title}
                source={{ uri: data.productImage.url }}
              />
            </Button>
          ))}
        </VStack>
      </ModalBody>
    </Modal>
  );
};

export default BandMore;
