import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Streaming: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M4.78751 3.27117C5.13899 3.63273 5.13899 4.21893 4.78751 4.58049C0.804169 8.67817 0.804169 15.3218 4.78751 19.4195C5.13899 19.781 5.13899 20.3673 4.78751 20.7288C4.43604 21.0904 3.8662 21.0904 3.51472 20.7288C-1.17157 15.908 -1.17157 8.09197 3.51472 3.27117C3.8662 2.90961 4.43604 2.90961 4.78751 3.27117ZM20.4853 3.27117C25.1716 8.09197 25.1716 15.908 20.4853 20.7288C20.1338 21.0904 19.564 21.0904 19.2125 20.7288C18.861 20.3673 18.861 19.781 19.2125 19.4195C23.1959 15.3218 23.1959 8.67817 19.2125 4.58049C18.861 4.21893 18.861 3.63273 19.2125 3.27117C19.564 2.90961 20.1338 2.90961 20.4853 3.27117ZM8.18162 6.7627C8.53309 7.12425 8.53309 7.71047 8.18162 8.07202C6.07279 10.2414 6.07279 13.7585 8.18162 15.928C8.53309 16.2895 8.53309 16.8758 8.18162 17.2373C7.83016 17.5989 7.2603 17.5989 6.90883 17.2373C4.09705 14.3448 4.09705 9.65514 6.90883 6.7627C7.2603 6.40114 7.83016 6.40114 8.18162 6.7627ZM17.0911 6.7627C19.903 9.65514 19.903 14.3448 17.0911 17.2373C16.7396 17.5989 16.1699 17.5989 15.8184 17.2373C15.4669 16.8758 15.4669 16.2895 15.8184 15.928C17.9272 13.7585 17.9272 10.2414 15.8184 8.07202C15.4669 7.71047 15.4669 7.12425 15.8184 6.7627C16.1699 6.40114 16.7396 6.40114 17.0911 6.7627ZM12 10.1483C12.9941 10.1483 13.8 10.9774 13.8 12C13.8 13.0226 12.9941 13.8516 12 13.8516C11.0059 13.8516 10.2 13.0226 10.2 12C10.2 10.9774 11.0059 10.1483 12 10.1483Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Streaming;
