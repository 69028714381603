import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidPlayCircular: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.52341 20.3768C9.63705 20.848 10.8344 21.0896 12.0436 21.0872C13.2528 21.0895 14.45 20.8477 15.5635 20.3763C16.638 19.922 17.6144 19.2639 18.4386 18.4383C19.2643 17.6144 19.9224 16.6382 20.3765 15.5638C20.8477 14.4501 21.0894 13.2528 21.0872 12.0436C21.0894 10.8345 20.8478 9.63746 20.3768 8.52394C19.9228 7.44945 19.2646 6.47324 18.4388 5.64939C17.615 4.82323 16.6386 4.16497 15.5638 3.71119C14.4502 3.23949 13.2529 2.9976 12.0436 3.00002C10.8344 2.99738 9.63705 3.23891 8.52341 3.71014C7.44874 4.16393 6.47247 4.8222 5.64887 5.64835C4.823 6.47219 4.16478 7.4484 3.71066 8.52289C3.2394 9.63672 2.9977 10.8342 3.00002 12.0436C2.9977 13.253 3.2394 14.4505 3.71066 15.5643C4.16487 16.6387 4.82308 17.6149 5.64887 18.4388C6.47281 19.2645 7.449 19.9226 8.52341 20.3768ZM10.2089 10.0765C10.2098 9.78732 10.444 9.5531 10.7332 9.55224C10.8252 9.55159 10.9157 9.57557 10.9953 9.6217L14.4054 11.5961C14.5684 11.6888 14.6686 11.8623 14.6675 12.0498C14.6685 12.2373 14.5683 12.4107 14.4054 12.5033L10.9953 14.478C10.9158 14.5246 10.8253 14.5491 10.7332 14.549C10.444 14.5481 10.2098 14.3139 10.2089 14.0247V10.0765Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidPlayCircular;
