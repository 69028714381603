/* eslint-disable import/order */
import { Box, HStack } from '@gluestack-ui/themed';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { useState } from 'react';
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native';
import { AttachStep } from 'react-native-spotlight-tour';
import { useDispatch, useSelector } from 'react-redux';
import useFirebaseAnalytics from 'services/analytics';
import { backendApiSlice } from 'services/backend';
import { logoutAction } from 'store/actions';
import { RootState } from 'store/index';
import { toggleKidProfile } from 'store/slices/profileSlice';
import { isMobile } from 'utils/deviceInfo';

import ModalAgeRating from '../ModalAgeRating';
import BandMoreMenu from './BandMoreMenu.web';
import MenuArea from './MenuArea';
import ModalConfirmExitKids from './ModalConfirmExitKids';

import { Icons } from 'components/Icons';
import StreamConnectButton from 'components/Player/Stream/UI/ConnectButton';

const HeaderMenuRightArea: React.FC = () => {
  const route = useRoute();
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const { logEventAction } = useFirebaseAnalytics();
  const user = useSelector((state: RootState) => state.auth);
  const { width } = useWindowDimensions();

  const [modalAgeRatingVisible, setModalAgeRatingVisible] = useState(false);
  const [modalKidProfileVisible, setModalKidProfileVisible] = useState(false);

  const handleKidLogout = () => {
    dispatch(toggleKidProfile());
    setModalKidProfileVisible(false);
    dispatch(logoutAction());
    dispatch(backendApiSlice.util.resetApiState());
  };

  const handleSearchPress = () => {
    logEventAction({ component: 'Header', reference: 'Busca' });
    isMobile ? navigation.navigate('Search') : linkTo('/buscar');
  };

  return (
    <>
      <HStack space="2xl" alignItems="center" paddingHorizontal={5}>
        {route.name !== 'Search' && (
          <AttachStep
            index={1}
            children={
              <Box borderWidth={1} borderColor="transparent">
                <TouchableOpacity
                  style={{
                    width: 15,
                    height: 25,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onPress={handleSearchPress}>
                  <Icons.MagnifyinGlass height="22" width="22" />
                </TouchableOpacity>
              </Box>
            }
          />
        )}
        <StreamConnectButton />

        <MenuArea
          userData={user}
          toggleAgeRatingModal={() => setModalAgeRatingVisible(!modalAgeRatingVisible)}
          toggleKidProfileModal={() => setModalKidProfileVisible(!modalKidProfileVisible)}
        />
        {Platform.OS === 'web' && width > 479 && <BandMoreMenu />}
      </HStack>

      <ModalConfirmExitKids
        isOpen={modalKidProfileVisible}
        handleKidLogout={handleKidLogout}
        toggleModal={() => setModalKidProfileVisible(!modalKidProfileVisible)}
      />

      <ModalAgeRating
        isOpen={modalAgeRatingVisible}
        toggleModal={() => setModalAgeRatingVisible(!modalAgeRatingVisible)}
      />
    </>
  );
};

export default HeaderMenuRightArea;
