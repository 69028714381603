import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowUp: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11.7258 20.0003C11.3461 20.0003 11.0323 19.7182 10.9827 19.3521L10.9758 19.2503L10.9758 4.25033C10.9758 3.83612 11.3116 3.50033 11.7258 3.50033C12.1055 3.50033 12.4193 3.78249 12.469 4.14856L12.4758 4.25033L12.4758 19.2503C12.4758 19.6645 12.14 20.0003 11.7258 20.0003Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M6.23302 10.8291C5.94076 11.1226 5.46589 11.1236 5.17236 10.8313C4.90552 10.5656 4.88042 10.149 5.09764 9.85495L5.17008 9.77068L11.1941 3.72068C11.4606 3.45304 11.8787 3.42869 12.1728 3.64765L12.257 3.72063L18.282 9.77063C18.5743 10.0641 18.5733 10.539 18.2798 10.8313C18.013 11.097 17.5963 11.1204 17.3031 10.9019L17.2191 10.8291L11.7253 5.31306L6.23302 10.8291Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ArrowUp;
