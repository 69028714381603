/* eslint-disable import/order */
import { Box, Spinner } from '@gluestack-ui/themed';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetProgramsQuery } from 'services/cms';
import { RootState } from 'store/index';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice } from 'utils/index';
import { useDebounce } from 'utils/useDebounce';

import Section from 'components/Section';

type SearchVerticalListProps = {
  searchInputValue?: string;
  categoryValue?: ProgramType;
  containerWidth?: number | undefined;
  topContainerHeight?: number | undefined;
};
const SearchVerticalList: React.FC<SearchVerticalListProps> = ({
  categoryValue,
  searchInputValue,
  ...props
}) => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);

  const delayedValue = useDebounce(searchInputValue);

  const profile = useSelector((state: RootState) => state.profile);

  const { data, isLoading, isFetching, isSuccess } = useGetProgramsQuery({
    q: delayedValue,
    type: categoryValue,
    page,
  });

  useEffect(() => {
    setIsDone(false);
    setItems([]);
    setPage(1);
  }, [delayedValue, categoryValue, profile.ageRating]);

  useEffect(() => {
    if (isFetching) return;
    setItems((old) => [...old, ...(data?.items || [])]);
    if (isSuccess) setIsDone(true);
  }, [data, isFetching, isSuccess]);

  const formattedData: CmsCard[] =
    items.map<CmsCard>((item) => ({
      type: item.type,
      title: item.title,
      image: item.imageVertical || item.image,
      link: `/${item.url}`,
      height: item.imageVertical?.height || item.image?.height,
    })) || [];

  const HandleOnEndReached = () => {
    setPage(page + 1);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box flexDirection="column">
      {isDone && (
        <Section
          onEndReached={HandleOnEndReached}
          vertical
          items={formattedData}
          _name="section"
          showTitle={false}
          loading={isLoading}
          variant="section.medium"
          title={searchInputValue || ''}
          {...props}
        />
      )}
      {isFetching && (
        <Box position="absolute" w="$full" top={0} bottom={calcSizeForDevice(20)}>
          <Spinner size="small" />
        </Box>
      )}
    </Box>
  );
};

export default SearchVerticalList;
