import { createSlice } from '@reduxjs/toolkit';
import { backendApiSlice } from 'services/backend';
import { logoutAction } from 'store/actions';

export type AuthState = {
  id: string;
  bandId: string;
  name: string;
  email: string;
  userToken: string | null;
  isAuthenticated: boolean;
  suppressRatingModal: boolean;
  hasCompletedOnboarding: boolean;
  fiveDaysFromNow: Date | null;
};

const initialState: AuthState = {
  id: '',
  bandId: '',
  name: '',
  email: '',
  userToken: null,
  isAuthenticated: false,
  suppressRatingModal: false,
  fiveDaysFromNow: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutAction, () => initialState);

    builder.addMatcher(backendApiSlice.endpoints.logIn.matchFulfilled, (state, { payload }) => {
      state.id = payload.id;
      state.bandId = payload.bandId;
      state.name = payload.name;
      state.email = payload.email;
      state.userToken = payload.accessToken;
      state.isAuthenticated = true;
      state.suppressRatingModal = false;
      state.fiveDaysFromNow = null;
    });
  },
});

export default authSlice.reducer;
