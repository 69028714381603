import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Band: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="42"
    height="24"
    viewBox="0 0 42 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M21.046 21.3244C23.5679 21.3244 25.9388 20.356 27.7217 18.5976C29.5046 16.8391 30.4872 14.5014 30.4872 12.014C30.4872 9.52716 29.5046 7.18913 27.7217 5.43068C25.9388 3.67222 23.5679 2.70389 21.046 2.70389C18.5239 2.70389 16.153 3.67222 14.3694 5.43068C12.5859 7.18913 11.6036 9.52716 11.6036 12.014C11.6036 14.5014 12.5862 16.8391 14.3694 18.5976C16.153 20.356 18.5239 21.3244 21.046 21.3244Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M6.92884 19.7056C11.0154 22.5255 15.734 23.9699 20.9521 23.9994C21.0023 24 21.0515 24 21.1 24C26.1768 24 30.8455 22.5533 34.9764 19.6994C38.6409 17.1683 40.8465 14.1964 41.6245 13.0359C42.1309 12.2616 42.1266 11.7746 41.6025 10.9528C41.0614 10.1031 38.9974 7.12244 35.142 4.43673C33.2326 3.10746 31.1742 2.05924 29.0223 1.32151C26.4628 0.445031 23.7465 0 20.9472 0H20.9249C15.8196 0.00485136 11.1443 1.44926 7.02625 4.29475C3.57102 6.68226 1.39555 9.46856 0.406377 10.9169C-0.13872 11.7154 -0.135439 12.4211 0.416217 13.2044C2.2355 15.7921 4.42704 17.9785 6.92884 19.7056ZM11.4487 5.15829C14.0292 2.74878 17.3913 1.42145 20.9144 1.42145C26.041 1.42145 29.412 4.02178 30.9653 5.5713C32.9767 7.57879 34.162 9.98669 34.1374 12.0133C34.1768 13.9635 32.921 16.5124 30.9387 18.5066C28.3126 21.148 24.775 22.6035 20.9777 22.6035C17.4093 22.6035 14.0131 21.2473 11.4149 18.7851C9.26207 16.7443 7.87047 14.0864 7.87047 12.0126C7.87047 9.84503 9.24206 7.21882 11.4487 5.15829Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Band;
