import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowRotateRight: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0479 5.36486V3.53566C12.0479 3.07666 12.3955 2.80409 12.7431 3.16988L16.567 5.73064C16.8292 5.83831 16.9622 6.15394 16.8564 6.42991C16.8052 6.56988 16.6997 6.68123 16.567 6.73478L12.8317 9.38876C12.3955 9.75454 12.0479 9.47858 12.0479 9.02298V7.19406C9.16823 7.19406 6.834 9.65056 6.834 12.6811C6.834 15.7116 9.16823 18.1684 12.0479 18.1684C14.9276 18.1684 17.2621 15.7116 17.2621 12.6811H19C19 16.7228 15.8888 19.9973 12.0479 19.9973C8.20708 19.9973 5.09585 16.7228 5.09585 12.6811C5.09585 8.63935 8.20708 5.36486 12.0479 5.36486Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ArrowRotateRight;
