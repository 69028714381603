import { useEffect } from 'react';
import useFirebaseAnalytics from 'services/analytics';

export const logHelpPageViewEvent = (templateKey: string) => {
  const { logEventPageView } = useFirebaseAnalytics();

  const callAnalyticsLog = () => {
    logEventPageView({
      page_title: 'Ajuda',
      template: templateKey,
      page_location: 'ajuda',
    });
  };

  useEffect(() => {
    callAnalyticsLog();
  }, []);
};
