import { Pressable, Text, VStack, View, useBreakpointValue } from '@gluestack-ui/themed';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackParamList } from 'navigation/AppNavigator';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { getAppVersion } from 'utils/getAppVersion';

import { Icons } from 'components/Icons';
import Separator from 'components/Separator';

type FooterItemConfig = { title: string; route: keyof RootStackParamList };

const footerConfig: FooterItemConfig[] = [
  { title: 'Não encontrou o que procurava? Fale Conosco', route: 'ContactUs' },
  { title: 'Perguntas Frequentes', route: 'FaqSection' },
  { title: 'Termos e Condições', route: 'LegalTerms' },
];

export function HelperFooter() {
  const route = useRoute();
  const [version, setVersion] = useState('');
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const contentGap = useBreakpointValue({ base: '$4', xl: '$12', lg: '$12' });
  const separatorMaxWidth = useBreakpointValue({ base: undefined, xl: 600, lg: 600 });
  const contentDirection = useBreakpointValue({ base: 'column', xl: 'row', lg: 'row' });

  useEffect(() => {
    getAppVersion().then(setVersion);
  }, []);

  const renderFooterItem = (config: FooterItemConfig, index: number) => {
    const isCurrentRoute = route.name === config.route;
    const itemColor = isCurrentRoute ? theme.colors.base.white : theme.colors.base.purple;

    return (
      <Pressable
        gap="$2"
        key={index}
        width="auto"
        alignItems="center"
        flexDirection="row"
        disabled={isCurrentRoute}
        onPress={() => navigation.navigate<any>(config.route)}>
        <Text color={itemColor} fontWeight="$semibold">
          {config.title}
        </Text>
        <Icons.ChevronRight color={theme.colors.base.white} />
      </Pressable>
    );
  };

  return (
    <VStack gap="$4" pb="$2">
      <View mb="$1" maxWidth={separatorMaxWidth}>
        <Separator />
      </View>

      <View gap={contentGap} flexDirection={contentDirection}>
        {footerConfig.map(renderFooterItem)}
      </View>

      <View mb="$4" style={{ marginBottom: 10 }}>
        <Text size="xs" color={theme.colors.base.white} opacity={0.3}>
          versão: {version}
        </Text>
      </View>
    </VStack>
  );
}
