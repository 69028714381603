import { Heading, ScrollView, View, useBreakpointValue } from '@gluestack-ui/themed';
import React, { PropsWithChildren } from 'react';
import theme from 'theme';

import HelpBackground from 'components/Help/HelpBackground';
import { HelperFooter } from 'components/Help/HelperFooter';
import SafeMenuView from 'components/SafeMenuView';

type HelpPageLayoutProps = { title?: string } & PropsWithChildren;

const HelpPageLayout: React.FC<HelpPageLayoutProps> = ({ title, children }) => {
  const paddingX = useBreakpointValue({ xl: '$3', lg: '$3', base: '$0' });
  const contentSpacing = useBreakpointValue({ xl: '$8', lg: '$8', base: '$4' });
  const titleFontSize = useBreakpointValue({ xl: '$3xl', lg: '$3xl', base: '$lg' });

  return (
    <ScrollView>
      <View
        flex={1}
        w="$full"
        overflow="hidden"
        position="relative"
        justifyContent={'space-between'}>
        <HelpBackground />

        <View flex={1} px={paddingX} gap={contentSpacing}>
          {Boolean(title) && (
            <Heading px="$4" color={theme.colors.base.white} fontSize={titleFontSize}>
              {title}
            </Heading>
          )}

          <View flex={1}>{children}</View>

          <View px="$4" pt="$4">
            <HelperFooter />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default HelpPageLayout;
