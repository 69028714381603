import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ClapperboardPlays: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M21.4795 7.85938H2.51953C2.10953 7.85938 1.76953 7.51937 1.76953 7.10938C1.76953 6.69938 2.09953 6.35938 2.51953 6.35938H21.4795C21.8895 6.35938 22.2295 6.69938 22.2295 7.10938C22.2295 7.51937 21.8995 7.85938 21.4795 7.85938Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M8.51953 7.71937C8.10953 7.71937 7.76953 7.37937 7.76953 6.96937V2.10937C7.76953 1.69937 8.10953 1.35938 8.51953 1.35938C8.92953 1.35938 9.26953 1.69937 9.26953 2.10937V6.96937C9.26953 7.37937 8.92953 7.71937 8.51953 7.71937Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M15.4805 7.26937C15.0705 7.26937 14.7305 6.92937 14.7305 6.51937V2.10937C14.7305 1.69937 15.0705 1.35938 15.4805 1.35938C15.8905 1.35938 16.2305 1.69937 16.2305 2.10937V6.51937C16.2305 6.93937 15.9005 7.26937 15.4805 7.26937Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.09 18.1193C10.73 18.1193 10.39 18.0293 10.08 17.8593C9.4 17.4593 9 16.6593 9 15.6493V13.2493C9 12.2393 9.4 11.4293 10.09 11.0293C10.78 10.6293 11.68 10.6893 12.55 11.1993L14.63 12.3993C15.5 12.8993 16.01 13.6493 16.01 14.4493C16.01 15.2493 15.5 15.9993 14.62 16.4993L12.54 17.6993C12.06 17.9793 11.56 18.1193 11.09 18.1193ZM11.1 12.2693C11 12.2693 10.91 12.2893 10.84 12.3293C10.63 12.4493 10.5 12.7893 10.5 13.2493V15.6493C10.5 16.0993 10.63 16.4393 10.84 16.5693C11.05 16.6893 11.41 16.6293 11.8 16.3993L13.88 15.1993C14.27 14.9693 14.51 14.6893 14.51 14.4493C14.51 14.2093 14.28 13.9293 13.88 13.6993L11.8 12.4993C11.54 12.3493 11.29 12.2693 11.1 12.2693Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ClapperboardPlays;
