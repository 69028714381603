import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Tv: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M21.1667 6H2.83333C2.3731 6 2 6.39504 2 6.88235V20.1176C2 20.605 2.3731 21 2.83333 21H21.1667C21.6269 21 22 20.605 22 20.1176V6.88235C22 6.39504 21.6269 6 21.1667 6Z"
      stroke={color || '#FFF'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6 2L12 6L18 2"
      stroke={color || '#FFF'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Tv;
