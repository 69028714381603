import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ExpandIcon: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M1.71429 1.5C0.766071 1.5 0 2.17031 0 3V7.5C0 8.32969 0.766071 9 1.71429 9C2.6625 9 3.42857 8.32969 3.42857 7.5V4.5H6.85714C7.80536 4.5 8.57143 3.82969 8.57143 3C8.57143 2.17031 7.80536 1.5 6.85714 1.5H1.71429ZM3.42857 16.5C3.42857 15.6703 2.6625 15 1.71429 15C0.766071 15 0 15.6703 0 16.5V21C0 21.8297 0.766071 22.5 1.71429 22.5H6.85714C7.80536 22.5 8.57143 21.8297 8.57143 21C8.57143 20.1703 7.80536 19.5 6.85714 19.5H3.42857V16.5ZM17.1429 1.5C16.1946 1.5 15.4286 2.17031 15.4286 3C15.4286 3.82969 16.1946 4.5 17.1429 4.5H20.5714V7.5C20.5714 8.32969 21.3375 9 22.2857 9C23.2339 9 24 8.32969 24 7.5V3C24 2.17031 23.2339 1.5 22.2857 1.5H17.1429ZM24 16.5C24 15.6703 23.2339 15 22.2857 15C21.3375 15 20.5714 15.6703 20.5714 16.5V19.5H17.1429C16.1946 19.5 15.4286 20.1703 15.4286 21C15.4286 21.8297 16.1946 22.5 17.1429 22.5H22.2857C23.2339 22.5 24 21.8297 24 21V16.5Z" fill={color || '#FFF'} />
  </Svg>
);

export default ExpandIcon;
