import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

const Number04: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544.9 629.1" {...props}>
    <Path
      fill={innerColor || 'none'}
      d="M1.4,512.6v-132.6L257.2,0h113.2v161.7h-62.1l-146.1,219.1v2.1h381.3v129.6H1.4ZM308.8,629.1v-157.1l.8-58.3V0h163.4v629.1h-164.2Z"
    />
    <Path
      fill={color || 'white'}
      d="M477,629.1h-179l.2-111.2-298.2-1.7v-141.4L252.4,0h224.6v370.8s67.9,0,67.9,0v145.5h-67.9v112.8ZM318,609.1h139v-112.8h67.9v-105.5h-67.9V20s-193.9,0-193.9,0L20,381v115.4l298.2,1.7-.2,111.1Z"
    />
    <Path
      fill={color || 'white'}
      d="M317.9,390.8h-162.1v-15c-.1,0,162.4-243.6,162.4-243.6l-.4,258.7ZM183.1,370.8h114.9l.2-172.5-115.1,172.5Z"
    />
  </Svg>
);

export default Number04;
