import { useEffect, useState } from 'react';
import { View, ViewProps } from 'react-native';
import Animated, {
  useAnimatedRef,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

export const useCollapseWithAnimation = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const reanimatedOpenFlag = useSharedValue(false);
  const contentRef = useAnimatedRef<View>();
  const opacity = useDerivedValue(() => (reanimatedOpenFlag.value ? withSpring(1) : withTiming(0)));

  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    height: opacity.value === 0 ? 0 : 'auto',
    display: opacity.value === 0 ? 'none' : 'flex',
  }));

  useEffect(() => {
    reanimatedOpenFlag.value = isOpen;
  }, [isOpen]);

  return {
    isOpen,
    animatedContainerStyle,
    toggleCollapse: () => setIsOpen(!isOpen),
    contentRef: contentRef as React.Ref<Animated.AnimateProps<ViewProps>>,
  };
};
