import { Image, Link, View } from '@gluestack-ui/themed';
import { useRoute } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, ListRenderItem, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import DynamicListItem from './ListItem';

type DynamicListProps = {
  items: CmsHomeModule[];
  refreshControl?: ReactElement;
  initialNumToRender?: number;
  maxToRenderPerBatch?: number;
  initialNumToLoad?: number;
  maxToLoadPerBatch?: number;
};

const DynamicList: React.FC<DynamicListProps> = ({
  items,
  refreshControl,
  initialNumToRender = 3,
  maxToRenderPerBatch = 3,
  initialNumToLoad = 5,
  maxToLoadPerBatch = 1,
}) => {
  const { handleScroll, offSetScroll, PromotionArea } = useGlobalConfig();

  const itemsWithSuggestionSection: CmsHomeModule[] = React.useMemo(() => {
    const keepWatchingIndex = items.findIndex((item) => item._name === 'sectionWatchingList');

    return [
      ...items.slice(0, keepWatchingIndex),
      { _name: 'sectionSuggestion' },
      ...items.slice(keepWatchingIndex),
    ];
  }, [items]);
  const { isKid } = useSelector((state: RootState) => state.profile);
  const [end, setEnd] = useState(Math.min(itemsWithSuggestionSection.length, initialNumToLoad));
  const dataItems = itemsWithSuggestionSection.slice(0, end);
  const { width, height } = useWindowDimensions();
  const { name: routeName } = useRoute();

  const isPromotionActive = PromotionArea?.is_active && !Platform.isTV && !isKid;
  const isWeb = Platform.OS === 'web';
  const isSmallScreen = height <= 700;

  const renderItem: ListRenderItem<CmsHomeModule> = ({ item }) => (
    <View marginBottom={calcSizeForDevice(15, 30)}>
      <DynamicListItem {...item} />
    </View>
  );

  const flatListRef = useRef<FlatList<any>>(null);

  useEffect(() => {
    flatListRef.current?.scrollToOffset({
      offset: offSetScroll!,
      animated: true,
    });
  }, [offSetScroll]);

  const handleEndReached = useCallback(() => {
    if (end < itemsWithSuggestionSection.length) {
      setEnd(Math.min(itemsWithSuggestionSection.length, end + maxToLoadPerBatch));
    }
  }, [end, maxToLoadPerBatch]);

  return (
    <FlatList
      ref={flatListRef}
      refreshControl={refreshControl}
      data={dataItems}
      keyExtractor={(item, index) => item._name + index.toString()}
      renderItem={renderItem}
      initialNumToRender={initialNumToRender}
      maxToRenderPerBatch={maxToRenderPerBatch}
      onEndReached={handleEndReached}
      onEndReachedThreshold={1}
      contentContainerStyle={{ paddingBottom: 40 }}
      onScroll={handleScroll}
      ListHeaderComponent={
        <>
          {routeName === 'Home' && isPromotionActive && (
            <View width="$full">
              <Link href={PromotionArea.program_url}>
                <Image
                  source={{
                    uri:
                      isWeb && width > 500
                        ? PromotionArea?.desktop_image.url
                        : PromotionArea?.mobile_image.url,
                  }}
                  alt="image"
                  width="$full"
                  height={isWeb ? (isSmallScreen ? 80 : 100) : 80}
                />
              </Link>
            </View>
          )}
        </>
      }
    />
  );
};

export default DynamicList;
