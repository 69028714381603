import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const CircleMinus: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11.9219 22.75C6.00187 22.75 1.17188 17.93 1.17188 12C1.17188 6.07 6.00187 1.25 11.9219 1.25C17.8419 1.25 22.6719 6.07 22.6719 12C22.6719 17.93 17.8519 22.75 11.9219 22.75ZM11.9219 2.75C6.82188 2.75 2.67188 6.9 2.67188 12C2.67188 17.1 6.82188 21.25 11.9219 21.25C17.0219 21.25 21.1719 17.1 21.1719 12C21.1719 6.9 17.0219 2.75 11.9219 2.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M15.9219 12.75H7.92188C7.51188 12.75 7.17188 12.41 7.17188 12C7.17188 11.59 7.51188 11.25 7.92188 11.25H15.9219C16.3319 11.25 16.6719 11.59 16.6719 12C16.6719 12.41 16.3419 12.75 15.9219 12.75Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default CircleMinus;
