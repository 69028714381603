import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import Constants from 'expo-constants';
import { useMemo, useRef } from 'react';

export const mediaApiSlice = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: Constants.expoConfig?.extra?.bandplayMediaApi,
  }),
  endpoints: (builder) => ({
    getMediaStream: builder.query<MediaApi, string>({
      query: (id) => `/api/media/${id}?type=1`,
    }),
  }),
});

const { useGetMediaStreamQuery } = mediaApiSlice;

const getMediaStreamUrl = async (mediaId: string): Promise<string> => {
  const data = await axios.get<MediaApi>(
    `${Constants.expoConfig?.extra?.bandplayMediaApi}/api/media/${mediaId}?type=1`
  );
  if (data.status !== 200) {
    throw new Error();
  }

  return data.data.streamUrl;
};

const useGetLazyMediaStreamUrl = (mediaId: string): { fetch: () => Promise<string> } => {
  const cache = useRef<string>();

  return useMemo(
    () => ({
      async fetch() {
        if (!cache.current) {
          cache.current = await getMediaStreamUrl(mediaId);
          setTimeout(() => {
            cache.current = undefined;
          }, 5000);
        }
        return cache.current;
      },
    }),
    [mediaId]
  );
};

export { getMediaStreamUrl, useGetLazyMediaStreamUrl, useGetMediaStreamQuery };
