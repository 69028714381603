import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const VideoSlash: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M4.3 19.92C4.13 19.92 3.96 19.86 3.82 19.75C2.81 18.92 2.25 17.59 2.25 16V8C2.25 4.58 3.58 3.25 7 3.25H13C15.04 3.25 17.18 3.63 17.64 6.48C17.71 6.89 17.43 7.27 17.02 7.34C16.61 7.41 16.23 7.13 16.16 6.72C15.95 5.42 15.4 4.75 13 4.75H7C4.42 4.75 3.75 5.42 3.75 8V16C3.75 16.65 3.88 17.86 4.77 18.59C5.09 18.85 5.14 19.33 4.87 19.65C4.73 19.83 4.51 19.92 4.3 19.92Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M13 20.75H8C7.59 20.75 7.25 20.41 7.25 20C7.25 19.59 7.59 19.25 8 19.25H13C15.58 19.25 16.25 18.58 16.25 16V11C16.25 10.59 16.59 10.25 17 10.25C17.41 10.25 17.75 10.59 17.75 11V16C17.75 19.42 16.42 20.75 13 20.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M20.7983 18.04C20.3683 18.04 19.8383 17.9 19.2083 17.46L16.5683 15.61C16.2283 15.37 16.1483 14.9 16.3883 14.57C16.6283 14.23 17.0883 14.15 17.4283 14.39L20.0683 16.24C20.5083 16.55 20.8283 16.58 20.9583 16.51C21.0883 16.44 21.2483 16.17 21.2483 15.63V7C21.2483 6.59 21.5883 6.25 21.9983 6.25C22.4083 6.25 22.7483 6.59 22.7483 7V15.62C22.7483 17.05 22.0583 17.62 21.6483 17.83C21.4583 17.93 21.1683 18.04 20.7983 18.04Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M2.02094 22.9409C1.83094 22.9409 1.64094 22.8709 1.49094 22.7209C1.20094 22.4309 1.20094 21.9509 1.49094 21.6609L21.4909 1.66086C21.7809 1.37086 22.2609 1.37086 22.5509 1.66086C22.8409 1.95086 22.8409 2.43086 22.5509 2.72086L2.55094 22.7209C2.41094 22.8709 2.21094 22.9409 2.02094 22.9409Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default VideoSlash;
