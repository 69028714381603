import { Box, Text } from '@gluestack-ui/themed';
import theme from 'theme';
import { calcSizeForDevice } from 'utils/index';

type TitleCardProps = {
  title?: string;
  reference?: string;
};

const TitleCard: React.FC<TitleCardProps> = ({ title = '', reference = '' }) => {
  const isSeeMore = reference === 'Ver Mais';

  return (
    <Box
      width="$full"
      height="$full"
      alignItems="center"
      justifyContent="center"
      p={10}
      borderWidth={1}
      borderColor="#ffffff29"
      backgroundColor={isSeeMore ? '#ffffff29' : 'transparent'}
      borderRadius={3}>
      <Text
        color="white"
        textAlign="center"
        fontFamily={isSeeMore ? theme.fonts.inter.bold : theme.fonts.inter.regular}
        fontSize={isSeeMore ? calcSizeForDevice(24) : calcSizeForDevice(18)}>
        {title || reference}
      </Text>
    </Box>
  );
};

export default TitleCard;
