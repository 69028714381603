import * as React from 'react';
import { Path, Polygon, Svg } from 'react-native-svg';

const Number10: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 912 647.9" {...props}>
    <Polygon
      fill={innerColor || 'none'}
      points="141.8 628.4 141.8 159.1 139.9 159.1 3 244.8 3 103 151.8 10.1 307.5 10.1 307.5 628.4 141.8 628.4"
    />
    <Path
      fill={color || 'white'}
      d="M307,10.6v617.4h-164.7V158.6h-2.5l-.2.2L3.5,243.9V103.2L152,10.6h155M308,9.6h-156.3L2.5,102.7v143l137.6-86h1.2v469.3h166.7V9.6h0Z"
    />
    <Path
      fill={innerColor || 'none'}
      d="M636,638c-57,0-106.4-12.9-146.9-38.4-40.5-25.5-71.9-62.3-93.5-109.5-21.6-47.2-32.6-104.6-32.6-170.4s11-123.3,32.6-170.8c21.6-47.5,53.1-84.5,93.5-109.9C529.6,13.4,579,.5,636,.5s106.4,12.9,146.9,38.4c40.5,25.5,71.9,62.5,93.5,109.9,21.6,47.5,32.6,105,32.6,170.8s-11,123.3-32.6,170.6c-21.6,47.4-53.1,84.2-93.5,109.5-40.5,25.3-89.9,38.2-146.9,38.2ZM636,132.9c-22.5,0-42.1,7.6-58.3,22.6-16.2,15-28.8,36.6-37.3,64.2-8.6,27.6-12.9,61.2-12.9,99.9s4.3,71.9,12.9,99.5c8.6,27.7,21.1,49.1,37.3,63.8,16.2,14.7,35.9,22.2,58.3,22.2s42-7.5,58.1-22.2c16.1-14.7,28.6-36.2,37.1-63.8,8.6-27.6,12.9-61.1,12.9-99.5s-4.3-72.3-12.9-99.9c-8.6-27.7-21.1-49.3-37.1-64.2-16.1-15-35.6-22.6-58.1-22.6Z"
    />
    <Path
      fill={color || 'white'}
      d="M636,1c28.5,0,55.2,3.2,79.6,9.6,24.3,6.4,46.9,16,67.1,28.7,20.2,12.7,38.3,28.4,53.8,46.6,15.5,18.2,28.8,39.5,39.6,63.2,21.6,47.5,32.5,104.9,32.5,170.6s-10.9,123.1-32.5,170.4c-10.8,23.6-24.1,44.8-39.6,63-15.5,18.1-33.6,33.7-53.8,46.4-20.2,12.6-42.7,22.2-67.1,28.6-24.3,6.3-51.1,9.5-79.6,9.5s-55.2-3.2-79.6-9.6c-24.3-6.4-46.9-16-67.1-28.7-20.2-12.7-38.3-28.3-53.8-46.5-15.5-18.1-28.8-39.3-39.6-62.9-21.6-47.2-32.5-104.4-32.5-170.2s10.9-123.2,32.5-170.6c10.8-23.7,24.1-45,39.6-63.2,15.5-18.2,33.6-33.9,53.8-46.6,20.2-12.7,42.8-22.4,67.1-28.7,24.3-6.4,51.1-9.6,79.6-9.6M636,505.6c22.6,0,42.3-7.5,58.5-22.3,16.1-14.7,28.7-36.3,37.3-64,8.6-27.7,12.9-61.2,12.9-99.6s-4.4-72.4-12.9-100.1c-8.6-27.8-21.1-49.4-37.3-64.5-16.2-15.1-35.9-22.7-58.5-22.7s-42.3,7.6-58.7,22.7c-16.3,15-28.9,36.7-37.5,64.5-8.6,27.7-12.9,61.3-12.9,100.1s4.4,72,12.9,99.6c8.6,27.8,21.2,49.3,37.5,64,16.3,14.8,36.1,22.3,58.7,22.3M636,0c-57.4,0-106.4,12.8-147.2,38.5-40.7,25.6-72,62.4-93.7,110.2-21.8,47.8-32.6,104.8-32.6,171.1s10.9,123.1,32.6,170.6c21.8,47.5,53,84.1,93.7,109.7,40.7,25.6,89.8,38.5,147.2,38.5s106.4-12.7,147.2-38.2c40.7-25.5,72-62.1,93.7-109.7,21.8-47.7,32.6-104.6,32.6-170.8s-10.9-123.3-32.6-171.1c-21.8-47.8-53-84.5-93.7-110.2C742.4,12.8,693.3,0,636,0h0ZM636,504.6c-22.4,0-41.8-7.3-58-22-16.2-14.7-28.6-35.9-37.2-63.6-8.6-27.7-12.9-60.8-12.9-99.3s4.3-72.1,12.9-99.8c8.6-27.7,21-49.1,37.2-64,16.2-15,35.5-22.4,58-22.4s41.7,7.5,57.8,22.4c16.1,15,28.4,36.3,37,64,8.6,27.7,12.9,61,12.9,99.8s-4.3,71.6-12.9,99.3c-8.6,27.7-20.9,48.9-37,63.6-16.1,14.7-35.3,22-57.8,22h0Z"
    />
    <Path
      fill={color || 'white'}
      d="M320.5,638.5h-183.9V184.3L0,269.6V105.4L153.9,9.4h166.6v629.1ZM156.5,618.5h143.9V29.4h-140.8L20,116.5v117l122.4-76.6h14.1v461.5Z"
    />
    <Path
      fill={color || 'white'}
      d="M633,647.9c-58.1,0-108.5-13.2-150-39.4-41.6-26.1-73.8-63.9-95.9-112.2-21.9-47.8-33-105.7-33-172s11.1-124.3,33-172.4c22.1-48.6,54.4-86.5,96-112.7C524.5,13.2,574.9,0,633,0s108.5,13.2,150,39.3c41.6,26.1,73.8,64.1,96,112.7,21.9,48.1,33,106.1,33,172.4s-11.1,124.2-33,172.2c-22.1,48.5-54.4,86.2-96,112.2-41.5,26-92,39.1-150,39.1ZM633,20c-54.2,0-101.1,12.2-139.4,36.3-38.2,24-67.9,59-88.4,104-20.7,45.5-31.2,100.7-31.2,164.1s10.5,118.5,31.2,163.6c20.5,44.7,50.2,79.6,88.4,103.6,38.3,24.1,85.1,36.3,139.4,36.3s101.2-12.1,139.4-36.1c38.2-23.9,67.9-58.7,88.4-103.6,20.7-45.3,31.2-100.5,31.2-163.9s-10.5-118.6-31.2-164.1c-20.5-45-50.2-80-88.4-104-38.3-24.1-85.2-36.3-139.4-36.3ZM633,516.3c-24.5,0-46-8.2-63.7-24.3-17.2-15.6-30.5-38.1-39.4-67-8.7-28.1-13.1-61.9-13.1-100.7s4.4-73,13.1-101.1c8.9-28.8,22.2-51.5,39.4-67.3,17.8-16.4,39.2-24.7,63.8-24.7s46,8.3,63.6,24.8c17,15.9,30.2,38.5,39.1,67.3,8.7,28.1,13.1,62.1,13.1,101.1s-4.4,72.6-13.1,100.7c-8.9,28.8-22.1,51.4-39.2,67-17.6,16.1-39,24.3-63.6,24.3ZM633,151.2c-19.6,0-36.1,6.4-50.2,19.4-14.6,13.4-25.9,33.1-33.8,58.6-8.1,26.2-12.2,58.2-12.2,95.1s4.1,68.6,12.2,94.7c7.9,25.4,19.2,44.9,33.7,58.1,14.2,12.8,30.6,19.1,50.3,19.1s36.1-6.2,50.1-19c14.4-13.2,25.7-32.7,33.6-58.1,8.1-26.2,12.2-58,12.2-94.7s-4.1-69-12.2-95.2c-7.9-25.4-19.2-45.1-33.6-58.6-14-13-30.4-19.4-50-19.4Z"
    />
  </Svg>
);

export default Number10;
