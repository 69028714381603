import { UtilityProps } from '@gluestack-style/react/lib/typescript/types';
import { VStack, useBreakpointValue } from '@gluestack-ui/themed';
import { memo, useId } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Collapse, { CollapseProps } from 'components/Collapse';

type CollapseGroupProps = {
  items: CollapseProps[];
  gap?: UtilityProps<StyleProp<ViewStyle>>['gap'];
};

const CollapseGroup: React.FC<CollapseGroupProps> = ({ items, gap = '$2' }) => {
  const groupId = useId();
  const flexProp = useBreakpointValue({ xl: 1, lg: 1, base: undefined });

  return (
    <VStack flex={flexProp} gap={gap}>
      {items.map((item, index) => {
        const customKey = `${groupId}-${index}`;
        return <Collapse key={customKey} {...item} />;
      })}
    </VStack>
  );
};

export default memo(CollapseGroup);
