import { getAnalytics, getGoogleAnalyticsClientId, logEvent, setUserId } from 'firebase/analytics';

import { Driver } from './types';

export const useAnalyticsDriver = (): Driver => {
  const analytics = getAnalytics();
  const getAppInstanceIdProxy = () => getGoogleAnalyticsClientId(analytics);
  const logEventProxy: Driver['logEvent'] = async (n, p) => logEvent(analytics, n, p);
  const setUserGaId = async (id: string) => setUserId(analytics, id);

  return {
    getAppInstanceId: getAppInstanceIdProxy,
    logEvent: logEventProxy,
    setUserGaId,
  };
};
