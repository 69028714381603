import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowLeft: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M20.0004 12.2742C20.0004 12.6539 19.7182 12.9677 19.3521 13.0173L19.2504 13.0242L4.25037 13.0242C3.83615 13.0242 3.50037 12.6884 3.50037 12.2742C3.50037 11.8945 3.78252 11.5807 4.1486 11.531L4.25037 11.5242L19.2504 11.5242C19.6646 11.5242 20.0004 11.86 20.0004 12.2742Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.8291 17.767C11.1226 18.0592 11.1237 18.5341 10.8314 18.8276C10.5657 19.0945 10.1491 19.1196 9.85501 18.9024L9.77074 18.8299L3.72074 12.8059C3.4531 12.5394 3.42876 12.1213 3.64771 11.8272L3.7207 11.743L9.7707 5.71802C10.0642 5.42574 10.5391 5.42672 10.8314 5.72022C11.0971 5.98704 11.1204 6.40375 10.9019 6.69691L10.8292 6.78088L5.31313 12.2748L10.8291 17.767Z"
      fill={color || '#FFF'}
    />
  </Svg>
);
export default ArrowLeft;
