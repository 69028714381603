import SimpleKeyboard from './SimpleKeyboard';
import { KeyItemProps } from './helpers/keyboardItems';

type TvKeyboardProps = {
  variant?: string;
  textValue: string;
  setTextValue: (newText: string) => void;
};

const TvKeyboard: React.FC<TvKeyboardProps> = ({ variant, textValue, setTextValue }) => {
  const onHandleKeyPress = (keyItem: KeyItemProps) => {
    switch (keyItem.item) {
      case 'backspace':
        if (textValue.length > 0) {
          setTextValue(textValue.slice(0, -1));
        }
        break;
      case 'clear':
        setTextValue('');
        break;
      case 'space':
      default:
        if (keyItem.value !== null) {
          setTextValue(textValue + keyItem.value);
        }
        break;
    }
  };

  switch (variant) {
    case 'simple':
    default:
      return <SimpleKeyboard onHandleKeyPress={onHandleKeyPress} />;
  }
};

export default TvKeyboard;
