import { createSlice } from '@reduxjs/toolkit';
import { logoutAction } from 'store/actions';
import { AgeRatingKey } from 'utils/ageRating';

type ProfileState = {
  isKid: boolean;
  ageRating: AgeRatingKey;
};

const initialState: ProfileState = {
  isKid: false,
  ageRating: '18',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      state.isKid = payload.isKid;
    },
    toggleKidProfile: (state) => {
      state.isKid = !state.isKid;
    },
    setAgeRating: (state, { payload }) => {
      state.ageRating = payload.ageRating;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, () => initialState);
  },
});

export const { setProfile, toggleKidProfile, setAgeRating } = profileSlice.actions;
export default profileSlice.reducer;
