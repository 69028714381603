import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidPlay: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53157 18.7274C5.44878 18.7935 5.33132 18.7769 5.26701 18.692C5.23508 18.6479 5.22162 18.5928 5.22961 18.5405V5.2277C5.2109 5.12317 5.28046 5.02167 5.38196 5.0023C5.43532 4.99413 5.48892 5.0079 5.52883 5.0408L17.8704 11.7207C18.0362 11.8112 18.0362 11.957 17.8704 12.0478L5.53157 18.7274Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidPlay;
