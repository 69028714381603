import { createSlice } from '@reduxjs/toolkit';
import { backendApiSlice } from 'services/backend';
import { logoutAction } from 'store/actions';

const initialState: any[] = [];

const boomarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    clearStory: (state) => {
      state.splice(0, state.length);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction, () => initialState);

    builder.addMatcher(
      backendApiSlice.endpoints.createFavorite.matchFulfilled,
      (state, { payload, meta }) => {
        state.push({ program_id: meta.arg.originalArgs.programId, bookmarks_id: payload._id });
      }
    );

    builder.addMatcher(
      backendApiSlice.endpoints.deleteFavorite.matchFulfilled,
      (state, { meta }) => {
        const newStateArray = state.filter((data) => data.bookmarks_id !== meta.arg.originalArgs);
        state.splice(0, state.length, ...newStateArray);
      }
    );
  },
});

export const { clearStory } = boomarksSlice.actions;

export default boomarksSlice.reducer;
