import { Button } from '@gluestack-ui/themed';
import React from 'react';
import { PressableProps } from 'react-native';
import { calcSizeForTv } from 'utils/index';

import { ButtonProps } from '.';

import { getIconByName } from 'components/Icons';

type BackButtonProps = Omit<ButtonProps, 'gradientVariant' | 'variant'>;

const BackButton = React.forwardRef<PressableProps, BackButtonProps>(
  ({ backgroundColor = 'rgba(209, 215, 255, 0.20)', iconStyle, iconType, ...rest }, ref) => {
    const Icon = iconType ? getIconByName(iconType) : getIconByName('TurnBack');

    return (
      <Button
        ref={ref}
        width={calcSizeForTv(68)}
        height={calcSizeForTv(68)}
        borderRadius={calcSizeForTv(100)}
        padding={calcSizeForTv(10)}
        backgroundColor={backgroundColor}
        {...rest}>
        <Icon
          width={calcSizeForTv(50).toString()}
          height={calcSizeForTv(50).toString()}
          {...iconStyle}
        />
      </Button>
    );
  }
);

export default BackButton;
