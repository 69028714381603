import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const DeleteLeft: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M16.9983 21H10.2783C8.65828 21 7.10828 20.31 6.02828 19.12L2.49828 15.24C0.818281 13.4 0.818281 10.62 2.49828 8.77L6.02828 4.89C7.10828 3.69 8.65828 3 10.2783 3H16.9983C20.1683 3 22.7483 5.58 22.7483 8.75V15.25C22.7483 18.42 20.1683 21 16.9983 21ZM10.2783 4.5C9.08828 4.5 7.93828 5.01 7.13828 5.89L3.59828 9.78C2.44828 11.05 2.44828 12.96 3.59828 14.23L7.12828 18.11C7.92828 18.99 9.07828 19.5 10.2683 19.5H16.9983C19.3383 19.5 21.2483 17.59 21.2483 15.25V8.75C21.2483 6.41 19.3383 4.5 16.9983 4.5H10.2783Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M16 15.2209C15.81 15.2209 15.62 15.1509 15.47 15.0009L10.53 10.0509C10.24 9.76094 10.24 9.28094 10.53 8.99094C10.82 8.70094 11.3 8.70094 11.59 8.99094L16.53 13.9409C16.82 14.2309 16.82 14.7109 16.53 15.0009C16.38 15.1509 16.19 15.2209 16 15.2209Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.06 15.2207C10.87 15.2207 10.68 15.1507 10.53 15.0007C10.24 14.7107 10.24 14.2307 10.53 13.9407L15.47 9.0007C15.76 8.7107 16.24 8.7107 16.53 9.0007C16.82 9.2907 16.82 9.7707 16.53 10.0607L11.59 15.0007C11.44 15.1507 11.25 15.2207 11.06 15.2207Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default DeleteLeft;
