// TODO: web
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import useFirebaseAnalytics, { PlayerEventsEnum } from 'services/analytics';

const StreamContext = createContext<StreamContextProps>({} as StreamContextProps);

export const StreamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isConnected, setConnected] = useState(false);
  const [castState, setCastState] = useState<string>('false');
  const { logEventAction } = useFirebaseAnalytics();

  const loadMedia = async (request: any) => {};
  const closeMedia = async () => {};

  const castDialog = () => {
    logEventAction({
      component: 'Header',
      reference: PlayerEventsEnum.CAST,
    });
  };

  const showControls = () => {};

  const closeSession = () => {};

  return (
    <StreamContext.Provider
      value={{
        client: null,
        isConnected,
        status,
        streamPosition: null,
        device: null,
        castState,
        castDialog,
        showControls,
        closeSession,
        loadMedia,
        closeMedia,
      }}>
      {children}
    </StreamContext.Provider>
  );
};

export const useStream = () => useContext(StreamContext);

export default StreamContext;
