import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const FaceFrown: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.6414 9.49945C10.4514 9.49945 10.2614 9.42945 10.1114 9.27945C9.40141 8.56945 8.24141 8.56945 7.53141 9.27945C7.24141 9.56945 6.76141 9.56945 6.47141 9.27945C6.18141 8.98945 6.18141 8.50945 6.47141 8.21945C7.76141 6.92945 9.87141 6.92945 11.1714 8.21945C11.4614 8.50945 11.4614 8.98945 11.1714 9.27945C11.0214 9.42945 10.8314 9.49945 10.6414 9.49945Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M13.3608 9.49945C13.1708 9.49945 12.9808 9.42945 12.8308 9.27945C12.5408 8.98945 12.5408 8.50945 12.8308 8.21945C14.1208 6.92945 16.2308 6.92945 17.5308 8.21945C17.8208 8.50945 17.8208 8.98945 17.5308 9.27945C17.2408 9.56945 16.7608 9.56945 16.4708 9.27945C15.7608 8.56945 14.6008 8.56945 13.8908 9.27945C13.7508 9.42945 13.5608 9.49945 13.3608 9.49945Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M15.6 18.4508H8.4C7.49 18.4508 6.75 17.7108 6.75 16.8008C6.75 13.9108 9.1 11.5508 12 11.5508C14.9 11.5508 17.25 13.9108 17.25 16.8008C17.25 17.7108 16.51 18.4508 15.6 18.4508ZM12 13.0508C9.93 13.0508 8.25 14.7308 8.25 16.8008C8.25 16.8808 8.32 16.9508 8.4 16.9508H15.6C15.68 16.9508 15.75 16.8808 15.75 16.8008C15.75 14.7308 14.07 13.0508 12 13.0508Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default FaceFrown;
