import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackParamList } from 'navigation/AppNavigator';
import { useEffect } from 'react';

const useHandlerError = (Error: boolean) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const route = useRoute();

  useEffect(() => {
    if (Error) {
      navigation.navigate('NotFound', { path: route.path });
    }
  }, [Error]);

  return null;
};

export default useHandlerError;
