/* eslint-disable import/order */
import { Button, ButtonText, Text, View } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import useFirebaseAnalytics from 'services/analytics';
import { Platform } from 'utils/platform';

import FocusView from 'components/FocusView';
import SolidPlay from 'components/Icons/SolidPlay';
import { WebDiv } from 'components/WebDiv';

export const Cancel: React.FC<any> = ({ useTVFocus = false, mini = false, onPress, ...rest }) => {
  const { logEventActionName } = useFirebaseAnalytics();
  const [isFocused, setIsFocused] = React.useState(false);
  const baseOnFocus = () => (Platform.isAndroidTV && useTVFocus ? setIsFocused(true) : null);
  const baseOnBlur = () => (Platform.isAndroidTV && useTVFocus ? setIsFocused(false) : null);

  const onFocus = () => {
    baseOnFocus();
    rest.onBtnFocus?.();
  };

  const onBlur = () => {
    baseOnBlur();
    rest.onBtnBlur?.();
  };

  const onPressProxy = (...args: any) => {
    onPress(...args);
    logEventActionName({ component: 'player-autonext' }, 'an_cancelar');
  };

  return (
    <WebDiv className="rounded-6">
      <Button
        id="linearGradientButton"
        role="button"
        onFocus={onFocus}
        onBlur={onBlur}
        height={mini ? '$8' : '$10'}
        variant="outline"
        borderWidth={2}
        borderStyle="solid"
        borderColor={isFocused ? 'red' : '#2240FF'}
        borderRadius={styles.gradient.borderRadius}
        alignItems="center"
        justifyContent="center"
        backgroundColor="#00000088"
        onPress={onPressProxy}
        {...rest}>
        <Text fontSize={mini ? '$sm' : '$md'} color="$white">
          Cancelar
        </Text>
      </Button>
    </WebDiv>
  );
};

export const Next = React.forwardRef<any, any>(
  ({ useTVFocus = false, mini = false, onPress, ...rest }, ref) => {
    const { logEventActionName } = useFirebaseAnalytics();
    const [isFocused, setIsFocused] = React.useState(false);
    const baseOnFocus = () => (Platform.isAndroidTV && useTVFocus ? setIsFocused(true) : null);
    const baseOnBlur = () => (Platform.isAndroidTV && useTVFocus ? setIsFocused(false) : null);

    const onFocus = () => {
      baseOnFocus();
      rest.onBtnFocus?.();
    };

    const onBlur = () => {
      baseOnBlur();
      rest.onBtnBlur?.();
    };

    const onPressProxy = (...args: any) => {
      onPress(...args);
      logEventActionName({ component: 'player-autonext' }, 'an_play_proximo');
    };

    const animatedWidth = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
      // Animate the width from 0 to 100% over 10 seconds
      Animated.timing(animatedWidth, {
        toValue: 1, // Representing 100% as 1
        duration: 10000, // 10 seconds
        useNativeDriver: false,
      }).start();
    }, [animatedWidth]);

    const interpolatedWidth = animatedWidth.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '100%'],
    });

    return (
      <WebDiv className="rounded-6">
        <FocusView>
          <View
            overflow="hidden"
            backgroundColor="#FFFFFF66"
            borderRadius={styles.gradient.borderRadius}>
            <Animated.View style={[styles.gradient, { width: interpolatedWidth }]}>
              <LinearGradient
                end={{ x: 0, y: 0 }}
                start={{ x: 0, y: 1 }}
                colors={['#00AD5C', '#6DFFBC']}
                style={[styles.gradient, { width: '100%' }]}
              />
            </Animated.View>

            <Button
              id="linearGradientButton"
              ref={ref}
              height={mini ? '$8' : '$10'}
              role="button"
              onFocus={onFocus}
              onBlur={onBlur}
              variant="outline"
              borderWidth={2}
              borderColor={isFocused ? 'red' : 'transparent'}
              borderRadius={styles.gradient.borderRadius}
              alignItems="center"
              justifyContent="center"
              backgroundColor="transparent"
              hasTVPreferredFocus
              gap="$1"
              onPress={onPressProxy}
              {...rest}>
              <SolidPlay color="black" />
              <ButtonText fontSize={mini ? '$sm' : '$md'} fontWeight="bold" color="#070d31">
                Próximo Episódio
              </ButtonText>
            </Button>
          </View>
        </FocusView>
      </WebDiv>
    );
  }
);

const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    overflow: 'hidden',
  },
});
