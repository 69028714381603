import * as React from 'react';
import { Path, Polygon, Svg } from 'react-native-svg';

const Number07: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 472.1 629.1" {...props}>
    <Polygon
      fill={innerColor || 'none'}
      points="46.5 628.6 292.7 142.8 292.7 140.9 3.1 140.9 3.1 .5 469 .5 469 136.7 221 628.6 46.5 628.6"
    />
    <Path
      fill={color || 'white'}
      d="M468.5,1v135.6l-247.9,491.5H47.3l245.8-484.9v-.2c.1,0,.1-.2.1-.2v-2.3H3.6V1h465M469.5,0H2.6v141.4h289.6v1.3L45.6,629.1h175.6l248.3-492.3V0h0Z"
    />
    <Path
      fill={color || 'white'}
      d="M227.9,629.1H35.4l239.4-472.1H0V0h472.1v144.8l-244.2,484.3ZM68,609.1h147.6l236.5-469V20H20v116.9h280.4v13.6l-1.1,2.1-231.4,456.4Z"
    />
  </Svg>
);

export default Number07;
