import { View } from '@gluestack-ui/themed';
import { useState } from 'react';
import { FlatList } from 'react-native';
import { calcSizeForTv } from 'utils/index';

import EnvButton from './components/EnvButton';
import { DEV_ENVIRONMENTS, PROD_ENVIRONMENTS, QA_ENVIRONMENTS } from './utils/constants';

import Button from 'components/Button';
import Grid from 'components/Grid';

const EasterEggEnvs: React.FC = () => {
  const [envIndexSelected, setEnvIndexSelected] = useState<number>(2);
  const envTypes = [
    { name: 'PROD', children: PROD_ENVIRONMENTS },
    { name: 'QA', children: QA_ENVIRONMENTS },
    { name: 'DEV', children: DEV_ENVIRONMENTS },
  ];

  return (
    <View my={calcSizeForTv(50)}>
      <View borderBottomColor="#7A8CFF" borderBottomWidth={1} mb={calcSizeForTv(10)}>
        <FlatList
          data={envTypes}
          keyExtractor={(item) => item.name}
          renderItem={({ item, index }) => (
            <Button
              useTVFocus
              variant="solid"
              backgroundColor="transparent"
              style={{
                marginRight: calcSizeForTv(20),
                padding: calcSizeForTv(25),
                borderWidth: calcSizeForTv(3),
                borderColor: 'transparent',
                borderBottomColor: index === envIndexSelected ? 'white' : '#7A8CFF',
              }}
              textTransform="uppercase"
              textStyle={{
                fontSize: calcSizeForTv(20),
                lineHeight: calcSizeForTv(24),
                color: index === envIndexSelected ? 'white' : '#7A8CFF',
                textShadowColor: 'rgba(7, 13, 49, 0.7)',
                textShadowOffset: { width: 1, height: 1 },
              }}
              onPress={() => setEnvIndexSelected(index)}>
              {item.name}
            </Button>
          )}
          contentContainerStyle={{ paddingHorizontal: calcSizeForTv(20) }}
          showsHorizontalScrollIndicator={false}
          horizontal
        />
      </View>
      <Grid
        data={envTypes[envIndexSelected].children}
        renderItem={(item) => (
          <EnvButton
            name={item.title}
            url={item.url}
            description={item.description}
            borderColor={item.borderColor}
          />
        )}
        numColumns={6}
        gap={calcSizeForTv(30)}
      />
    </View>
  );
};

export default EasterEggEnvs;
