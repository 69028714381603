import { styled } from '@gluestack-style/react';
import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import theme from 'theme';

type CustomSeparatorProps = Omit<LinearGradientProps, 'start' | 'locations' | 'colors'>;

const BaseSeparator: React.FC<CustomSeparatorProps> = (props) => (
  <LinearGradient
    {...props}
    locations={[0.1, 0.2, 1]}
    start={{ x: 0.1, y: 0.1 }}
    colors={['transparent', theme.colors.primary[600], 'transparent']}
  />
);

const Separator = styled(BaseSeparator, { h: 1, opacity: 0.3 }, { componentName: 'Separator' });
export default Separator;
