import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const HouseWindow: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M17.6018 22.5608H6.4018C4.5818 22.5608 2.9218 21.1608 2.6218 19.3608L1.2918 11.4008C1.0818 10.1608 1.6818 8.57084 2.6718 7.78084L9.60181 2.23079C10.9418 1.15079 13.0518 1.1608 14.4018 2.2408L21.3318 7.78084C22.3118 8.57084 22.9118 10.1608 22.7118 11.4008L21.3818 19.3608C21.0818 21.1308 19.3918 22.5608 17.6018 22.5608ZM11.9918 2.94082C11.4618 2.94082 10.9318 3.10079 10.5418 3.41079L3.6118 8.96084C3.0418 9.42084 2.65181 10.4408 2.77181 11.1608L4.10181 19.1208C4.28181 20.1708 5.3318 21.0608 6.4018 21.0608H17.6018C18.6718 21.0608 19.7218 20.1708 19.9018 19.1108L21.2318 11.1508C21.3518 10.4308 20.9518 9.40083 20.3918 8.95083L13.4618 3.41079C13.0618 3.10079 12.5318 2.94082 11.9918 2.94082Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12 16.25C10.21 16.25 8.75 14.79 8.75 13C8.75 11.21 10.21 9.75 12 9.75C13.79 9.75 15.25 11.21 15.25 13C15.25 14.79 13.79 16.25 12 16.25ZM12 11.25C11.04 11.25 10.25 12.04 10.25 13C10.25 13.96 11.04 14.75 12 14.75C12.96 14.75 13.75 13.96 13.75 13C13.75 12.04 12.96 11.25 12 11.25Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default HouseWindow;
