import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const AudioLibrary: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M17 22.75H7C3.35 22.75 1.25 20.65 1.25 17V13C1.25 9.4 3.17003 8.06 4.78003 7.56C5.43003 7.35 6.18 7.25 7 7.25H17C17.82 7.25 18.57 7.36001 19.23 7.57001C20.83 8.06001 22.75 9.41 22.75 13V17C22.75 20.65 20.65 22.75 17 22.75ZM7 8.75C6.34 8.75 5.73998 8.83 5.22998 9C3.55998 9.51 2.75 10.82 2.75 13V17C2.75 19.86 4.14 21.25 7 21.25H17C19.86 21.25 21.25 19.86 21.25 17V13C21.25 10.82 20.44 9.51 18.78 9C18.26 8.83 17.66 8.75 17 8.75H7Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M19 9.03003C18.92 9.03003 18.85 9.01999 18.77 8.98999C18.26 8.82999 17.66 8.73999 17 8.73999H7C6.34 8.73999 5.73998 8.81999 5.22998 8.98999C4.99998 9.05999 4.75 9.02 4.56 8.88C4.37 8.74001 4.25 8.51002 4.25 8.27002V7C4.25 5.48 5.48 4.25 7 4.25H17C18.52 4.25 19.75 5.48 19.75 7V8.28003C19.75 8.52003 19.64 8.74001 19.44 8.89001C19.31 8.98001 19.16 9.03003 19 9.03003ZM7 5.75C6.31 5.75 5.75 6.31 5.75 7V7.34003C6.14 7.28003 6.56 7.25 7 7.25H17C17.44 7.25 17.86 7.28003 18.25 7.34003V7C18.25 6.31 17.69 5.75 17 5.75H7Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M16 5.75H8C7.59 5.75 7.25 5.41 7.25 5V3.51001C7.25 2.26001 8.26001 1.25 9.51001 1.25H14.49C15.74 1.25 16.75 2.26001 16.75 3.51001V5C16.75 5.41 16.41 5.75 16 5.75ZM8.75 4.25H15.25V3.51001C15.25 3.09001 14.91 2.75 14.49 2.75H9.51001C9.09001 2.75 8.75 3.09001 8.75 3.51001V4.25Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M9.07001 20.2103C7.93001 20.2103 7 19.2803 7 18.1403C7 17.0003 7.93001 16.0703 9.07001 16.0703C10.21 16.0703 11.14 17.0003 11.14 18.1403C11.14 19.2803 10.22 20.2103 9.07001 20.2103ZM9.07001 17.5603C8.75001 17.5603 8.5 17.8203 8.5 18.1303C8.5 18.4403 8.76001 18.7003 9.07001 18.7003C9.38001 18.7003 9.64001 18.4403 9.64001 18.1303C9.64001 17.8203 9.39001 17.5603 9.07001 17.5603Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.3984 18.8808C9.98844 18.8808 9.64844 18.5408 9.64844 18.1308V12.8007C9.64844 11.9407 10.1884 11.2408 11.0184 11.0108L13.6684 10.2907C14.5184 10.0607 15.0584 10.2808 15.3584 10.5108C15.6584 10.7408 16.0084 11.1908 16.0084 12.0708V17.2407C16.0084 17.6507 15.6684 17.9907 15.2584 17.9907C14.8484 17.9907 14.5084 17.6507 14.5084 17.2407V12.0708C14.5084 11.8208 14.4584 11.7208 14.4384 11.7008C14.4184 11.6908 14.2984 11.6707 14.0584 11.7307L11.4084 12.4508C11.1784 12.5108 11.1484 12.6607 11.1484 12.7907V18.1207C11.1484 18.5507 10.8084 18.8808 10.3984 18.8808Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M13.9294 19.3197C12.7894 19.3197 11.8594 18.3897 11.8594 17.2497C11.8594 16.1097 12.7894 15.1797 13.9294 15.1797C15.0694 15.1797 15.9994 16.1097 15.9994 17.2497C15.9994 18.3897 15.0694 19.3197 13.9294 19.3197ZM13.9294 16.6797C13.6094 16.6797 13.3594 16.9397 13.3594 17.2497C13.3594 17.5597 13.6194 17.8197 13.9294 17.8197C14.2394 17.8197 14.4994 17.5597 14.4994 17.2497C14.4994 16.9397 14.2394 16.6797 13.9294 16.6797Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.3991 15.0999C10.0691 15.0999 9.76906 14.8799 9.67906 14.5499C9.56906 14.1499 9.80909 13.7399 10.2091 13.6299L15.0591 12.3099C15.4591 12.1999 15.869 12.4399 15.979 12.8399C16.089 13.2399 15.8491 13.6499 15.4491 13.7599L10.599 15.0799C10.529 15.0899 10.4591 15.0999 10.3991 15.0999Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default AudioLibrary;
