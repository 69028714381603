import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Ghost: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15.3322 22.75C14.9122 22.75 14.4822 22.6 14.1522 22.3L12.1522 20.47C12.0722 20.4 11.9122 20.4 11.8222 20.47L9.83221 22.3C9.17221 22.91 8.14218 22.9 7.48218 22.3L5.48218 20.47C5.40218 20.4 5.24222 20.4 5.15222 20.47L4.16217 21.38C3.25217 22.21 2.53219 22.08 2.18219 21.92C1.83219 21.76 1.24219 21.32 1.24219 20.07V12.18C1.24219 6.14999 6.06219 1.25 11.9922 1.25C17.9222 1.25 22.7422 6.14999 22.7422 12.18V20.07C22.7422 21.32 22.1522 21.77 21.8022 21.92C21.4522 22.07 20.7322 22.21 19.8222 21.38L18.8222 20.47C18.7422 20.4 18.5822 20.4 18.5022 20.47L16.5022 22.3C16.1822 22.6 15.7622 22.75 15.3322 22.75ZM12.0022 18.91C12.4222 18.91 12.8522 19.06 13.1722 19.36L15.1722 21.19C15.2522 21.26 15.4122 21.26 15.4922 21.19L17.4922 19.36C18.1522 18.76 19.1822 18.76 19.8422 19.36L20.8422 20.27C21.0122 20.42 21.1222 20.49 21.1922 20.51C21.2222 20.44 21.2522 20.3 21.2522 20.06V12.17C21.2522 6.97001 17.1022 2.73999 12.0022 2.73999C6.9022 2.73999 2.7522 6.97001 2.7522 12.17V20.06C2.7522 20.3 2.79219 20.44 2.81219 20.51C2.88219 20.48 3.00217 20.42 3.16217 20.27L4.16217 19.36C4.82217 18.76 5.85221 18.76 6.51221 19.36L8.51221 21.19C8.59221 21.26 8.75221 21.26 8.83221 21.19L10.8322 19.36C11.1522 19.06 11.5822 18.91 12.0022 18.91Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12.0003 16.0703C10.4303 16.0703 8.86025 15.5803 7.55025 14.6003C7.22025 14.3503 7.15029 13.8803 7.40029 13.5503C7.65029 13.2203 8.12027 13.1503 8.45027 13.4003C10.5403 14.9703 13.4602 14.9703 15.5502 13.4003C15.8802 13.1503 16.3502 13.2203 16.6002 13.5503C16.8502 13.8803 16.7803 14.3503 16.4503 14.6003C15.1403 15.5803 13.5703 16.0703 12.0003 16.0703Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12 11.75C10.48 11.75 9.25 10.52 9.25 9C9.25 7.48 10.48 6.25 12 6.25C13.52 6.25 14.75 7.48 14.75 9C14.75 10.52 13.52 11.75 12 11.75ZM12 7.75C11.31 7.75 10.75 8.31 10.75 9C10.75 9.69 11.31 10.25 12 10.25C12.69 10.25 13.25 9.69 13.25 9C13.25 8.31 12.69 7.75 12 7.75Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Ghost;
