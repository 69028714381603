import { Box, Text } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import userRequired from 'hoc/userRequired';
import { useCallback } from 'react';
import useFirebaseAnalytics from 'services/analytics';
import theme from 'theme';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import FocusView from 'components/FocusView';
import { SectionFavorite } from 'components/Section/SectionFavorite';

const FavoritesScreen: React.FC = () => {
  const { logEventPageCleanView } = useFirebaseAnalytics();

  useFocusEffect(
    useCallback(() => {
      logEventPageCleanView({
        page_title: 'Minha Lista',
        template: 'Listagem',
        page_location: 'minha-lista',
      });
      return () => {};
    }, [])
  );

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <Box
        flex={1}
        pb={calcSizeForDevice(32, 0)}
        px={calcSizeForDevice(32)}
        mt={calcSizeForDevice(20, 120)}>
        {Platform.isTV && (
          <Text
            color="white"
            fontSize={calcSizeForTv(48)}
            lineHeight={calcSizeForTv(60)}
            fontFamily={theme.fonts.inter.bold}
            mb={calcSizeForTv(60)}>
            Minha Lista
          </Text>
        )}
        <SectionFavorite {...(Platform.isTV && { topContainerHeight: calcSizeForTv(240) })} />
      </Box>
    </FocusView>
  );
};

export default userRequired(FavoritesScreen);
