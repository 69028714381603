import flowplayer, { Flowplayer } from '@flowplayer/player';
import '@flowplayer/player/flowplayer.css';
import AudioPlugin from '@flowplayer/player/plugins/audio';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import { pt } from '@flowplayer/translations';
import Constants from 'expo-constants';
import { useLayoutEffect, useState } from 'react';
import { Platform } from 'utils/platform';

declare global {
  interface Window {
    playerAudio?: Flowplayer.Player[];
  }
}

// idioma
flowplayer.i18n.pt = pt;

// plugins
flowplayer(HLSPlugin, AudioPlugin);

type Track = {
  url: string;
  title: string;
  artist: string;
  type: string;
  contentType: string;
  userAgent?: string;
  artwork?: string;
};

export const Event = {
  PlaybackState: {},
  PlaybackError: {},
  RemotePlay: {},
  RemotePause: {},
};

export const State = {
  Buffering: {},
  Playing: {},
};

export const useProgress = () => {
  return {
    duration: 0,
    position: 0,
  };
};

export const useTrackPlayerEvents = (events: any, callback: (event: any) => void) => {};

export const usePlaybackState = () => {};

enum Errors {
  HttpLiveLevelLoadError = 'http.live.levelLoadError',
  HttpmanifestLoadError = 'http.manifestLoadError',
}

const translateError = (errorType: string): string => {
  switch (errorType) {
    case Errors.HttpLiveLevelLoadError:
      return 'Ocorreu um erro de conexão.';
    case Errors.HttpmanifestLoadError:
      return 'Ocorreu um erro no carregamento.';
    default:
      return 'Ocorreu um erro inesperado.';
  }
};

const setMainDivWidth = (width: string) => {
  const element = document.getElementsByClassName('fp-ui');
  const length = element.length;
  const elementMainDiv = element[length > 0 ? length - 1 : length] as HTMLElement;
  elementMainDiv.style.width = width;
};
const toggleIconDivVisibility = (visible: boolean) => {
  const element = document.getElementsByClassName('fp-middle');
  const length = element.length;
  const elementsIconDiv = element[length > 0 ? length - 1 : length] as HTMLElement;
  elementsIconDiv.style.visibility = visible ? 'visible' : 'hidden';
};

export function useSetupPlayer() {
  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    const div = document.createElement('div');
    div.id = `player-audio-${(Math.random() * 1000).toFixed(0)}`;
    div.style.maxHeight = '58px';
    if (Platform.isTV) div.style.display = 'none';

    const container = document.querySelectorAll('#radio-container');
    container[container.length - 1]?.appendChild(div);

    window.playerAudio = window.playerAudio || [];
    window.playerAudio.at(-1)?.pause();
    window.playerAudio.push(
      flowplayer(`#${div.id}`, {
        ui: 8192,
        lang: 'pt',
        opts: { controls: true },
        token: Constants.expoConfig?.extra?.flowPlayerToken,
      })
    );

    setIsReady(true);

    return () => {
      window.playerAudio?.pop()?.destroy();
      window.playerAudio?.at(-1)?.play();
    };
  }, []);

  window.playerAudio?.at(-1)?.on('error', (err: any) => {
    setTimeout(() => {
      const errorDivsLength = document.getElementsByClassName('error-message').length;
      document.getElementsByClassName('error-message')[
        errorDivsLength > 0 ? errorDivsLength - 1 : errorDivsLength
      ].innerHTML = translateError(err?.data?.code);

      setMainDivWidth('100%');
      toggleIconDivVisibility(false);
    }, 10);
  });

  window.playerAudio?.at(-1)?.addEventListener('playing', function () {
    setMainDivWidth('100%');
  });

  window.playerAudio?.at(-1)?.addEventListener('pause', function () {
    setMainDivWidth('100%');
  });

  return isReady;
}

export default new (class {
  tracks: Track[] = [];

  get ref(): Flowplayer.Player | undefined {
    return window.playerAudio?.[window.playerAudio.length - 1];
  }

  getProgress() {
    return {
      duration: this.ref?.duration,
      position: this.ref?.currentTime,
    };
  }

  reset() {
    toggleIconDivVisibility(true);
    setMainDivWidth('auto');

    this.tracks.splice(0, this.tracks.length);
    this.pause();
  }

  reload() {
    const prevTracks = [...this.tracks];
    this.tracks.splice(0, this.tracks.length);
    this.add(prevTracks);
    const track = prevTracks[0];
    this.ref?.setSrc([
      {
        type: 'audio/mp4',
        src: track.url,
      },
    ]);
    this.ref?.play();
    this.ref?.pause();
  }

  add(tracks: any[]) {
    this.tracks.splice(0, this.tracks.length, ...tracks);
  }

  show(show: boolean) {
    const div = this.ref?.parentElement;
    if (div) {
      div.style.visibility = show ? 'visible' : 'hidden';
    }
  }

  play() {
    const track = this.tracks[0];
    if (this.ref?.src !== track.url) {
      this.ref?.setSrc([
        {
          type: 'audio/mp4',
          src: track.url,
        },
      ]);
    }
    this.ref?.play();
    this.show(true);
  }

  pause() {
    this.ref?.pause();
    this.show(false);

    if (!Platform.isTV) {
      window.playerAudio?.[window.playerAudio.length - 1].on('error', (err: any) => {
        if (err?.data.code.search('410') != -1) {
          this.reload();
        }
      });
    }
  }

  seekTo(n: number) {
    this.ref?.fastSeek(n);
  }
})();
