import React from 'react';

import { SkeletonContainer } from './styles';

type SkeletonProps = {
  variant: string;
};

const Skeleton: React.FC<SkeletonProps> = ({ variant }) => <SkeletonContainer type={variant} />;
export default Skeleton;
