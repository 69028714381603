import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Tiktok: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M17.3121 8.72991C16.6289 8.72991 15.9736 8.45848 15.4905 7.97534C15.0073 7.49221 14.7359 6.83693 14.7359 6.15367C14.7359 5.92591 14.6454 5.70749 14.4844 5.54644C14.3233 5.3854 14.1049 5.29492 13.8772 5.29492C13.6494 5.29492 13.431 5.3854 13.2699 5.54644C13.1089 5.70749 13.0184 5.92591 13.0184 6.15367V14.7411C13.0184 15.2507 12.8673 15.7487 12.5842 16.1724C12.3012 16.5961 11.8988 16.9263 11.4281 17.1213C10.9573 17.3163 10.4393 17.3673 9.93957 17.2679C9.43983 17.1685 8.98079 16.9231 8.62049 16.5628C8.2602 16.2025 8.01484 15.7435 7.91543 15.2437C7.81603 14.744 7.86705 14.226 8.06204 13.7552C8.25703 13.2845 8.58723 12.8821 9.01089 12.5991C9.43455 12.316 9.93264 12.1649 10.4422 12.1649C10.6699 12.1649 10.8883 12.0744 11.0494 11.9134C11.2104 11.7523 11.3009 11.5339 11.3009 11.3061C11.3009 11.0784 11.2104 10.86 11.0494 10.6989C10.8883 10.5379 10.6699 10.4474 10.4422 10.4474C9.59295 10.4474 8.7628 10.6992 8.0567 11.171C7.3506 11.6428 6.80026 12.3134 6.47528 13.098C6.1503 13.8826 6.06527 14.7459 6.23094 15.5788C6.39662 16.4117 6.80556 17.1768 7.40604 17.7773C8.00653 18.3777 8.7716 18.7867 9.6045 18.9524C10.4374 19.118 11.3007 19.033 12.0853 18.708C12.8699 18.383 13.5405 17.8327 14.0123 17.1266C14.4841 16.4205 14.7359 15.5903 14.7359 14.7411V9.58865C15.4768 10.1506 16.3823 10.4524 17.3121 10.4474C17.5399 10.4474 17.7583 10.3569 17.9194 10.1959C18.0804 10.0348 18.1709 9.81641 18.1709 9.58865C18.1709 9.3609 18.0804 9.14247 17.9194 8.98143C17.7583 8.82038 17.5399 8.72991 17.3121 8.72991Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M16.6317 23.3274H6.83698C5.28943 23.3257 3.80572 22.6856 2.71143 21.5475C1.61714 20.4094 1.00165 18.8664 1 17.2569V7.07046C1.00165 5.461 1.61714 3.91795 2.71143 2.77989C3.80572 1.64183 5.28943 1.00171 6.83698 1H16.6317C18.1792 1.00171 19.6629 1.64183 20.7572 2.77989C21.8515 3.91795 22.467 5.461 22.4687 7.07046V17.2569C22.467 18.8664 21.8515 20.4094 20.7572 21.5475C19.6629 22.6856 18.1792 23.3257 16.6317 23.3274ZM6.83698 2.61793C5.70202 2.61964 4.61402 3.08929 3.81148 3.92394C3.00894 4.75858 2.55735 5.8901 2.5557 7.07046V17.2569C2.55735 18.4373 3.00894 19.5688 3.81148 20.4035C4.61402 21.2381 5.70202 21.7078 6.83698 21.7095H16.6317C17.7666 21.7078 18.8546 21.2381 19.6572 20.4035C20.4597 19.5688 20.9113 18.4373 20.913 17.2569V7.07046C20.9113 5.8901 20.4597 4.75858 19.6572 3.92394C18.8546 3.08929 17.7666 2.61964 16.6317 2.61793H6.83698Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Tiktok;
