import { Pressable, View } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { backendApiSlice } from 'services/backend';
import { logoutAction } from 'store/actions';
import { RootState } from 'store/index';
import { toggleKidProfile } from 'store/slices/profileSlice';
import { calcSizeForTv } from 'utils/index';

import { SIDEBAR_MENU_TV } from './menu-tv';

import Button from 'components/Button';
import EasterEggMonitor from 'components/EasterEgg/utils/EasterEggMonitor';
import ModalAgeRating from 'components/Header/ModalAgeRating';
import { WebDiv } from 'components/WebDiv';

export type MenuTvItem = {
  name: string;
  icon: IconsName | string | undefined;
  label: string;
  link?: string;
  menu?: string;
  header?: boolean;
  footer?: boolean;
  submenu?: MenuTvItem[];
  onKidsMode?: boolean;
  easterEgg?: boolean;
};

const SidebarMenu: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const [isAgeRatingOpen, setIsAgeRatingOpen] = React.useState(false);
  const [isNavigationFocused, setIsNavigationFocused] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('home');
  const [submenuOpenedIndex, setSubmenuOpenedIndex] = useState<number | null>(null);
  const [isEasterEggOpened, setIsEasterEggOpened] = useState(false);
  const profile = useSelector((state: RootState) => state.profile);
  const user = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const containerRef = React.useRef(null);
  const isFavoriteScreen = window.location.pathname === '/favoritos';

  const activeMenuIndex = React.useMemo(
    () => SIDEBAR_MENU_TV.findIndex((item) => item.name === activeMenuItem) + 1,
    [activeMenuItem]
  );

  const submenuClass = React.useMemo(
    () => (isSubmenuOpen ? 'navigation-priority' : 'navigation-ignore'),
    [isSubmenuOpen]
  );

  const onHandleItemPress = ({ item, index }: { item: MenuTvItem; index: number }) => {
    if (isOpen) {
      if (!item.submenu) {
        setIsOpen(false);
        setActiveMenuItem(item?.menu || item?.name);
        setSubmenuOpenedIndex(null);
        switch (item.name) {
          case 'age-rating':
            setIsAgeRatingOpen(true);
            break;
          case 'kids-mode':
            dispatch(toggleKidProfile());
            if (profile.isKid) {
              dispatch(logoutAction());
              dispatch(backendApiSlice.util.resetApiState());
            }
            linkTo(`/?toggleKidProfile=${new Date().getTime()}`);
            break;
          case 'loggout':
            dispatch(logoutAction());
            dispatch(backendApiSlice.util.resetApiState());
            linkTo('/');
            break;
          default:
            linkTo(item?.link || '');
        }
      } else {
        setSubmenuOpenedIndex(isSubmenuOpen ? null : index + 1);
        setIsSubmenuOpen(!isSubmenuOpen);
      }
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsSubmenuOpen(false);
    }
  }, [isOpen]);

  const onKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      if (!isOpen && isNavigationFocused) {
        setIsOpen(true);
      }
      // left
      if (e.keyCode === 37 && isSubmenuOpen) {
        setIsSubmenuOpen(false);
      }
    },
    [isSubmenuOpen, isOpen, isNavigationFocused]
  );

  // append keypress listener
  useEffect(() => {
    window.addEventListener('keydown', onKeyPress, false);
    return () => window.removeEventListener('keydown', onKeyPress);
  }, [onKeyPress]);

  // append listener
  useEffect(() => {
    const observer = new MutationObserver((mutations: any) => {
      let isFocused = false;

      for (const mutation of mutations) {
        if (mutation.target.classList.contains('focused')) {
          isFocused = true;
        }
      }

      setIsNavigationFocused(isFocused);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    return () => observer.disconnect();
  }, [containerRef]);

  React.useEffect(() => {
    setIsOpen(isNavigationFocused);
  }, [isNavigationFocused]);

  return (
    <View w={calcSizeForTv(108)} zIndex={9} h={height}>
      <WebDiv
        ref={containerRef}
        style={{
          width: isOpen ? calcSizeForTv(108) : calcSizeForTv(720),
          height,
          position: 'fixed',
          display: 'flex',
          flexDirection: 'row',
        }}
        className={`navigation-container first-focus-index[${
          submenuOpenedIndex ?? activeMenuIndex
        }]`}>
        {isOpen && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width,
              height,
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
            onPress={() => setIsOpen(false)}
          />
        )}
        <Pressable
          id="sidebar-menu"
          h={height}
          display="flex"
          justifyContent="center"
          bg="#000738"
          w={isOpen ? calcSizeForTv(320) : calcSizeForTv(108)}
          p={isOpen ? calcSizeForTv(40) : calcSizeForTv(20)}
          onPress={() => setIsOpen(true)}>
          {SIDEBAR_MENU_TV.map(
            (item, index) =>
              (!profile.isKid || item.onKidsMode) && (
                <React.Fragment key={`side-menu_${item?.label}_${index}`}>
                  <Button
                    role="link"
                    variant="sidebar-menu"
                    iconType={item?.icon as IconsName}
                    iconStyle={{ width: calcSizeForTv(30), height: calcSizeForTv(30) }}
                    style={{ paddingVertical: 0, flex: 1 }}
                    isActivated={activeMenuItem === item?.name}
                    isOpen={isOpen}
                    onButtonEnter={() => onHandleItemPress({ item, index })}
                    setActiveMenuItem={setActiveMenuItem}
                    setIsOpen={setIsOpen}
                    setIsSubmenuOpen={setIsSubmenuOpen}>
                    {index === 0 ? (user?.isAuthenticated ? user.name : 'Entrar') : item.label}
                  </Button>
                  {item.submenu && isSubmenuOpen && (
                    <WebDiv
                      className={`navigation-container ignore-left-key ignore-right-key ${submenuClass}`}>
                      <LinearGradient
                        id="sidebar-menu__submenu"
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                        locations={[0.6, 0.95]}
                        colors={['#001075', 'transparent']}
                        style={[
                          {
                            width: isSubmenuOpen ? calcSizeForTv(400) : 0,
                            opacity: isSubmenuOpen ? 1 : 0,
                            display: isSubmenuOpen ? 'flex' : 'none',
                            position: 'absolute',
                            top: 0,
                            left: calcSizeForTv(310),
                            height: '100%',
                            justifyContent: 'center',
                            padding: calcSizeForTv(20),
                          },
                        ]}>
                        {item.submenu.map(
                          (submenuItem, submenuIndex) =>
                            (!profile.isKid || submenuItem.onKidsMode) &&
                            (!submenuItem.easterEgg || isEasterEggOpened) && (
                              <Button
                                role="link"
                                variant="sidebar-menu"
                                iconType={submenuItem?.icon as IconsName}
                                style={{
                                  paddingVertical: 0,
                                  flex: 1,
                                  marginRight: calcSizeForTv(40),
                                }}
                                isOpen={isOpen}
                                onButtonEnter={() =>
                                  onHandleItemPress({ item: submenuItem, index: submenuIndex })
                                }
                                setIsOpen={setIsOpen}
                                setIsSubmenuOpen={setIsSubmenuOpen}
                                key={`sidemenu_${submenuItem?.label}_${submenuIndex}`}>
                                {submenuItem?.name === 'kids-mode' && profile.isKid
                                  ? 'Sair do Modo Infantil'
                                  : submenuItem?.label}
                              </Button>
                            )
                        )}
                      </LinearGradient>
                    </WebDiv>
                  )}
                </React.Fragment>
              )
          )}
        </Pressable>
        {isFavoriteScreen && (
          <EasterEggMonitor handleEasterEgg={() => setIsEasterEggOpened(true)} />
        )}

        <ModalAgeRating isOpen={isAgeRatingOpen} toggleModal={() => setIsAgeRatingOpen(false)} />
      </WebDiv>
    </View>
  );
};

export default SidebarMenu;
