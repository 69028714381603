import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Bracket: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M4.79999 9.60001V14.8H19.2V9.60001" stroke={color || '#FFF'} strokeWidth="2" />
  </Svg>
);

export default Bracket;
