import { Text } from '@gluestack-ui/themed';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';
import { isMobile, isMobileOrSmallScreen } from 'utils/deviceInfo';

import DropDownTrigger from 'components/DropDown/DropDownTrigger';
import { DropDownItem, DropDownProps } from 'components/DropDown/interface';
import { Menu } from 'components/DropDown/style';

const useDropDownControls = (props: DropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropDownItem>();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (props.defaultSelectedItem !== undefined) {
      setSelectedItem(props.items[props.defaultSelectedItem]);
    }
  }, [props.defaultSelectedItem]);

  const handleChangeItem = useCallback(
    (item: DropDownItem) => {
      setIsOpen(false);
      setSelectedItem(item);
      props.onChangeSelectedItem?.(item);
    },
    [setIsOpen, setSelectedItem]
  );

  const triggerRenderer = useCallback(
    (triggerProps: Record<string, unknown>) => {
      return (
        <DropDownTrigger
          triggerProps={triggerProps}
          disabled={!props.items.length}
          label={selectedItem?.label || props.emptyLabel || ''}
        />
      );
    },
    [selectedItem, props.emptyLabel]
  );

  const items = useMemo(() => {
    const notSelectedItems = props.items.filter((item) => item.id !== selectedItem?.id);

    return notSelectedItems.map((item) => {
      return (
        <Menu.Item
          mb={8}
          mt={8}
          paddingHorizontal={isMobileOrSmallScreen(width) ? 60 : 80}
          key={item.id}
          minWidth="$full"
          textValue={item.label}
          justifyContent={'center'}
          backgroundColor="transparent">
          <TouchableOpacity  {...(isMobile
            ? { onPress: () => handleChangeItem(item) }
            : { onPressIn: () => handleChangeItem(item) })}>
            <Text color="white">{item.label}</Text>
          </TouchableOpacity>
        </Menu.Item>
      );
    });
  }, [props.items, selectedItem]);

  return {
    isOpen,
    setIsOpen,
    items,
    triggerRenderer,
  };
};

export default useDropDownControls;
