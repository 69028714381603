import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidBackwardStep: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4844 16.2015V7.19646V7.19467C18.4844 6.76757 18.2559 6.37292 17.885 6.15937C17.5141 5.94583 17.0571 5.94583 16.6862 6.15938L8.88829 10.6652C8.518 10.8789 8.29 11.2732 8.29 11.6999C8.29 12.1265 8.518 12.5208 8.88829 12.7346L16.6862 17.2344C17.0568 17.4477 17.5132 17.4479 17.8839 17.235C18.2547 17.022 18.4835 16.6281 18.4844 16.2015ZM6.79688 17.3766V6.01951L4.99873 6.01951V17.3766H6.79688Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidBackwardStep;
