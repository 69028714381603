import { Link, useLinkProps } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Pressable, View } from 'react-native';
import useFirebaseAnalytics from 'services/analytics';
import { Platform } from 'utils/platform';

export type WithLinkProps = {
  link?: string;
  component?: string;
  reference?: string;
  target?: number;
  requestTVFocus?: boolean;
  label?: string;
};

const withLink =
  <P extends object>(
    Component: React.ComponentType<P>,
    _componentName: string
  ): React.FC<P & WithLinkProps> =>
  ({ link, requestTVFocus, ...props }: WithLinkProps) => {
    const { logEventActionName } = useFirebaseAnalytics();
    const [isFocused, setIsFocused] = React.useState(false);
    const androidTVFocusableButtonRef = React.useRef<View>(null);

    const logEvent = logEventActionName.bind(
      null,
      {
        component: props.component, // section + tipo  == section mid, section boxed, section.... ou uma string
        reference: props.reference, // novidades
        target: props.target,
        label: props.label,
      },
      'ver_mais'
    );

    if (!link) {
      return <Component className="touchable" {...(props as P)} />;
    }

    link = link
      .replace(/^(expo|bandplay)[^:]*:/, '')
      .replace(/^https?:\/\/(www\.)?bandplay\.com/, '')
      .replace(/^\/{2,}/, '/');

    if (/^https?:/.test(link)) {
      return (
        <Pressable role="link" onPress={() => WebBrowser.openBrowserAsync(link as string)}>
          <Component {...(props as P)} />
        </Pressable>
      );
    } else if (link.charAt(0) != '/') {
      link = '/' + link;
    }

    if (Platform.OS === 'web') {
      return (
        <Link role="link" style={{ flex: 1 }} to={link} onPress={logEvent}>
          <Component {...(props as P)} />
        </Link>
      );
    }

    const { onPress } = useLinkProps({ to: link });

    const onPressProxy = () => {
      onPress();
      logEvent();
    };

    const onFocusProxy = () => {
      setIsFocused(true);
    };

    return (
      <Pressable
        role="link"
        onFocus={onFocusProxy}
        onPress={onPressProxy}
        ref={androidTVFocusableButtonRef}
        onBlur={() => setIsFocused(false)}
        hasTVPreferredFocus={requestTVFocus}
        style={{ borderWidth: 2, borderColor: isFocused ? 'red' : 'transparent' }}>
        <Component {...(props as P)} />
      </Pressable>
    );
  };

export default withLink;
