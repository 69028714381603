import { Box, Pressable } from '@gluestack-ui/themed';
import React from 'react';
import { Animated, useWindowDimensions } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

type PaginatorProps = {
  data: any[];
  scrollX: any;
  scrollToItem: any;
};

const Paginator: React.FC<PaginatorProps> = (props) => {
  const { width } = useWindowDimensions();
  const { data, scrollX, scrollToItem } = props;

  return (
    <Box flexDirection="row" height={calcSizeForDevice(64)}>
      {data.map((_, i) => {
        const inputRange = [(i - 1) * width, i * width, (i + 1) * width];

        const dotColor = scrollX.interpolate({
          inputRange,
          outputRange: ['#ffffff', '#6386FF', '#ffffff'],
          extrapolate: 'clamp',
        });

        const dot = (
          <Animated.View
            key={i}
            focusable={false}
            style={{
              backgroundColor: dotColor,
              height: calcSizeForDevice(10),
              borderRadius: calcSizeForDevice(5),
              width: calcSizeForDevice(10),
              marginRight: calcSizeForDevice(5),
            }}
          />
        );

        if (Platform.isTV) {
          return dot;
        }

        return (
          <Pressable key={i} onPress={() => scrollToItem(i)}>
            <Animated.View
              style={{
                backgroundColor: dotColor,
                height: calcSizeForDevice(10),
                borderRadius: calcSizeForDevice(5),
                width: calcSizeForDevice(10),
                marginRight: calcSizeForDevice(5),
              }}
            />
          </Pressable>
        );
      })}
    </Box>
  );
};

export default Paginator;
