import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const VolumeSlash: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M7 17.7504H5C2.58 17.7504 1.25 16.4204 1.25 14.0004V10.0004C1.25 7.58036 2.58 6.25036 5 6.25036H6.43C6.66 6.25036 6.89 6.18036 7.09 6.06036L10.01 4.23036C11.47 3.32036 12.89 3.15036 14.01 3.77036C15.13 4.39036 15.74 5.68036 15.74 7.41036V8.37036C15.74 8.78036 15.4 9.12036 14.99 9.12036C14.58 9.12036 14.24 8.78036 14.24 8.37036V7.41036C14.24 6.27036 13.89 5.42036 13.28 5.09036C12.67 4.75036 11.77 4.90036 10.8 5.51036L7.88 7.33036C7.45 7.61036 6.94 7.75036 6.43 7.75036H5C3.42 7.75036 2.75 8.42036 2.75 10.0004V14.0004C2.75 15.5804 3.42 16.2504 5 16.2504H7C7.41 16.2504 7.75 16.5904 7.75 17.0004C7.75 17.4104 7.41 17.7504 7 17.7504Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12.5476 20.5892C11.7576 20.5892 10.8876 20.3092 10.0176 19.7592C9.66765 19.5392 9.55765 19.0792 9.77765 18.7292C9.99765 18.3792 10.4576 18.2692 10.8076 18.4892C11.7776 19.0892 12.6776 19.2492 13.2876 18.9092C13.8976 18.5692 14.2476 17.7192 14.2476 16.5892V12.9492C14.2476 12.5392 14.5876 12.1992 14.9976 12.1992C15.4076 12.1992 15.7476 12.5392 15.7476 12.9492V16.5892C15.7476 18.3092 15.1276 19.6092 14.0176 20.2292C13.5676 20.4692 13.0676 20.5892 12.5476 20.5892Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M18.0003 16.7503C17.8403 16.7503 17.6903 16.7003 17.5503 16.6003C17.2203 16.3503 17.1503 15.8803 17.4003 15.5503C18.6603 13.8703 18.9303 11.6403 18.1203 9.71032C17.9603 9.33032 18.1403 8.89032 18.5203 8.73032C18.9003 8.57032 19.3403 8.75032 19.5003 9.13032C20.5203 11.5503 20.1703 14.3603 18.6003 16.4603C18.4503 16.6503 18.2303 16.7503 18.0003 16.7503Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M19.8284 19.2492C19.6684 19.2492 19.5184 19.1992 19.3784 19.0992C19.0484 18.8492 18.9784 18.3792 19.2284 18.0492C21.3684 15.1992 21.8384 11.3792 20.4584 8.08922C20.2984 7.70922 20.4784 7.26922 20.8584 7.10922C21.2484 6.94922 21.6784 7.12922 21.8384 7.50922C23.4284 11.2892 22.8884 15.6692 20.4284 18.9492C20.2884 19.1492 20.0584 19.2492 19.8284 19.2492Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M2.00141 22.7495C1.81141 22.7495 1.62141 22.6795 1.47141 22.5295C1.18141 22.2395 1.18141 21.7595 1.47141 21.4695L21.4714 1.46945C21.7614 1.17945 22.2414 1.17945 22.5314 1.46945C22.8214 1.75945 22.8214 2.23945 22.5314 2.52945L2.53141 22.5295C2.38141 22.6795 2.19141 22.7495 2.00141 22.7495Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default VolumeSlash;
