import TvHelpScreenLayout from 'components/Help/TvHelpScreenLayout.ctv';
import TvQrContentLayout from 'components/Help/TvQRContentLayout.ctv';

const TvMyProfile: React.FC = () => {
  return (
    <TvHelpScreenLayout>
      <TvQrContentLayout
        title="Meu perfil"
        url="login.band.com.br"
        qrCodeValue="https://login.band.com.br/"
      />
    </TvHelpScreenLayout>
  );
};

export default TvMyProfile;
