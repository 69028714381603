import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const CirclePlay: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11.9688 22.75C6.04875 22.75 1.21875 17.93 1.21875 12C1.21875 6.07 6.04875 1.25 11.9688 1.25C17.8888 1.25 22.7188 6.07 22.7188 12C22.7188 17.93 17.8988 22.75 11.9688 22.75ZM11.9688 2.75C6.86875 2.75 2.71875 6.9 2.71875 12C2.71875 17.1 6.86875 21.25 11.9688 21.25C17.0688 21.25 21.2188 17.1 21.2188 12C21.2188 6.9 17.0688 2.75 11.9688 2.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.5583 16.9895C10.1183 16.9895 9.69828 16.8795 9.32828 16.6695C8.46828 16.1695 7.98828 15.1895 7.98828 13.9095V10.5595C7.98828 9.27951 8.45828 8.2995 9.31828 7.7995C10.1783 7.2995 11.2683 7.37951 12.3783 8.01951L15.2783 9.6895C16.3883 10.3295 16.9983 11.2295 16.9983 12.2295C16.9983 13.2195 16.3883 14.1295 15.2783 14.7695L12.3783 16.4395C11.7583 16.8095 11.1283 16.9895 10.5583 16.9895ZM10.5583 8.9695C10.3783 8.9695 10.2083 9.0095 10.0783 9.0895C9.69828 9.3095 9.48828 9.83951 9.48828 10.5595V13.9095C9.48828 14.6195 9.69828 15.1595 10.0783 15.3695C10.4483 15.5895 11.0183 15.4995 11.6383 15.1495L14.5383 13.4795C15.1583 13.1195 15.5083 12.6695 15.5083 12.2395C15.5083 11.8095 15.1483 11.3595 14.5383 10.9995L11.6383 9.3295C11.2383 9.0895 10.8683 8.9695 10.5583 8.9695Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default CirclePlay;
