import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Radio: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M17 22.75H7C3.56 22.75 1.25 20.44 1.25 17V10C1.25 6.56 3.56 4.25 7 4.25H17C20.44 4.25 22.75 6.56 22.75 10V17C22.75 20.44 20.44 22.75 17 22.75ZM7 5.75C4.42 5.75 2.75 7.42 2.75 10V17C2.75 19.58 4.42 21.25 7 21.25H17C19.58 21.25 21.25 19.58 21.25 17V10C21.25 7.42 19.58 5.75 17 5.75H7Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5V2C6.25 1.59 6.59 1.25 7 1.25C7.41 1.25 7.75 1.59 7.75 2V5C7.75 5.41 7.41 5.75 7 5.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M8.5 16.75C6.71 16.75 5.25 15.29 5.25 13.5C5.25 11.71 6.71 10.25 8.5 10.25C10.29 10.25 11.75 11.71 11.75 13.5C11.75 15.29 10.29 16.75 8.5 16.75ZM8.5 11.75C7.54 11.75 6.75 12.54 6.75 13.5C6.75 14.46 7.54 15.25 8.5 15.25C9.46 15.25 10.25 14.46 10.25 13.5C10.25 12.54 9.46 11.75 8.5 11.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M18.5 11.75H14.5C14.09 11.75 13.75 11.41 13.75 11C13.75 10.59 14.09 10.25 14.5 10.25H18.5C18.91 10.25 19.25 10.59 19.25 11C19.25 11.41 18.91 11.75 18.5 11.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M15 15.75H14.5C14.09 15.75 13.75 15.41 13.75 15C13.75 14.59 14.09 14.25 14.5 14.25H15C15.41 14.25 15.75 14.59 15.75 15C15.75 15.41 15.41 15.75 15 15.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M18.5 15.75H18C17.59 15.75 17.25 15.41 17.25 15C17.25 14.59 17.59 14.25 18 14.25H18.5C18.91 14.25 19.25 14.59 19.25 15C19.25 15.41 18.91 15.75 18.5 15.75Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Radio;
