import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ChevronDown: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M17.1445 9.875L12.0017 15.125L6.85882 9.875"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ChevronDown;
