import Constants from 'expo-constants';
import { Platform as RNPlatform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

const isFireTV = () => {
  const brand = DeviceInfo.getBrand();
  const model = DeviceInfo.getModel();

  return brand === 'Amazon' && model.includes('AFT');
};

type Platform = RNPlatform & {
  isAndroidTV: boolean;
  isWebTV: boolean;
  isFireTV: boolean;
  isMobileWeb: (data?: 'ios' | 'android') => boolean;
};

const Platform: Platform = {
  ...RNPlatform,
  isAndroidTV: RNPlatform.isTV && RNPlatform.OS == 'android',
  isWebTV: false,
  isFireTV: isFireTV(),
  isMobileWeb: (data) => {
    if (RNPlatform.OS !== 'web') {
      return false;
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const iosWeb = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const androidWeb = /android/i.test(userAgent);

    switch (data) {
      case 'ios':
        return iosWeb;
      case 'android':
        return androidWeb;
      default:
        return iosWeb || androidWeb;
    }
  },
};

if (RNPlatform.OS == 'web') {
  const detectors: { name: string; re: RegExp }[] = [
    { name: 'Samsung Tizen', re: /Tizen\s*(\d[\d.]+)/ },
    { name: 'LG WebOS', re: /Web[O0]S\s*(\d[\d.]+)?/ },
  ];

  const sources: (string | undefined)[] = [
    Constants.expoConfig?.extra?.defaultUserAgent,
    navigator?.userAgent,
  ];

  for (const data of sources) {
    if (!data) {
      continue;
    }

    for (const { name, re } of detectors) {
      const matched = re.exec(data);
      if (matched) {
        Platform.isTV = true;
        Platform.isWebTV = true;
        Platform.Version = `${name} ${matched[1] || ''}`;
        break;
      }
    }
  }
}

export { Platform };
