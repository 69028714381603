import { View } from '@gluestack-ui/themed';
import { ImageSource } from 'expo-image';
import React, { PropsWithChildren } from 'react';
import { ImageBackground, ImageProps } from 'react-native';
import getImageDynamicCrop, { ImageDynamicCrop } from 'utils/image';

type WebImageProps = PropsWithChildren &
  Omit<ImageProps, 'source'> &
  Omit<ImageDynamicCrop, 'uri'> & { source: ImageSource };

const FastImage: React.FC<WebImageProps> = ({ children, style, ...props }) => {
  const { source, alt = 'Imagem', ...otherProps } = props;
  const hasSource = !!props.source.uri;

  const croppedSource = getImageDynamicCrop({
    size: props.size,
    aspect: props.aspect,
    uri: props.source.uri,
  });

  return (
    <View style={style}>
      {hasSource && (
        <ImageBackground
          alt={alt}
          {...otherProps}
          style={{ flex: 1 }}
          source={{ uri: croppedSource }}
        />
      )}

      {children}
    </View>
  );
};

export default FastImage;
