export enum PipelineEnvironment {
  CMS1 = 'CMS1',
  CMS2 = 'CMS2',
  PROD = 'PROD',
}

export const environmentUrl = () => {
  const path = window.location?.href ?? 'https://bandplay.com/';
  const parts = path.split('/');
  const position = parts.slice(0, 3).join('/').length;
  const baseUrl = path.substr(0, position);

  return baseUrl;
};
