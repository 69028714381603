import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { isMobile } from 'utils/deviceInfo';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice, hourFormatterBySeconds } from 'utils/index';
import { Platform } from 'utils/platform';

import InfoDescription from './DescriptionText';
import { useProgram } from './context';

type ProgramContentProps = {
  episode?: CmsApiItem<CmsApiMediaList>;
  podcast?: any;
  selectedIndex?: any;
};

const ProgramContent: React.FC<ProgramContentProps> = ({ episode, podcast, selectedIndex }) => {
  const program = useProgram();
  const { width } = useWindowDimensions();
  const isNotMovie = program.type !== ProgramType.MOVIE;
  const isMobileOrSmallScreen = (isMobile || width < 767) && !Platform.isAndroidTV;
  const hasSummary = Boolean(program.summary);
  const programType = program.type as ProgramType;
  const isPodcastOrRadio = [ProgramType.RADIO, ProgramType.PODCAST].includes(programType);
  const isNotPodCastOrRadioAndIsSmall = !isPodcastOrRadio && isMobileOrSmallScreen;
  const showDescription = hasSummary && (isPodcastOrRadio || isNotPodCastOrRadioAndIsSmall);

  return (
    <Box mt={calcSizeForDevice(18)}>
      {program !== undefined && episode !== undefined && !isPodcastOrRadio ? (
        <InfoDescription
          title={`${episode.title} - ${hourFormatterBySeconds(episode.duration)}`}
          text={episode?.summary}
        />
      ) : (
        <>
          {showDescription && (
            <InfoDescription
              title={isNotMovie ? 'Descrição' : 'Sinopse'}
              text={
                program.type !== ProgramType.PODCAST
                  ? program?.summary
                  : podcast?.[selectedIndex]?.title ?? program?.summary
              }
            />
          )}
        </>
      )}
      {episode === undefined && (
        <Box mb={calcSizeForDevice(15)}>
          {program?.director && (
            <InfoDescription variant="horizontal" title="Direção:" text={program?.director} />
          )}
          {program?.cast && (
            <InfoDescription variant="horizontal" title="Elenco:" text={program?.cast} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProgramContent;
