import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowRotateLeft: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9521 5.36486V3.53566C11.9521 3.07666 11.6045 2.80409 11.2569 3.16988L7.43304 5.73064C7.17081 5.83831 7.03781 6.15394 7.14362 6.42991C7.19477 6.56988 7.30031 6.68123 7.43304 6.73478L11.1683 9.38876C11.6045 9.75454 11.9521 9.47858 11.9521 9.02298V7.19406C14.8318 7.19406 17.166 9.65056 17.166 12.6811C17.166 15.7116 14.8318 18.1684 11.9521 18.1684C9.07238 18.1684 6.73788 15.7116 6.73788 12.6811H5C5 16.7228 8.11123 19.9973 11.9521 19.9973C15.7929 19.9973 18.9042 16.7228 18.9042 12.6811C18.9042 8.63935 15.7929 5.36486 11.9521 5.36486Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ArrowRotateLeft;
