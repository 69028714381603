import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidPause: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C5.44772 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H8.875C9.42728 20 9.875 19.5523 9.875 19V5C9.875 4.44772 9.42728 4 8.875 4H6ZM14.125 4C13.5727 4 13.125 4.44772 13.125 5V19C13.125 19.5523 13.5727 20 14.125 20H17C17.5523 20 18 19.5523 18 19V5C18 4.44772 17.5523 4 17 4H14.125Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidPause;
