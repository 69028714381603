/* eslint-disable import/order */
import flowplayer, { Flowplayer as FlowplayerType } from '@flowplayer/player';
import '@flowplayer/player/flowplayer.css';
import AdsPlugin from '@flowplayer/player/plugins/ads';
import AirPlayPlugin from '@flowplayer/player/plugins/airplay';
import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import MessagePlugin from '@flowplayer/player/plugins/message';
import QSelPlugin from '@flowplayer/player/plugins/qsel';
import SubtitlesPlugin from '@flowplayer/player/plugins/subtitles';
import Flowplayer, { useFlowplayer } from '@flowplayer/react-flowplayer';
import { pt } from '@flowplayer/translations';
import { View } from '@gluestack-ui/themed';
import Constants from 'expo-constants';
import userRequired from 'hoc/userRequired';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { renderToString } from 'react-dom/server';
import useFirebaseAnalytics, {
  ComponentsNameTypesEnum,
  PlayerEventsEnum,
} from 'services/analytics';
import { ProgramType } from 'utils/enum';
import { Platform } from 'utils/platform';

import { useAutoNext } from '../AutoNext';
import { usePlayer } from '../context';
import ASelPlugin from './UI/Flowplayer/ASelPlugin';
import { VideoInfoView } from './UI/Info';

import { useProgram } from 'components/Program/context';

// idioma
flowplayer.i18n.pt = pt;

// plugins
if (Platform.isTV) {
  flowplayer(
    HLSPlugin,
    AirPlayPlugin,
    SubtitlesPlugin,
    QSelPlugin,
    AdsPlugin,
    MessagePlugin,
    ASelPlugin
  );
} else {
  flowplayer(
    HLSPlugin,
    AirPlayPlugin,
    ChromecastPlugin,
    SubtitlesPlugin,
    QSelPlugin,
    AdsPlugin,
    MessagePlugin,
    ASelPlugin
  );
}

const languages: Record<string, string> = {
  dub: 'Dublado',
  origin: 'Original',
  en: 'Inglês',
  pt: 'Português',
  fr: 'Francês',
  es: 'Espanhol',
};

const VideoPlayer = forwardRef<CustomPlayerRef, CustomPlayerProps>((props, ref) => {
  const {
    isLive,
    startTime,
    mediaUrl,
    media,
    trackId,
    adTagUrl,
    setTrack,
    setupTextTracks,
    reloadMedia,
    loadAttempts,
    error,
    play,
    pause,
    setError,
    isVisible,
    setIsVisible,
    isMuted,
    mute,
    isPlaying,
    setFullScreen,
    isFullScreen,
  } = usePlayer();

  const program = useProgram();
  const playerRef = useRef<HTMLDivElement>(null);
  const playerApi = useFlowplayer(playerRef);
  const [showingError, setShowingError] = useState(false);
  const progressRef = useRef<PlayerMediaProgress>({ currentTime: 0 });
  const quarterMarks = useRef([
    { mark: 0.25, name: 'view_25', value: 0, reached: false },
    { mark: 0.5, name: 'view_50', value: 0, reached: false },
    { mark: 0.75, name: 'view_75', value: 0, reached: false },
  ]);
  const { logEventActionName } = useFirebaseAnalytics();
  const geoErrorCodes = ['http.403', 'http.live.403'];
  const [currentTime, setCurrentTime] = React.useState(0);
  const handleFullScreen = (value: boolean) => setFullScreen(value);

  const { showNextTrack, autoNextCard } = useAutoNext({
    currentTime,
    duration: media?.duration || 0,
    forceMobileView: !isFullScreen,
  });

  const isRadio = program?.type === ProgramType.RADIO;

  const updateDuration = useCallback(
    (duration: number) => {
      if (progressRef.current.duration !== duration) {
        progressRef.current.duration = duration;
        quarterMarks.current[0].value = duration * 0.25;
        quarterMarks.current[1].value = duration * 0.5;
        quarterMarks.current[2].value = duration * 0.75;
      }
    },
    [progressRef?.current?.duration]
  );

  const handleLogEvents = (currentProgress: number) => {
    const marks = quarterMarks.current;

    marks.forEach((mark, index) => {
      const nextMark = marks[index + 1];
      const isValid =
        currentProgress >= mark.value &&
        (nextMark ? currentProgress < nextMark.value : true) &&
        !mark.reached;

      if (isValid) {
        logEventActionName({ component: 'Player', reference: 'click-text' }, mark.name);
        mark.reached = true;
      }
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      seek(time: number) {
        playerApi?.fastSeek(time);
      },
      progress(): PlayerMediaProgress {
        return progressRef.current;
      },
      getNativePlayerRef() {
        return playerApi;
      },
    }),
    [playerApi, progressRef]
  );

  useEffect(() => {
    if (!playerApi || !mediaUrl) {
      return;
    }

    const playerStartTime = progressRef.current.currentTime || startTime || 0;
    const playerDuration = media?.duration || progressRef?.current?.duration || 0;
    const timeRemaining = playerDuration - playerStartTime;
    const watchedEntireMedia = playerStartTime >= 0.96 * playerDuration;
    const normalizedStartTime = watchedEntireMedia || timeRemaining <= 30 ? 0 : playerStartTime;

    playerApi.setSrc(mediaUrl);
    if (!isLive) playerApi.setOpts({ start_time: normalizedStartTime });
    playerApi.play();
    playerApi.toggleFullScreen(isFullScreen);
  }, [playerApi, mediaUrl, startTime]);

  // re-tentativa de exibição de uma mídia com problema
  useEffect(() => {
    if (!playerApi || !error) return;

    const timer = setTimeout(() => {
      reloadMedia();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [playerApi, error, loadAttempts]);

  // controlador de exibição de erros
  // não é possível esconder/remover uma mensagem em exibição
  useEffect(() => {
    if (!playerApi || !error || showingError) return;

    if (
      Platform.isTV &&
      error.code === 'ERR_NETWORK' &&
      Math.floor(playerApi.buffered.end(0)) > Math.floor(playerApi.currentTime)
    )
      return;

    const timeout = 3000;

    playerApi.setState(flowplayer.states.ERRORED, true);

    playerApi.emit(playerApi.message?.events.SHOW_MESSAGE!, {
      message: error.message,
      timeout,
    });

    setTimeout(() => {
      playerApi.setState(flowplayer.states.ERRORED, false);
      setShowingError(false);
    }, timeout);

    setShowingError(true);
  }, [playerApi, error, showingError]);

  useEffect(() => {
    logEventActionName(
      {
        component: ComponentsNameTypesEnum.PLAYER,
        reference: PlayerEventsEnum.PLAY,
      },
      'view_inicial'
    );
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      const clickListItemWithText = (text: string) => {
        const parentDiv = document.querySelector('.fp-qsel');

        if (parentDiv) {
          const list = parentDiv.querySelector('ol');

          if (list) {
            const listItems = list.getElementsByTagName('li');

            for (let i = 0; i < listItems.length; i++) {
              if (listItems[i].textContent === text) {
                listItems[i].click();
                break;
              }
            }
          }
        }
      };

      setTimeout(() => {
        clickListItemWithText('477p');
      }, 2000);
    }
  }, [isVisible]);

  useEffect(() => {
    mute(!isRadio && !!isLive && !Platform.isTV);
  }, [isLive]);

  React.useEffect(() => {
    const bootstrap = () => {
      const currentTimeValue = playerApi?.currentTime || 0;
      progressRef.current.currentTime = currentTimeValue;
      setCurrentTime(currentTimeValue);

      if (!isLive) {
        updateDuration(media?.duration || progressRef?.current?.duration || 0);
        handleLogEvents(currentTimeValue);
      }
    };

    if (!isPlaying || !playerApi) return;
    const timer = setInterval(bootstrap, 900);
    return () => clearInterval(timer);
  }, [playerApi, isPlaying]);

  // inicialização do player
  useEffect(() => {
    if (!playerApi || !media || !trackId) {
      return;
    }

    playerApi.setOpts({
      muted: false,
      live: isLive,
      autoplay: isLive,
      ima: { ads: [{ time: 0, adTag: adTagUrl }] },
      asel: {
        header:
          !Platform.isTV && !showNextTrack
            ? renderToString(<VideoInfoView {...media} />)
            : undefined,
        tracks: media?.videoTracks?.map((item) => ({
          id: item.trackId,
          title: languages[item.language],
          selected: item.trackId === trackId,
        })),
      },
    });

    const handleAudioChange = (event: FlowplayerType.FPEvent<unknown>) => {
      const i = media?.videoTracks.findIndex((x) => x.trackId == event.detail);
      if (i != undefined) {
        setTrack(i);
      }
    };

    const handleError = (event: FlowplayerType.FPEvent<unknown>) => {
      event.preventDefault();
      const hasGeoError = geoErrorCodes.includes((event?.detail as any)?.code);
      setError({
        code: 'error',
        message:
          !Platform.isTV || (Platform.isTV && hasGeoError)
            ? `Este vídeo não está disponível para a sua região\n ou não está sendo transmitido no momento.`
            : '',
      });
    };

    const handlePlay = () => play();
    const handlePause = () => pause();

    const handleEnded = () => {
      const flowplayerDiv = document.querySelectorAll('.fp-fullscreen-exit')[0] as HTMLHtmlElement;

      if (flowplayerDiv) {
        flowplayerDiv.click();
      }

      if (isVisible) {
        setIsVisible(false);
      }
    };

    setupTextTracks([]);

    playerApi.on('asel:id', handleAudioChange);
    playerApi.on(flowplayer.events.FULLSCREEN_ENTER, () => handleFullScreen(true));
    playerApi.on(flowplayer.events.FULLSCREEN_EXIT, () => handleFullScreen(false));
    playerApi.on(flowplayer.events.ERROR, handleError);
    playerApi.on(flowplayer.events.PLAY, handlePlay);
    playerApi.on(flowplayer.events.PAUSE, handlePause);
    playerApi.on(flowplayer.events.ENDED, handleEnded);
    playerApi.on(flowplayer.events.ENDED, handleEnded);

    return () => {
      playerApi.off('asel:id', handleAudioChange);
      playerApi.off(flowplayer.events.ERROR, handleError);
      playerApi.off(flowplayer.events.PLAY, handlePlay);
      playerApi.off(flowplayer.events.PAUSE, handlePause);
      playerApi.off(flowplayer.events.ENDED, handleEnded);
    };
  }, [playerApi, media, trackId, , isMuted, play, pause, setupTextTracks, showNextTrack]);

  const flowplayerUI = playerApi?.parentElement?.querySelector('flowplayer-ui')!;

  useEffect(() => {
    const fullScreenButton = document.querySelector(
      isFullScreen ? '.fp-fullscreen-exit' : '.fp-fullscreen'
    ) as HTMLDivElement;

    if (fullScreenButton) {
      if (showNextTrack) {
        fullScreenButton.style.visibility = 'hidden'; // Esconde o botão
      } else {
        fullScreenButton.style.visibility = 'visible'; // Mostra o botão
      }
    }
  }, [showNextTrack, isFullScreen]);

  return (
    <>
      <Flowplayer
        ref={playerRef}
        src={undefined}
        token={Constants.expoConfig?.extra?.flowPlayerToken}
        opts={{
          lang: 'pt',
          controls: !showNextTrack,
          hls: { liveSyncDurationCount: 5, liveMaxLatencyDurationCount: 20 },
        }}
      />
      {flowplayerUI && showNextTrack && !Platform.isTV
        ? createPortal(
            <View pr={isFullScreen ? undefined : '$4'} pb={isFullScreen ? undefined : '$4'}>
              {autoNextCard}
            </View>,
            flowplayerUI
          )
        : null}
    </>
  );
});

VideoPlayer.displayName = 'VideoPlayer';

export default userRequired(VideoPlayer);
