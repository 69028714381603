import * as React from 'react';
import { Path, Polygon, Svg } from 'react-native-svg';

const Number01: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 619.4" {...props}>
    <Polygon
      fill={innerColor || 'none'}
      points="149.1 609.4 149.1 140.1 134.9 140.1 10.2 218.1 10.2 98.7 152.3 10 295.7 10 295.7 609.4 149.1 609.4"
    />
    <Path
      fill={color || 'none'}
      d="M285.7,20v579.4h-126.7V130.1h-27l-4.9,3L20.2,200v-95.8L155.2,20h130.6M305.7,0h-156.3L.2,93.1v143l137.6-86h1.2v469.3h166.7V0h0Z"
    />
  </Svg>
);

export default Number01;
