/* eslint-disable import/order */
import {
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ScrollView,
  View,
} from '@gluestack-ui/themed';
import { ReactNode } from 'react';
import { ImageBackground, ImageSourcePropType, StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import BG from 'assets/Splash/BgInside.png';

import FocusView from 'components/FocusView';

export type ModalProps = Partial<React.ComponentProps<typeof Modal>> & {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  modalHeader?: ReactNode;
  modalBody: ReactNode;
  modalFooter?: ReactNode;
  headerCloseButton?: boolean;
  backGroundImage?: ImageSourcePropType;
};

export function BaseModal({
  modalHeader,
  modalBody,
  modalFooter,
  backGroundImage,
  headerCloseButton = false,
  ...rest
}: ModalProps) {
  const theme = useTheme();

  const body = (
    <>
      {headerCloseButton && <Modal.CloseButton />}
      <ImageBackground source={backGroundImage ?? BG} style={styles.imageBg}>
        <View style={styles.overLay} />

        <View p={20}>
          {modalHeader && (
            <ModalHeader py={6} children={modalHeader} borderBottomWidth={0} bg="transparent" />
          )}

          <ModalBody children={<View flex={1}>{modalBody}</View>} />

          {modalFooter && (
            <ModalFooter py={6} borderTopWidth={0} bg="transparent" children={modalFooter} />
          )}
        </View>
      </ImageBackground>
    </>
  );

  const tvBody = <div className="navigation-container navigation-priority">{body}</div>;
  const tvAndroidBody = (
    <FocusView trapFocusDown trapFocusLeft trapFocusRight trapFocusUp>
      {body}
    </FocusView>
  );

  return (
    <Modal id="modal" focusable={false} avoidKeyboard hasTVPreferredFocus {...rest}>
      <ModalBackdrop />
      <ScrollView height="$full" width="$full" contentContainerStyle={{ minHeight: '100%' }}>
        <View
          flex={1}
          p={calcSizeForDevice(24)}
          height="$full"
          justifyContent="center"
          alignItems="center">
          <ModalContent
            borderWidth={1}
            rounded="$3xl"
            position="relative"
            bg={theme.colors.base.darkBlue}
            borderColor={theme.colors.gray[400]}>
            {Platform.isWebTV ? tvBody : Platform.isAndroidTV ? tvAndroidBody : body}
          </ModalContent>
        </View>
      </ScrollView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  imageBg: {
    backgroundColor: '#1a0f5f',
  },
  overLay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
