import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Back10Secs: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M9.5376 16.6691C9.1276 16.6691 8.7876 16.3291 8.7876 15.9191V12.5291L8.59759 12.7391C8.31759 13.0491 7.8476 13.0691 7.5376 12.7991C7.2276 12.5191 7.2076 12.0491 7.4776 11.7391L8.9776 10.0691C9.1876 9.8391 9.51758 9.75909 9.80758 9.86909C10.0976 9.97909 10.2876 10.2591 10.2876 10.5691V15.9191C10.2876 16.3391 9.9576 16.6691 9.5376 16.6691Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.0219 5.22042C9.85193 5.22042 9.69193 5.17044 9.55193 5.05044C9.23193 4.79044 9.18193 4.32045 9.43193 4.00045L11.4119 1.53048C11.6719 1.21048 12.1419 1.16042 12.4619 1.41042C12.7819 1.66042 12.8319 2.14047 12.5819 2.46047L10.6019 4.93044C10.4619 5.12044 10.2419 5.22042 10.0219 5.22042Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.9994 22.7507C6.68939 22.7507 2.35938 18.4307 2.35938 13.1107C2.35938 11.0407 3.02939 9.05071 4.30939 7.35071C4.55939 7.02071 5.02938 6.95074 5.35938 7.20074C5.68937 7.45074 5.7594 7.92073 5.5094 8.25073C4.4294 9.69073 3.85938 11.3707 3.85938 13.1107C3.85938 17.6007 7.50939 21.2507 11.9994 21.2507C16.4894 21.2507 20.1394 17.6007 20.1394 13.1107C20.1394 8.62072 16.4894 4.9707 11.9994 4.9707C11.4194 4.9707 10.8294 5.04073 10.1894 5.19073C9.78939 5.28073 9.3794 5.03074 9.2894 4.63074C9.1994 4.23074 9.4494 3.82071 9.8494 3.73071C10.5994 3.56071 11.3094 3.4707 11.9994 3.4707C17.3094 3.4707 21.6394 7.79072 21.6394 13.1107C21.6394 18.4307 17.3094 22.7507 11.9994 22.7507Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M14 16.6703C12.48 16.6703 11.25 15.4403 11.25 13.9203V12.5703C11.25 11.0503 12.48 9.82031 14 9.82031C15.52 9.82031 16.75 11.0503 16.75 12.5703V13.9203C16.75 15.4403 15.52 16.6703 14 16.6703ZM14 11.3303C13.31 11.3303 12.75 11.8903 12.75 12.5803V13.9303C12.75 14.6203 13.31 15.1803 14 15.1803C14.69 15.1803 15.25 14.6203 15.25 13.9303V12.5803C15.25 11.8903 14.69 11.3303 14 11.3303Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Back10Secs;
