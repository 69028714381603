import { Text, View } from '@gluestack-ui/themed';
import QRCode from 'react-native-qrcode-svg';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';

import ListNumber from './helpers/ListNumber';

import BandplayB from 'assets/favicon.png';

type TvQrContentLayoutProps = {
  title: string;
  url: string;
  qrCodeValue: string;
};

const TvQrContentLayout: React.FC<TvQrContentLayoutProps> = ({ title, url, qrCodeValue }) => (
  <View justifyContent="space-between" flexDirection="row">
    <View>
      <Text
        fontSize={calcSizeForTv(60)}
        lineHeight={calcSizeForTv(80)}
        color="white"
        mb={calcSizeForTv(70)}
        fontFamily={theme.fonts.inter.bold}>
        {title}
      </Text>
      <View
        px={calcSizeForTv(36)}
        py={calcSizeForTv(52)}
        borderRadius={23}
        backgroundColor="rgba(217, 217, 217, 0.075)">
        <View
          alignItems="center"
          maxWidth={calcSizeForTv(750)}
          flexDirection="row"
          mb={calcSizeForTv(60)}>
          <ListNumber number={1} />
          <Text
            flex={1}
            color="white"
            fontSize={calcSizeForTv(24)}
            lineHeight={calcSizeForTv(32)}
            ml={calcSizeForTv(32)}
            fontFamily={theme.fonts.inter.bold}>
            Acesse
            <Text
              color="#6386FF"
              fontSize={calcSizeForTv(24)}
              lineHeight={calcSizeForTv(32)}
              fontFamily={theme.fonts.inter.bold}>
              {` ${url} `}
            </Text>
            em seu navegador ou dispositivo móvel
          </Text>
        </View>
        <View alignItems="center" maxWidth={calcSizeForTv(750)} flexDirection="row">
          <ListNumber number={2} />
          <Text
            color="white"
            fontSize={calcSizeForTv(24)}
            lineHeight={calcSizeForTv(32)}
            ml={calcSizeForTv(32)}
            fontFamily={theme.fonts.inter.bold}
            maxWidth={calcSizeForTv(600)}>
            Ou escaneie o QR Code ao lado e acesse com seu smartphone
          </Text>
        </View>
      </View>
    </View>
    {qrCodeValue && (
      <View
        backgroundColor="white"
        width={calcSizeForTv(450)}
        height={calcSizeForTv(450)}
        alignItems="center"
        justifyContent="center">
        <QRCode
          value={qrCodeValue}
          size={calcSizeForTv(430)}
          backgroundColor="white"
          color="#070D31"
          logo={BandplayB}
          logoBackgroundColor="#070D31"
          logoSize={calcSizeForTv(70)}
          logoBorderRadius={calcSizeForTv(10)}
          logoMargin={calcSizeForTv(20)}
        />
      </View>
    )}
  </View>
);

export default TvQrContentLayout;
