import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SquareCheck: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M10.5814 15.5796C10.3814 15.5796 10.1914 15.4996 10.0514 15.3596L7.22141 12.5296C6.93141 12.2396 6.93141 11.7596 7.22141 11.4696C7.51141 11.1796 7.99141 11.1796 8.28141 11.4696L10.5814 13.7696L15.7214 8.62961C16.0114 8.33961 16.4914 8.33961 16.7814 8.62961C17.0714 8.91961 17.0714 9.39961 16.7814 9.68961L11.1114 15.3596C10.9714 15.4996 10.7814 15.5796 10.5814 15.5796Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SquareCheck;
