import { useWindowDimensions } from 'react-native';

const CarouselGradient: React.FC = () => {
  const { width: SCREEN_WIDTH } = useWindowDimensions();
  return (
    <>
      <div
        className={
          SCREEN_WIDTH < 500
            ? 'carousel-gradient-bottom--mobile'
            : 'carousel-gradient-bottom--desktop'
        }
      />
      {SCREEN_WIDTH > 500 && <div className="carousel-gradient-left" />}
    </>
  );
};

export default CarouselGradient;
