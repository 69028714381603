import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ChevronUp: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M6.85547 14.125L11.9983 8.875L17.1412 14.125"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ChevronUp;
