import { useEffect } from 'react';
import { Platform } from 'utils/platform';

type EasterEggMonitorProps = {
  handleEasterEgg: Function;
};

const SEQUENCE_WEB = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowUp',
  'ArrowDown',
];

const EasterEggMonitor: React.FC<EasterEggMonitorProps> = ({ handleEasterEgg }) => {
  let currentIndex = 0;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === SEQUENCE_WEB[currentIndex]) {
      currentIndex += 1;
      if (currentIndex === SEQUENCE_WEB.length) {
        handleEasterEgg();
      }
    } else {
      currentIndex = 0;
    }
  };

  useEffect(() => {
    if (Platform.isWebTV) {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return <></>;
};

export default EasterEggMonitor;
