import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Foward10Secs: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M13.9797 5.21973C13.7597 5.21973 13.5397 5.11976 13.3997 4.93976L11.4197 2.46973C11.1597 2.14973 11.2097 1.66974 11.5397 1.41974C11.8597 1.15974 12.3297 1.20973 12.5897 1.53973L14.5697 4.00977C14.8297 4.32977 14.7797 4.80975 14.4497 5.05975C14.3097 5.15975 14.1397 5.21973 13.9797 5.21973Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.9994 22.7507C6.68939 22.7507 2.35938 18.4307 2.35938 13.1107C2.35938 7.79072 6.67939 3.4707 11.9994 3.4707C12.6894 3.4707 13.3894 3.55071 14.1494 3.73071C14.5494 3.82071 14.8094 4.23074 14.7094 4.63074C14.6194 5.03074 14.2194 5.29073 13.8094 5.19073C13.1694 5.04073 12.5694 4.9707 11.9994 4.9707C7.50939 4.9707 3.85938 8.62072 3.85938 13.1107C3.85938 17.6007 7.50939 21.2507 11.9994 21.2507C16.4894 21.2507 20.1394 17.6007 20.1394 13.1107C20.1394 11.3707 19.5694 9.69073 18.4894 8.25073C18.2394 7.92073 18.3094 7.45074 18.6394 7.20074C18.9694 6.95074 19.4394 7.02071 19.6894 7.35071C20.9694 9.05071 21.6394 11.0407 21.6394 13.1107C21.6394 18.4307 17.3094 22.7507 11.9994 22.7507Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M9.5376 16.6691C9.1276 16.6691 8.7876 16.3291 8.7876 15.9191V12.5291L8.59759 12.7391C8.31759 13.0491 7.8476 13.0691 7.5376 12.7991C7.2276 12.5191 7.2076 12.0491 7.4776 11.7391L8.9776 10.0691C9.1876 9.8391 9.51758 9.75909 9.80758 9.86909C10.0976 9.97909 10.2876 10.2591 10.2876 10.5691V15.9191C10.2876 16.3391 9.9576 16.6691 9.5376 16.6691Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M14 16.6703C12.48 16.6703 11.25 15.4403 11.25 13.9203V12.5703C11.25 11.0503 12.48 9.82031 14 9.82031C15.52 9.82031 16.75 11.0503 16.75 12.5703V13.9203C16.75 15.4403 15.52 16.6703 14 16.6703ZM14 11.3303C13.31 11.3303 12.75 11.8903 12.75 12.5803V13.9303C12.75 14.6203 13.31 15.1803 14 15.1803C14.69 15.1803 15.25 14.6203 15.25 13.9303V12.5803C15.25 11.8903 14.69 11.3303 14 11.3303Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Foward10Secs;
