import { MenuTvItem } from '.';

export const SIDEBAR_MENU_TV: MenuTvItem[] = [
  {
    name: 'profile',
    icon: 'SolidUser',
    label: 'Meu perfil',
    onKidsMode: true,
    link: '/tvSubMenuArea',
    submenu: [
      {
        name: 'my-profile',
        icon: 'FaceSmile',
        label: 'Meu Perfil',
        link: '/perfil',
        menu: 'profile',
      },
      {
        name: 'kids-mode',
        icon: 'FaceGrinWide',
        label: 'Modo Infantil',
        menu: 'home',
        onKidsMode: true,
      },
      {
        name: 'age-rating',
        icon: 'TwoSliders',
        label: 'Classificação etária',
        menu: 'profile',
      },
      {
        name: 'faq',
        icon: 'CircleQuestion',
        label: 'Ajuda',
        link: '/ajuda',
        menu: 'profile',
      },
      {
        name: 'privacy-policies',
        icon: 'Lock',
        label: 'Políticas de Privacidade',
        link: '/termos',
        menu: 'profile',
      },
      {
        name: 'contact',
        icon: 'Envelope',
        label: 'Fale conosco',
        link: '/contato',
        menu: 'profile',
      },
      // { name: 'device', icon: 'Tv', label: 'Sobre o dispositivo', menu: 'profile' },
      {
        name: 'easter-egg',
        icon: 'Ghost',
        label: 'Developer Menu',
        menu: 'profile',
        easterEgg: true,
        link: '/easter-egg',
      },
      { name: 'loggout', icon: 'CircleXmark', label: 'Sair da Conta' },
    ],
  },
  { name: 'search', icon: 'MagnifyinGlass', label: 'Buscar', link: '/buscar', onKidsMode: true },
  { name: 'home', icon: 'HouseWindow', label: 'Início', link: '/', onKidsMode: true },
  { name: 'live', icon: 'Streaming', label: 'Ao Vivo', link: '/band-ao-vivo' },
  { name: 'watch', icon: 'ClapperboardPlay', label: 'Assista', link: '/assista' },
  { name: 'listen', icon: 'Radio', label: 'Ouça', link: '/ouca' },
  {
    name: 'favorites',
    icon: 'AudioLibrary',
    label: 'Minha Lista',
    link: '/favoritos',
    onKidsMode: true,
  },
  // { name: 'close', icon: 'CircleXmark', label: 'Sair' },
];
