import React, { ReactNode, createContext, useContext, useState } from 'react';
import useFirebaseAnalytics from 'services/analytics';

interface ModalContextType {
  isModalVisible: boolean;
  toggleModal: () => void;
  closeModal: () => void;
}
const EVENT_ACTION_PARAMS = {
  action: 'clique',
  reference: 'botao_menu',
  component: 'band+',
};
const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { logEventActionName } = useFirebaseAnalytics();
  const toggleModal = () => {
    if (!isModalVisible) {
      logEventActionName(EVENT_ACTION_PARAMS, 'action');
    }
    setModalVisible(!isModalVisible);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <ModalContext.Provider value={{ isModalVisible, toggleModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalConfig = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalConfig must be used within a ModalProvider');
  }
  return context;
};
