import { useGlobalConfig } from 'context/GlobalContext';
import { TourStep, offset } from 'react-native-spotlight-tour';

import SpotlightComponent from '.';

export const useTourSteps = (): TourStep[] => {
  const { setValueOffSetScroll } = useGlobalConfig();

  return [
    {
      shape: 'rectangle',
      floatingProps: {
        placement: 'bottom',
        middleware: [
          offset({
            crossAxis: -135,
            mainAxis: 5,
          }),
        ],
      },
      render: (props) => (
        <SpotlightComponent
          arrowPosition="top"
          spotlightTitle="Acessando a Minha Lista"
          spotlightDescription="Vá em Meu Perfil e acesse Minha lista para conferir seus conteúdos favoritos salvos!"
          {...props}
        />
      ),
    },
    {
      shape: 'rectangle',
      floatingProps: {
        placement: 'bottom',
        middleware: [
          offset({
            crossAxis: -25,
            mainAxis: 5,
          }),
        ],
      },
      render: (props) => (
        <SpotlightComponent
          arrowPosition="top"
          arrowAlign="center"
          spotlightTitle="Explore conteúdos"
          spotlightDescription="Encontre filmes, séries, rádios, podcasts e muito mais na busca!"
          {...props}
        />
      ),
    },
    {
      shape: 'rectangle',
      floatingProps: {
        placement: 'top',
        middleware: [offset(20)],
      },
      before() {
        setValueOffSetScroll?.(120);
      },
      render: (props) => (
        <SpotlightComponent
          arrowPosition="bottom"
          arrowAlign="center"
          spotlightTitle="Navegue pelo Ao Vivo"
          spotlightDescription="Explore canais de TV e rádios ao vivo!"
          {...props}
        />
      ),
    },
    {
      shape: 'rectangle',
      floatingProps: {
        placement: 'top',
        middleware: [
          offset({
            crossAxis: -120,
          }),
        ],
      },
      render: (props) => (
        <SpotlightComponent
          arrowPosition="bottom"
          arrowAlign="right"
          isLastItem
          spotlightTitle="Descubra mais com +Band"
          spotlightDescription="Não perca nenhuma novidade! O recurso +Band oferece acesso a conteúdos extras do grupo Bandeirantes para você."
          {...props}
        />
      ),
    },
  ];
};
