import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Constants from 'expo-constants';

export const apiConteudoSlice = createApi({
  reducerPath: 'apiConteudo',
  baseQuery: fetchBaseQuery({
    baseUrl: Constants.expoConfig?.extra?.apiConteudo,
  }),
  endpoints: (builder) => ({
    getPodcast: builder.query<ApiConteudoSpreakerEpisodes, { id: string; page?: number }>({
      query: ({ id, page = 1 }) =>
        `?query={spreaker_episodes(ShowId:${id},Page:${page},Limit:10){url:url_unificado streamUrl:playback_url image:image_url title duration episodeId:episode_id publishedAt:published_at}}`,
    }),
    getPodcastEpisode: builder.query<ApiConteudoSpreakerEpisodes, string>({
      query: (id) =>
        `?query={spreaker_episode(EpisodeId:${id}){titulo:title description episodeId:episode_id streamUrl:playback_url id:show_id date:published_at show{title}}}`,
    }),
  }),
});

export const { useGetPodcastQuery, useGetPodcastEpisodeQuery } = apiConteudoSlice;
