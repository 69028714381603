import { Box, Text, View } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import withLink from 'hoc/withLink';
import AnimatedLottieView from 'lottie-react-native';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import LottieImage from 'assets/Lottie/404.json';
import LottieLiveOff from 'assets/Lottie/tv.json';

import Button from 'components/Button';

const NotFound: React.FC<RootStackScreenProps<'NotFound'>> = ({ route }) => {
  const linkTo = useLinkTo();
  const checkTransmissionIsOff = route?.path?.includes('band-ao-vivo');

  return (
    <Box padding={calcSizeForDevice(16)} height="$full" alignItems="center" justifyContent="center">
      <View
        padding={calcSizeForDevice(16)}
        paddingHorizontal={calcSizeForDevice(16)}
        alignItems="center">
        {Platform.OS === 'web' ? (
          <Text
            fontWeight="$bold"
            color="white"
            fontSize={calcSizeForDevice(128, 192)}
            lineHeight={calcSizeForDevice(128, 192)}>
            404
          </Text>
        ) : (
          <View>
            {checkTransmissionIsOff ? (
              <AnimatedLottieView
                autoPlay
                style={{
                  width: 250,
                  height: 250,
                }}
                source={LottieLiveOff}
              />
            ) : (
              <AnimatedLottieView
                autoPlay
                style={{
                  width: 160,
                  height: 160,
                }}
                source={LottieImage}
              />
            )}
          </View>
        )}
        <Text
          fontSize={calcSizeForDevice(18, 27)}
          lineHeight={calcSizeForDevice(22, 27)}
          fontWeight="$bold"
          color="white"
          size="lg"
          textAlign="center"
          mt={Platform.OS === 'web' ? calcSizeForDevice(24, 12) : 0}
          mb={calcSizeForDevice(24, 60)}>
          {checkTransmissionIsOff ? (
            '🕖 Parece que essa transmissão já foi encerrada.'
          ) : (
            <>
              {Platform.isTV
                ? 'Ops! Parece que página não foi encontrada.'
                : 'Ops 🫣, Parece que página não foi encontrada.'}
            </>
          )}
        </Text>
        <Button
          useTVFocus
          hasTVPreferredFocus
          variant="gradient"
          size="md"
          onPress={() => linkTo('/')}
          style={{
            height: calcSizeForDevice(40, 60),
            paddingHorizontal: calcSizeForDevice(20, 30),
          }}
          textStyle={{ fontSize: calcSizeForDevice(16, 24) }}>
          Página Inicial
        </Button>
      </View>
    </Box>
  );
};

export default withLink(NotFound, 'NotFound');
