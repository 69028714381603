import { Box, Text } from '@gluestack-ui/themed';
import useHandlerError from 'hooks/useHandlerError';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useEffect, useState } from 'react';
import { useGetProgramsQuery, useGetTagQuery } from 'services/cms';
import theme from 'theme';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';
import { capitalize } from 'utils/string';

import FocusView from 'components/FocusView';
import Section from 'components/Section';
import IsLoading from 'components/isLoading';

const CategoryScreen: React.FC<RootStackScreenProps<'Category'>> = ({ navigation, route }) => {
  const { data: tag, isLoading: isLoadingTag, error } = useGetTagQuery(route.params.slug);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any[]>([]);
  useHandlerError(!!error);

  const { data: _data, isLoading: isLoadingPrograms } = useGetProgramsQuery(
    { [`${tag?.type}._id`]: tag?._id, page },
    { skip: !tag }
  );

  useEffect(() => {
    setData((old) => [...old, ...(_data?.items || [])]);
  }, [_data]);

  function PaginationOnEndReached() {
    setPage(page + 1);
  }

  const items: CmsCard[] =
    data?.map((item) => ({
      link: `/${item.url}`,
      title: item.title,
      image: item.imageVertical,
      height: item.imageVertical.height,
      type: item.type,
    })) || [];

  useEffect(() => {
    if (tag) navigation.setOptions({ title: tag.name });
  }, [navigation, tag]);

  if (isLoadingPrograms || isLoadingTag) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </Box>
    );
  }

  if (!tag) {
    return (
      <Box>
        <Text
          color="#fff"
          marginLeft={calcSizeForDevice(32)}
          fontSize={calcSizeForDevice(24)}
          fontWeight="$semibold">
          Nenhum item encontrado
        </Text>
      </Box>
    );
  }

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <Box px={calcSizeForDevice(32)} pb={calcSizeForDevice(32, 0)} pt={calcSizeForDevice(20, 120)}>
        {(Platform.OS === 'web' || Platform.isTV) && (
          <Text
            fontSize={calcSizeForDevice(30, 48)}
            color="$white"
            lineHeight={calcSizeForDevice(30, 60)}
            fontFamily={theme.fonts.inter.bold}
            ml={calcSizeForDevice(0, 15)}
            mb={calcSizeForDevice(60)}>
            {capitalize(tag?.name || '')}
          </Text>
        )}
        {!!items?.length && (
          <Section
            requestTVFocus
            onEndReached={PaginationOnEndReached}
            vertical
            items={items}
            _name="section"
            showTitle={false}
            title={tag?.name || ''}
            variant="section.medium"
            {...(Platform.isTV && { topContainerHeight: calcSizeForTv(240) })}
          />
        )}
      </Box>
    </FocusView>
  );
};

export default CategoryScreen;
