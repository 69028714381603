/* eslint-disable import/order */
import {
  Box,
  Button,
  ButtonText,
  HStack,
  Pressable,
  useBreakpointValue,
} from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import '@reduxjs/toolkit/dist/query';
import React, { useState } from 'react';
import { FlatList, TextInput } from 'react-native';
import useFirebaseAnalytics from 'services/analytics';
import { useTheme } from 'styled-components/native';
import { ProgramType } from 'utils/enum';

import TrackPlayer from '../../components/Radio/player';
import { SEARCH_MENU_OPTION } from './index';

import { Icons } from 'components/Icons';
import SearchVerticalList from 'components/Section/SearchVerticalList';

const Search: React.FC = () => {
  const theme = useTheme();

  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [categoryValue, setCategoryValue] = useState<ProgramType>();

  const paddingLeft = useBreakpointValue({ xl: 32, lg: 32, base: 15 });

  const { logEventPageCleanView } = useFirebaseAnalytics();

  const pauseRadio = async () => {
    await TrackPlayer.reset();
  };

  useFocusEffect(
    React.useCallback(() => {
      pauseRadio();
      logEventPageCleanView({
        page_title: 'Busca',
        template: 'Search',
        page_location: 'busca',
      });
    }, [])
  );

  return (
    <Box
      flex={1}
      bgColor="rgba(22, 18, 51, 0.5"
      paddingHorizontal={paddingLeft}
      paddingVertical={5}
      mb={25}>
      <HStack
        alignItems="center"
        borderWidth={2}
        borderColor={theme.colors.base.blue}
        borderRadius={5}
        width="$full"
        paddingHorizontal={5}
        paddingVertical={10}>
        <TextInput
          style={{
            color: 'white',
            marginLeft: 10,
            flex: 1,
            fontSize: 16,
          }}
          placeholder="Termos de Pesquisa"
          placeholderTextColor={theme.colors.base.white}
          value={searchInputValue}
          onChangeText={setSearchInputValue}
        />

        {!searchInputValue ? (
          <Box marginRight={10}>
            <Icons.MagnifyinGlass />
          </Box>
        ) : (
          <Pressable marginRight={5} onPress={() => setSearchInputValue('')}>
            <Icons.Xmark />
          </Pressable>
        )}
      </HStack>

      <Box mt={10} mb={15}>
        <FlatList
          data={SEARCH_MENU_OPTION}
          keyExtractor={(item) => item.title}
          renderItem={({ item }) => (
            <Button
              onPress={() => setCategoryValue(item.value)}
              overflow="hidden"
              px={10}
              py={5}
              mr={10}
              borderWidth={2}
              borderColor={categoryValue === item.value ? theme.colors.base.blue : '$white'}
              rounded="$md"
              bg="transparent"
              isPressed={categoryValue === item.value}>
              <ButtonText color={categoryValue === item.value ? theme.colors.base.blue : '$white'}>
                {item.title}
              </ButtonText>
            </Button>
          )}
          contentContainerStyle={{ height: 40 }}
          showsHorizontalScrollIndicator={false}
          horizontal
        />
      </Box>
      <SearchVerticalList searchInputValue={searchInputValue} categoryValue={categoryValue} />
    </Box>
  );
};

export default Search;
