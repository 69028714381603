import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackParamList } from 'navigation/AppNavigator';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';

const userRequired = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  return React.forwardRef<P, any>((props, ref) => {
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    const route = useRoute();

    if (!isAuthenticated) {
      navigation.navigate('Login', { redirect: route.path });
      return null;
    }

    return <Component ref={ref} {...props} />;
  });
};

export default userRequired;
