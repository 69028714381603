import { View } from '@gluestack-ui/themed';
import { ActivityIndicator } from 'react-native';

const IsLoading: React.FC = () => {
  return (
    <View>
      <ActivityIndicator size="large" color="#0000ff" />
    </View>
  );
};

export default IsLoading;
