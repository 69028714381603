import { createSlice } from '@reduxjs/toolkit';

type ProfileState = {
  hasCompletedOnboarding: boolean;
  hasCompletedNavigationTour: boolean;
};

const initialState: ProfileState = {
  hasCompletedOnboarding: false,
  hasCompletedNavigationTour: false,
};

const userDeviceSessionData = createSlice({
  name: 'userDeviceData',
  initialState,
  reducers: {
    setHasCompletedOnboarding: (state) => {
      state.hasCompletedOnboarding = true;
    },
    setCleanCompletedOnboarding: (state) => {
      state.hasCompletedOnboarding = false;
    },
    setHasCompletedNavigationTour: (state) => {
      state.hasCompletedNavigationTour = true;
    },
    setCleanCompletedNavigationTour: (state) => {
      state.hasCompletedNavigationTour = false;
    },
  },
});

export const {
  setHasCompletedOnboarding,
  setCleanCompletedOnboarding,
  setCleanCompletedNavigationTour,
  setHasCompletedNavigationTour,
} = userDeviceSessionData.actions;
export default userDeviceSessionData.reducer;
