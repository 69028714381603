import { useState } from 'react';
import { HWEvent, useTVEventHandler } from 'react-native';

const SEQUENCE_ANDROIDTV = [
  'up',
  'up',
  'down',
  'down',
  'right',
  'left',
  'right',
  'left',
  'up',
  'down',
];

let currentIndex = 0;

const useEasterEgg = ({ isConditionSastisfied = true }: { isConditionSastisfied?: boolean }) => {
  const [isDevMenuActive, setIsDevMenuActive] = useState(false);

  const handleKeyDown = (event: HWEvent) => {
    if (event.eventType === 'blur' || event.eventType === 'focus' || !isConditionSastisfied) return;

    if (event.eventType === SEQUENCE_ANDROIDTV[currentIndex]) {
      currentIndex += 1;
      if (currentIndex === SEQUENCE_ANDROIDTV.length) {
        setIsDevMenuActive(true);
      }
    } else {
      currentIndex = 0;
    }
  };

  // useTVEventHandler(handleKeyDown);

  return {
    isDevMenuActive,
    setIsDevMenuActive,
  };
};

export default useEasterEgg;
