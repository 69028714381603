import { FlatList } from '@gluestack-ui/themed';
import React from 'react';
import { ListRenderItem } from 'react-native';

import Skeleton from 'components/Skeleton';

const skeletonBody: ListRenderItem<any> = ({ item }) => {
  const variant = item?.variant?.replace('section.', '') ?? 'medium';
  return <Skeleton variant={variant} />;
};

const SectionVerticalSkeleton: React.FC<CmsHomeSection> = (props) => {
  const limit = props.searchPrograms?.limit ?? 10;

  const skeletonItems = React.useMemo(() => {
    return Array.from({ length: limit }, (_, index) => ({ index, variant: props.variant }));
  }, [limit]);

  return (
    <FlatList
      bounces={false}
      data={skeletonItems}
      scrollEnabled={false}
      initialNumToRender={3}
      maxToRenderPerBatch={3}
      renderItem={skeletonBody}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(_, index) => index.toString()}
      horizontal={props.variant !== 'section.boxed'}
      contentContainerStyle={{ columnGap: 10, marginBottom: 15 }}
    />
  );
};

export default SectionVerticalSkeleton;
