import { View } from '@gluestack-ui/themed';
import { calcSizeForTv } from 'utils/index';

import { SIMPLE_KEYBOARD } from './helpers/keyboardItems';

import Button from 'components/Button';
import Grid from 'components/Grid';

type SimpleKeyboardProps = { onHandleKeyPress: Function };

const SimpleKeyboard: React.FC<SimpleKeyboardProps> = ({ onHandleKeyPress }) => {
  return (
    <View w={calcSizeForTv(352)}>
      <Grid
        numColumns={6}
        data={SIMPLE_KEYBOARD}
        gap={calcSizeForTv(6)}
        isPlaceholderNeeded={false}
        renderItem={(keyItem) => (
          <Button
            useTVFocus
            hasTVPreferredFocus={keyItem.value === 'a'}
            variant="solid"
            style={{
              height: calcSizeForTv(52),
              width: keyItem.columns
                ? calcSizeForTv(keyItem.columns * 52 + (keyItem.columns - 1) * 4)
                : calcSizeForTv(52),
              paddingHorizontal: 0,
              borderWidth: 1,
              borderColor: 'rgba(255, 255, 255, 0.2)',
              backgroundColor: 'rgba(6, 113, 224, 0.07)',
              borderRadius: 2,
            }}
            onPress={() => onHandleKeyPress(keyItem)}
            textStyle={{ fontSize: calcSizeForTv(20) }}>
            {keyItem.symbol}
          </Button>
        )}
      />
    </View>
  );
};

export default SimpleKeyboard;
