import React from 'react';

import { usePlayer } from './context';

const Player: React.FC<PlayerProps> = (props) => {
  const player = usePlayer(props);

  if (!player.Component) {
    return null;
  }

  return <player.Component ref={player.ref} />;
};

export default React.memo(Player);
