export const capitalize = (text: string): string => {
  const parts = text.split(' ');

  if (parts.length === 1) {
    const [firstChar, ...rest] = text;
    return `${firstChar.toUpperCase()}${rest.join('').toLowerCase()}`;
  }

  const fragments = parts.map(capitalize);
  return fragments.join(' ');
};
