import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SharedValue, useSharedValue, withSpring } from 'react-native-reanimated';
import { Platform } from 'utils/platform';

type BottomSheetContextProps = {
  children?: React.ReactNode;
  visible: boolean;
  setVisible: (data: boolean) => void;
  handleToggleSheet: () => void;
  currentUrl: string;
  setCurrentUrl: (data: string) => void;
  liveMenuItems: LiveMenuItems[];
  setLiveMenuItems: Dispatch<SetStateAction<LiveMenuItems[]>>;
  menuOptions: any;
  setMenuOptions: (data: string) => void | undefined;
  currentMenu: {
    value: number;
    name: string;
    image: string;
  };
  setCurrentMenu: ({ value, name, image }: { value: number; name: string; image: string }) => void;
  translateY: SharedValue<number>;
  scrollTo: (data: number) => void;
  context: SharedValue<{
    y: number;
  }>;
};

const BottomSheetContext = createContext<BottomSheetContextProps>({} as BottomSheetContextProps);

const getMidTranslateYDivisor = () => {
  if (Platform.isMobileWeb('android')) {
    return 3.1;
  }

  return 3.8;
};

export const HEADER_HEIGHT = Platform.OS !== 'web' ? 45 : 0;
export const { height: SCREEN_HEIGHT } = Dimensions.get('window');
export const MAX_TRANSLATE_Y = SCREEN_HEIGHT / 1.5 - HEADER_HEIGHT;
export const MID_TRANSLATE_Y = SCREEN_HEIGHT / getMidTranslateYDivisor() + HEADER_HEIGHT;
export const MIN_TRANSLATE_Y = SCREEN_HEIGHT / 5;

export const BottomSheetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [menuOptions, setMenuOptions] = useState('');
  const [liveMenuItems, setLiveMenuItems] = useState<LiveMenuItems[]>([]);
  const [currentMenu, setCurrentMenu] = useState({
    value: 0,
    name: '',
    image: '',
  });
  const [currentUrl, setCurrentUrl] = useState('');
  const translateY = useSharedValue(SCREEN_HEIGHT);
  const context = useSharedValue({ y: SCREEN_HEIGHT });

  const handleToggleSheet = () => {
    if (visible) {
      scrollTo(SCREEN_HEIGHT);
    } else {
      scrollTo(-MID_TRANSLATE_Y);
    }

    setVisible(!visible);
  };

  const scrollTo = (destination: any) => {
    translateY.value = withSpring(destination, { damping: 50 });
  };

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <BottomSheetContext.Provider
        value={{
          visible,
          setVisible,
          handleToggleSheet,
          menuOptions,
          setMenuOptions,
          liveMenuItems,
          setLiveMenuItems,
          currentMenu,
          setCurrentMenu,
          currentUrl,
          setCurrentUrl,
          translateY,
          scrollTo,
          context,
        }}>
        {children}
      </BottomSheetContext.Provider>
    </GestureHandlerRootView>
  );
};

export const useBottomSheet = () => useContext(BottomSheetContext);

export default BottomSheetContext;
