import React from 'react';
import { AppState, Platform } from 'react-native';

export const useIsAppFocused = () => {
  const [isFocused, setIsFocused] = React.useState(true);
  const [notFocusedSince, setNotFocusedSince] = React.useState<number | undefined>();

  const webValidation = () => {
    const handleVisibilityChange = () => setIsFocused(!document.hidden);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  };

  const nativeValidation = () => {
    const handleAppStateChange = (nextAppState: any) => setIsFocused(nextAppState === 'active');
    const subscription = AppState.addEventListener('change', handleAppStateChange);
    return () => subscription.remove();
  };

  React.useEffect(() => {
    if (Platform.OS === 'web') return webValidation();
    return nativeValidation();
  }, []);

  React.useEffect(() => {
    if (!isFocused) setNotFocusedSince(Date.now());
  }, [isFocused]);

  return { isFocused, notFocusedSince };
};
