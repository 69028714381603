import { View } from '@gluestack-ui/themed';

const HelpBackground: React.FC = () => {
  return (
    <View w="$full" h="$full" position="absolute" overflow="hidden" opacity={0.3}>
      {/* No momento o time de design só forneceu uma imagem provisória no design,
          e por ter alguns filmes e séries que não podemos usar (Vingadores, por exemplo),
          vamos manter sem o background até que a imagem final seja fornecida
          <Image w="$full" h={810} source={HelpBackgroundImage} /> */}
    </View>
  );
};

export default HelpBackground;
