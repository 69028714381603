export enum PageNamesEnum {
  HOME = 'Home',
  LOGIN = 'Login',
  NULL = 'undefined',
  PROGRAM = 'Program',
}

export enum PageTemplateEnum {
  HOME = 'Home',
  LOGIN = 'Login',
  NULL = 'undefined',
  PROGRAM = 'Programa',
  SEARCH = 'Busca',
}

export enum EventsTypesEnum {
  PAGEVIEW = 'pageview',
  ACTION = 'action',
}

export enum ComponentsNameTypesEnum {
  PLAYER = 'Player',
  RADIO = 'Radio',
  RADIO_VIDEO = 'Botão de habilitar vídeo',
  CAST = 'Cast button',
}

export enum PlayerEventsEnum {
  PLAY = 'play',
  PAUSE = 'pause',
  SEEK = 'seek',
  SETTINGS = 'settings',
  CAST = 'cast',
  MUTE = 'mute',
  FULLSCREEN = 'fullscreen',
  NEXT = 'next',
  PREV = 'prev',
  ERROR = 'error',
}

export type PageviewType = {
  page_title: string;
  page_location?: string;
  user_id?: string | undefined;
  client_id?: string;
  central?: string; // faltando
  praca?: string; // faltando
  template?: string;
  media?: string;
  type?: string;
  genre?: any;
  subgenre?: string;
  category?: string;
  program?: string;
  season?: string;
  cast?: any;
  director?: any;
  year?: string;
  midia?: string;
};

export type ComponentEventType = {
  adUnit?: {
    name: string | undefined;
    value: string | undefined;
  } | null; // adUnit { name, value } se existir
  component?: string; // section + tipo  == section mid, section boxed, section.... ou uma string
  reference?: string; // novidades
  target?: number; // index da chamada
  label?: string; // rótulo
  button_title?: string;
};

export type Driver = {
  logEvent: (name: string, params?: { [key: string]: any }) => Promise<void>;
  getAppInstanceId: () => Promise<string | null>;
  setUserGaId: (id: string) => Promise<void>;
};
