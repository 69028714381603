import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Whatsapp: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12.0008 1C10.1222 0.999133 8.27549 1.48512 6.64067 2.41057C5.00586 3.33602 3.63878 4.66934 2.67275 6.28051C1.70672 7.89168 1.17472 9.7257 1.12863 11.6037C1.08253 13.4817 1.52392 15.3396 2.40975 16.9963L1.13038 21.6873C1.08808 21.834 1.08676 21.9895 1.12656 22.1369C1.16636 22.2843 1.24576 22.418 1.35615 22.5235C1.43832 22.5985 1.53463 22.6564 1.63944 22.6937C1.74426 22.731 1.85547 22.7471 1.96657 22.7409C2.04133 22.753 2.11757 22.753 2.19234 22.7409L7.13421 21.3612C7.34821 21.3024 7.53011 21.161 7.63988 20.9681C7.74965 20.7752 7.77831 20.5467 7.71954 20.3327C7.66077 20.1186 7.51939 19.9367 7.32651 19.827C7.13362 19.7172 6.90503 19.6886 6.69103 19.7473L3.15395 20.7006L4.11557 17.105C4.14411 16.9987 4.15142 16.8879 4.13707 16.7789C4.12272 16.6698 4.087 16.5647 4.03195 16.4695C3.01961 14.716 2.6141 12.6776 2.87828 10.6702C3.14247 8.66287 4.0616 6.79876 5.49314 5.36698C6.92468 3.93519 8.78864 3.01575 10.796 2.75122C12.8033 2.48669 14.8418 2.89186 16.5954 3.90391C18.349 4.91595 19.7197 6.4783 20.4949 8.34869C21.2701 10.2191 21.4065 12.293 20.883 14.2488C20.3594 16.2046 19.2052 17.9331 17.5993 19.1661C15.9934 20.3991 14.0255 21.0678 12.0008 21.0685C11.4854 21.069 10.9708 21.027 10.4622 20.9431C10.2438 20.9068 10.0199 20.9586 9.83967 21.0871C9.6594 21.2156 9.53747 21.4104 9.50062 21.6287C9.46432 21.8471 9.51612 22.071 9.64465 22.2513C9.77319 22.4316 9.96798 22.5535 10.1863 22.5904C11.6687 22.8396 13.1868 22.7787 14.6445 22.4115C16.1022 22.0443 17.4679 21.3787 18.6553 20.4568C19.8427 19.5349 20.826 18.3768 21.543 17.0556C22.2601 15.7344 22.6953 14.2788 22.8212 12.7808C22.9471 11.2828 22.7609 9.77503 22.2744 8.35266C21.788 6.93029 21.0118 5.62429 19.9949 4.51715C18.978 3.41002 17.7426 2.52583 16.3666 1.92044C14.9906 1.31506 13.5041 1.00165 12.0008 1Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M11.1633 7.86531L10.1934 6.52741C10.0472 6.32691 9.85948 6.16034 9.643 6.03911C9.42652 5.91788 9.18641 5.84485 8.93909 5.82501C8.69053 5.8067 8.44094 5.84202 8.20723 5.92858C7.97351 6.01515 7.76113 6.15092 7.58446 6.32673L6.58104 7.33015C6.35682 7.55912 6.18181 7.83153 6.06677 8.13063C5.95173 8.42974 5.89909 8.74922 5.91209 9.06942C5.91209 10.5495 7.0493 12.4142 9.25684 14.5883C11.8407 17.0968 13.6301 17.933 14.8509 17.933C15.1613 17.9412 15.47 17.8852 15.7577 17.7687C16.0455 17.6521 16.3061 17.4775 16.5233 17.2557L17.5267 16.2523C17.7093 16.081 17.8511 15.8709 17.9417 15.6376C18.0322 15.4042 18.0692 15.1535 18.05 14.9039C18.0307 14.6543 17.9557 14.4122 17.8304 14.1955C17.7051 13.9788 17.5327 13.793 17.326 13.6517L16.0132 12.7068C15.7947 12.5501 15.5429 12.4459 15.2775 12.4024C15.0121 12.3589 14.7402 12.3772 14.483 12.456L13.2371 12.849C13.1593 12.8435 13.0843 12.8175 13.0197 12.7737C12.2777 12.241 11.6159 11.6046 11.0546 10.8839C11.0492 10.8535 11.0492 10.8224 11.0546 10.792C11.1633 10.424 11.3473 9.83035 11.4727 9.42062C11.5539 9.15602 11.5681 8.87541 11.5141 8.60396C11.4601 8.33251 11.3396 8.07871 11.1633 7.86531ZM12.242 14.2454C12.6891 14.4905 13.2145 14.5505 13.7054 14.4127L14.9931 14.028L16.3393 15.0732L15.3443 16.0516C14.9429 16.4446 13.4629 16.3275 10.4442 13.3674C7.93566 10.9341 7.59283 9.60458 7.59283 9.03597C7.59911 8.84311 7.68006 8.66022 7.8186 8.5259L8.88892 7.53084L9.87562 8.91054C9.74183 9.32028 9.5495 9.93069 9.44916 10.2903C9.33004 10.6739 9.34478 11.0867 9.49097 11.4609C10.2121 12.5753 11.1611 13.5243 12.2755 14.2454H12.242Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Whatsapp;
