import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ChevronLeft: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000Ssvg"
    {...props}>
    <Path
      d="M14.125 17.1426L8.875 11.9997L14.125 6.85686"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default ChevronLeft;
