import { Box, Text } from '@gluestack-ui/themed';
import theme from 'theme';
import { calcSizeForDevice } from 'utils/index';

type InfoDescriptionProps = {
  title: string;
  text: string;
  variant?: 'horizontal' | 'vertical' | undefined;
};

const InfoDescription: React.FC<InfoDescriptionProps> = ({ title, text, variant = 'vertical' }) => {
  const isVertical = variant === 'vertical';

  return (
    <Box flexDirection={isVertical ? 'column' : 'row'} mb={calcSizeForDevice(15)}>
      <Text
        color="white"
        fontSize={isVertical ? calcSizeForDevice(18, 24) : calcSizeForDevice(15, 24)}
        lineHeight={calcSizeForDevice(18, 32)}
        fontFamily={theme.fonts.inter.bold}
        mb={calcSizeForDevice(15, 30)}
        mr={isVertical ? undefined : calcSizeForDevice(5)}>
        {title}
      </Text>
      <Text
        color="white"
        fontSize={calcSizeForDevice(14, 24)}
        lineHeight={calcSizeForDevice(18, 32)}
        fontFamily={theme.fonts.inter.regular}
        mb={isVertical ? calcSizeForDevice(24) : undefined}
        flexShrink={1}>
        {text}
      </Text>
    </Box>
  );
};

export default InfoDescription;
