import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Xmark: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M7.35814 19C7.01293 19 6.66771 18.8729 6.39518 18.6004C5.86827 18.0737 5.86827 17.2019 6.39518 16.6752L16.6789 6.39504C17.2058 5.86832 18.0779 5.86832 18.6048 6.39504C19.1317 6.92176 19.1317 7.79357 18.6048 8.32029L8.3211 18.6004C8.06674 18.8729 7.70335 19 7.35814 19Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M17.6419 19C17.2966 19 16.9514 18.8729 16.6789 18.6004L6.39518 8.32029C5.86827 7.79357 5.86827 6.92176 6.39518 6.39504C6.92208 5.86832 7.7942 5.86832 8.3211 6.39504L18.6048 16.6752C19.1317 17.2019 19.1317 18.0737 18.6048 18.6004C18.3323 18.8729 17.9871 19 17.6419 19Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Xmark;
