import React from 'react';
import { useSelector } from 'react-redux';
import useFirebaseAnalytics from 'services/analytics';
import { RootState } from 'store/index';

export const WithAnalyticsIdentification = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return React.forwardRef<P, any>((props, ref) => {
    const { setUserAppInstanceId } = useFirebaseAnalytics();
    const clientId = useSelector<RootState>((state) => state.screenAnalytics.client_id);

    React.useEffect(() => {
      setUserAppInstanceId();
    }, []);

    if (!clientId) return null;
    return <Component ref={ref} {...props} />;
  });
};
