import { useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';

import { Container, LogoBand, LogoText, SplashBg } from './styles';

import SplashLogo from 'assets/Splash/band_icon.png';
import LogoTextImg from 'assets/Splash/band_text.png';
import Bg from 'assets/bgAnimated.png';

const SplashScreen: React.FC = () => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return (
    <Container resizeMode="cover">
      <SplashBg source={Bg}>
        <View
          style={[
            {
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}>
          <Animated.View style={[{ position: 'absolute', opacity: fadeAnim }]}>
            <LogoBand source={SplashLogo} resizeMode="contain" />
          </Animated.View>
          <Animated.View style={{ position: 'absolute', opacity: fadeAnim }}>
            <LogoText source={LogoTextImg} resizeMode="contain" />
          </Animated.View>
        </View>
      </SplashBg>
    </Container>
  );
};

export default SplashScreen;
