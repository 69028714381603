import React from 'react';
import { useWindowDimensions } from 'react-native';
import theme from 'theme';
import useDropDownControls from 'components/DropDown/helpers/useDropDownControls';
import { DropDownProps } from 'components/DropDown/interface';
import { Menu } from 'components/DropDown/style';

const DropDown: React.FC<DropDownProps> = (props) => {
  const { height } = useWindowDimensions();
  const { isOpen, setIsOpen, triggerRenderer, items } = useDropDownControls(props);

  return <Menu
    mx={'$2'}
    my={'$4'}
    isOpen={isOpen}
    borderWidth={'$2'}
    alignContent={'center'}
    maxHeight={height / 2.5}
    trigger={triggerRenderer}
    placement={'bottom left'}
    onOpen={() => setIsOpen(true)}
    onClose={() => setIsOpen(false)}
    borderColor={theme.colors.base.blue}
    backgroundColor={theme.colors.base.darkBlue}
    showsHorizontalScrollIndicator={false}>
    {items}
  </Menu>
};

export default DropDown;
