/* eslint-disable import/order */
import { Image, Text, View } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useSocket } from 'context/SocketContext';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import React, { useCallback } from 'react';
import { BackGroundImageView } from 'screens/Login/styles';
import { useLogInMutation } from 'services/backend';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import BackgroundImage from 'assets/background.png';
import Logo from 'assets/loginLogo2x.png';

import NewGradientButton from 'components/Button/NewGradienteButton';
import { Icons } from 'components/Icons';
import { WebDiv } from 'components/WebDiv';
import IsLoading from 'components/isLoading';

const INFO_TEXTS = [
  <Text
    color="$white"
    fontSize={calcSizeForTv(24)}
    fontFamily={theme.fonts.inter.bold}
    lineHeight={calcSizeForTv(44)}>
    Escaneie o QR Code ao lado
  </Text>,
  <Text
    color="$white"
    fontSize={calcSizeForTv(24)}
    fontFamily={theme.fonts.inter.bold}
    lineHeight={calcSizeForTv(44)}>
    Faça login com seu usuário e senha
  </Text>,
  <Text
    flexShrink={1}
    color="$white"
    fontSize={calcSizeForTv(24)}
    fontFamily={theme.fonts.inter.bold}
    lineHeight={calcSizeForTv(44)}>
    Confirme o acesso e aproveite {'\n'}o conteúdo do{' '}
    <Text color="#6486ff" fontSize={calcSizeForTv(24)} fontFamily={theme.fonts.inter.bold}>
      Bandplay
    </Text>
    !
  </Text>,
];

const CTVLoginScreen: React.FC<RootStackScreenProps<'Login'>> = ({ route }) => {
  const linkTo = useLinkTo();
  const { socket } = useSocket();
  const { LoginScreen, dataIsLoading } = useGlobalConfig();
  const [deviceAuth, setDeviceAuth] = React.useState<any>();
  const [isBtnFocused, setIsBtnFocused] = React.useState(false);
  const [logIn, { isLoading: loginIsLoading, isSuccess: loginIsSuccess }] = useLogInMutation();
  const timer = React.useRef<NodeJS.Timeout | null>(null);
  const RENEW_TIME = 5 * 60 * 1000;

  const btnOnFocus = () => (Platform.isAndroidTV ? setIsBtnFocused(true) : null);
  const btnOnBlur = () => (Platform.isAndroidTV ? setIsBtnFocused(false) : null);

  const handleLoginCode = useCallback(
    async (code: string) => {
      await logIn({ code }).unwrap().catch(console.error);
      linkTo(route.params?.redirect || '/');
    },
    [logIn, linkTo, route?.params?.redirect]
  );

  const fetchCode = () => {
    socket.emit('auth', { client_id: 'TrvVxuuhvllszYhOTGcgiYDAV' }, setDeviceAuth);
  };

  const handleRenewCode = () => {
    fetchCode();
    if (timer?.current) {
      clearTimeout(timer.current);
    }
    timer.current = setInterval(fetchCode, RENEW_TIME);
  };

  React.useEffect(() => {
    socket.connect();

    // recebe as credenciais
    socket.on('oauth/code', (code: string) => handleLoginCode(code));

    fetchCode();

    // renova code + qrcode a cada 5 minutos
    timer.current = setInterval(fetchCode, RENEW_TIME);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
      socket.disconnect();
    };
  }, [socket, handleLoginCode]);

  if (!LoginScreen || dataIsLoading || loginIsLoading || loginIsSuccess) {
    return (
      <BackGroundImageView
        source={{
          uri:
            (typeof BackgroundImage != 'number' && BackgroundImage) ||
            'https://img.band.uol.com.br/image/2023/08/30/bgmobilebandplay-161926.png',
        }}>
        <View
          backgroundColor="transparent"
          flex={1}
          height="$full"
          width="$full"
          position="absolute"
          alignItems="center"
          justifyContent="center">
          <IsLoading />
        </View>
      </BackGroundImageView>
    );
  }

  return (
    <View flex={1} bg="#070D31">
      <BackGroundImageView
        source={{
          uri:
            (typeof BackgroundImage != 'number' && BackgroundImage) ||
            'https://img.band.uol.com.br/image/2023/08/30/bgmobilebandplay-161926.png',
        }}>
        <View w="$full" px={calcSizeForTv(192)}>
          <Image source={Logo} w={calcSizeForTv(290)} h={calcSizeForTv(82)} />

          <View
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            p={10}
            alignItems="center"
            mt={calcSizeForTv(32)}
            style={{ backdropFilter: 'blur(8px)' } as any}>
            <View mr={calcSizeForTv(80)}>
              <Text
                color="$white"
                fontSize={calcSizeForTv(44)}
                maxWidth={calcSizeForTv(837)}
                lineHeight={calcSizeForTv(52)}
                marginVertical={calcSizeForTv(48)}
                bold>
                {LoginScreen?.description}
              </Text>
              <View
                borderRadius="$2xl"
                p={calcSizeForTv(40)}
                maxWidth={calcSizeForTv(775)}
                bg="rgba(217, 217, 217, 0.07)"
                justifyContent="center">
                {INFO_TEXTS.map((text, index) => (
                  <View
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding={calcSizeForTv(16)}
                    key={index}>
                    <View w={calcSizeForTv(65)}>
                      <Text
                        fontFamily={theme.fonts.inter.bold}
                        fontSize={calcSizeForTv(48)}
                        lineHeight={calcSizeForTv(48)}
                        color="$white">
                        0{index + 1}
                      </Text>
                    </View>
                    <View
                      w={calcSizeForTv(7)}
                      h="$full"
                      minHeight={calcSizeForTv(60)}
                      marginHorizontal={calcSizeForTv(32)}
                      bg="#6486ff"
                    />
                    {text}
                  </View>
                ))}
              </View>
            </View>

            <View alignItems="center" flex={1}>
              {deviceAuth !== undefined ? (
                <>
                  <View display="flex" alignItems="center" mb={calcSizeForTv(20)}>
                    <View borderRadius="$lg" overflow="hidden">
                      <Image
                        source={{ uri: deviceAuth.qrcode }}
                        width={calcSizeForTv(380)}
                        height={calcSizeForTv(380)}
                      />
                    </View>
                    <Text
                      color="#FFFFFF"
                      fontSize={calcSizeForTv(32)}
                      lineHeight={calcSizeForTv(44)}
                      fontFamily={theme.fonts.inter.bold}
                      mt={calcSizeForTv(16)}>
                      {deviceAuth.code}
                    </Text>
                  </View>
                  <WebDiv className="navigation-container navigation-priority">
                    <NewGradientButton
                      hasTVPreferredFocus
                      onFocus={btnOnFocus}
                      onBlur={btnOnBlur}
                      gradientVariant="secondary"
                      role="button"
                      size={Platform.isAndroidTV ? 'xs' : 'lg'}
                      onPress={handleRenewCode}
                      style={{
                        paddingHorizontal: calcSizeForTv(52),
                        borderColor: isBtnFocused ? 'white' : 'transparent',
                        borderWidth: 2,
                        borderRadius: 25,
                      }}>
                      <View display="flex" flexDirection="row" alignItems="center">
                        <Icons.ArrowRotateLeft
                          width={calcSizeForTv(24).toString()}
                          height={calcSizeForTv(24).toString()}
                        />
                        <Text
                          color="white"
                          fontSize={calcSizeForTv(16)}
                          lineHeight={calcSizeForTv(24)}
                          fontFamily={theme.fonts.inter.bold}
                          ml={calcSizeForTv(10)}>
                          Atualizar QR Code
                        </Text>
                      </View>
                    </NewGradientButton>
                  </WebDiv>
                </>
              ) : (
                <View>
                  <IsLoading />
                </View>
              )}
            </View>
          </View>
        </View>
      </BackGroundImageView>
    </View>
  );
};

export default CTVLoginScreen;
