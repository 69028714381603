import { calcSizeForTv } from 'utils/index';

import { Icons } from 'components/Icons';

export type KeyItemProps = {
  item: string;
  symbol: string | React.ReactNode;
  value: string | null;
  columns?: number;
};

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
const NUMBERS = '1234567890';

const alphabetAndNumbers = [...ALPHABET.split(''), ...NUMBERS.split('')];

const SYMBOLS: { [key: string]: KeyItemProps } = {
  SPACE: {
    item: 'space',
    symbol: <Icons.Bracket width={String(calcSizeForTv(24))} height={String(calcSizeForTv(24))} />,
    value: ' ',
    columns: 2,
  },
  BACKSPACE: {
    item: 'backspace',
    symbol: (
      <Icons.DeleteLeft width={String(calcSizeForTv(24))} height={String(calcSizeForTv(24))} />
    ),
    value: null,
    columns: 2,
  },
  CLEAR: {
    item: 'clear',
    symbol: <Icons.Trash width={String(calcSizeForTv(24))} height={String(calcSizeForTv(24))} />,
    value: null,
    columns: 2,
  },
};

const keyboardOfAlphabetAndNumbers: KeyItemProps[] = alphabetAndNumbers.map((keyItem) => ({
  item: keyItem,
  symbol: keyItem,
  value: keyItem,
}));

export const SIMPLE_KEYBOARD: KeyItemProps[] = [
  ...keyboardOfAlphabetAndNumbers,
  SYMBOLS.SPACE,
  SYMBOLS.BACKSPACE,
  SYMBOLS.CLEAR,
];
