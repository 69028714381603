import { Box, Button, ButtonSpinner, ButtonText } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { PressableProps } from 'react-native';

import { ButtonProps } from './index';

import { getIconByName } from 'components/Icons';

type GradientButtonProps = Omit<ButtonProps, 'variant'>;

const GRADIENT_COLORS = {
  primary: ['#153c8e', '#194173', '#183954', '#142c44'],
  secondary: ['#19285e', '#1e2c5e', '#293360', '#353d60'],
  newBtnColors: ['#17b56e', '#4adc9a', '#17b56e', '#4adc9a'],
};

const GradientButton = React.forwardRef<PressableProps, GradientButtonProps>(
  (
    {
      children,
      gradientVariant = 'primary',
      iconStyle,
      iconType,
      isFullWidth = false,
      isLoading = false,
      size = 'md',
      textStyle,
      textTransform,
      ...rest
    },
    ref
  ) => {
    const Icon = iconType ? getIconByName(iconType) : null;

    return (
      <LinearGradient
        colors={GRADIENT_COLORS[gradientVariant]}
        start={{ x: 0.1, y: 0.1 }}
        end={{ x: 1, y: 0.1 }}
        locations={[0.1, 0.2, 0.5, 1]}
        style={{
          borderRadius: 5,
          width: isFullWidth ? '100%' : undefined,
          borderColor: 'white',
        }}>
        <Button size={size} backgroundColor="transparent" ref={ref} {...rest}>
          {Icon && (
            <Box mr="$1">
              <Icon {...iconStyle} />
            </Box>
          )}
          {children && (
            <ButtonText textTransform={textTransform} {...textStyle}>
              {children}
            </ButtonText>
          )}
          {isLoading && <ButtonSpinner color="white" size="small" marginLeft="$1" />}
        </Button>
      </LinearGradient>
    );
  }
);

export default GradientButton;
