import { useSocket } from 'context/SocketContext';
import { useEffect } from 'react';

import { usePlayer } from './context';

let UPDATE_WHILE_WATCHING_TIMER: NodeJS.Timeout;

const PlayerSocketUpdater: React.FC = () => {
  const { socket } = useSocket();
  const { ref: videoRef, isPlaying, isReady, isLive, isShowingAds } = usePlayer();

  const emitUpdateEvent = (eventName: string) => {
    const currentTime = Math.ceil(videoRef.current?.progress?.().currentTime || 0);
    if (currentTime > 0) socket.emit(eventName, currentTime);
  };

  const startWhileWatchingUpdater = (eventName: string) => {
    clearInterval(UPDATE_WHILE_WATCHING_TIMER);
    UPDATE_WHILE_WATCHING_TIMER = setInterval(() => emitUpdateEvent(eventName), 1000);
  };

  useEffect(() => {
    const isPlayingAndNotShowingAds = isPlaying && !isShowingAds;
    const eventName = isPlayingAndNotShowingAds ? 'play' : 'pause';

    if (!isReady || isLive) return;
    if (isPlayingAndNotShowingAds) startWhileWatchingUpdater(eventName);
    else clearInterval(UPDATE_WHILE_WATCHING_TIMER);
    emitUpdateEvent(eventName);

    return () => clearInterval(UPDATE_WHILE_WATCHING_TIMER);
  }, [socket, videoRef, isReady, isLive, isPlaying, isShowingAds]);

  return null;
};

export default PlayerSocketUpdater;
