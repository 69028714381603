/* eslint-disable react-native/no-inline-styles */
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { PersistentControlContainer, PersistentControlTitle } from './styles';

import { useStream } from 'components/Player/Stream/context';
import { usePlayer } from 'components/Player/context';

type PersistentControlProps = {
  navigationRef?: any;
};

const PersistentControl: React.FC<PersistentControlProps> = ({ navigationRef }) => {
  const { castState, device, status } = useStream();
  const { isStreaming, Component, mediaUrl, setMedia, media } = usePlayer();

  useEffect(() => {
    if (status && status.playerState != 'idle' && !media) {
      const { title, images } = status.mediaInfo.metadata;
      const { contentUrl, contentId } = status.mediaInfo;
      const isPlaying = status.playerState == 'playing';

      setMedia({
        media: { title, image: images.map((u) => u.url) },
        mediaUrl: contentUrl ?? contentId,
        isPlaying,
      });
    }
  }, [status?.playerState, media]);

  if (Component && media && mediaUrl && isStreaming) {
    return <Component compact navigationRef={navigationRef} />;
  }

  if (device && castState == 'connecting') {
    return (
      <PersistentControlContainer style={{ bottom: 0 }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <PersistentControlTitle
            style={{
              width: '100%',
              textAlign: 'center',
              marginLeft: 0,
            }}>
            Conectando a {device?.friendlyName}...
          </PersistentControlTitle>
        </View>
      </PersistentControlContainer>
    );
  }

  return null;
};

export default React.memo(PersistentControl);
