import TvHelpScreenLayout from 'components/Help/TvHelpScreenLayout.ctv';
import TvQrContentLayout from 'components/Help/TvQRContentLayout.ctv';

const TvFAQSectionScreen: React.FC = () => {
  return (
    <TvHelpScreenLayout>
      <TvQrContentLayout
        title="Perguntas Frequentes"
        url="bandplay.com/ajuda"
        qrCodeValue="https://bandplay.com/ajuda"
      />
    </TvHelpScreenLayout>
  );
};

export default TvFAQSectionScreen;
