import { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { Platform } from 'utils/platform';

import { Icons } from 'components/Icons';

export type VideoPreviewProps = {
  videoPreview: string;
  poster?: string;
  isPlaying: boolean;
  setIsPlaying: Function;
  setIsAllowed?: Function;
};

const VideoPreview: React.FC<VideoPreviewProps> = ({
  videoPreview,
  isPlaying,
  setIsPlaying,
  setIsAllowed,
}) => {
  const [isWebMuted, setIsWebMuted] = useState(true);
  const videoRefWeb = useRef<HTMLVideoElement>(null);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 2000,
      useNativeDriver: true,
    }).start();
  };

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 2000,
      useNativeDriver: true,
    }).start();
  };

  const handleEnded = () => {
    if (videoPreview && videoRefWeb.current) {
      setIsPlaying?.(false);
      if (Platform.OS === 'web' && !Platform.isTV) {
        setIsAllowed?.(false);
        fadeOut();
      }
    }
  };

  const handlePlay = () => {
    if (videoRefWeb.current) {
      videoRefWeb.current.currentTime = 0;
      videoRefWeb.current.play();

      if (Platform.OS === 'web' && !Platform.isTV) {
        fadeIn();
      }
    }
  };

  const handlePause = () => {
    if (videoRefWeb.current) {
      videoRefWeb.current.pause();

      if (Platform.OS === 'web' && !Platform.isTV) {
        setIsAllowed?.(false);
        fadeOut();
      }
    }
  };

  useEffect(() => {
    if (videoPreview && isPlaying && videoRefWeb?.current) {
      handlePlay();
    } else {
      handlePause();
    }
  }, [isPlaying]);

  return (
    <Animated.View style={{ opacity: Platform.isWebTV ? 1 : fadeAnim }}>
      <video ref={videoRefWeb} width="100%" height="100%" muted={isWebMuted} onEnded={handleEnded}>
        <source src={videoPreview} />
      </video>
      {Platform.OS === 'web' && !Platform.isTV && (
        <button
          onClick={() => setIsWebMuted(!isWebMuted)}
          style={{
            width: 50,
            height: 50,
            position: 'absolute',
            top: 100,
            right: 40,
            border: 'none',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }}>
          {isWebMuted ? <Icons.VolumeSlash /> : <Icons.VolumeHigh />}
        </button>
      )}
    </Animated.View>
  );
};

export default VideoPreview;
