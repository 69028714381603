import { useFocusEffect } from '@react-navigation/native';
import { HomeTabScreenProps } from 'navigation/tabs/HomeTab';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import useFirebaseAnalytics from 'services/analytics';
import { RootState } from 'store/index';
import { Platform } from 'utils/platform';

import Dynamic, { DynamicRef } from 'components/Dynamic';
import FocusView from 'components/FocusView';

const ADULTS_HOME = 'home-principal';
const KIDS_HOME = 'home-infantil';

const titlePage: Record<string, string> = {
  'home-principal': 'Home',
  assista: 'Assista',
  ouca: 'Ouça',
  'home-infantil': 'Home Infantil',
};

const useHomeScreenAnalytics = (id: string, isAuthenticated: boolean) => {
  const { logEventPageCleanView } = useFirebaseAnalytics();

  useFocusEffect(
    React.useCallback(() => {
      logEventPageCleanView({
        template: 'Home',
        page_location: id,
        page_title: titlePage[id] ?? 'none',
      });
    }, [isAuthenticated])
  );
};

const HomeScreen: React.FC<HomeTabScreenProps<'Home' | 'Videos' | 'Listen' | 'Generic'>> = ({
  route,
}) => {
  const dynamicRef = useRef<DynamicRef>(null);
  const auth = useSelector((state: RootState) => state.auth);
  const profile = useSelector((state: RootState) => state.profile);
  const id = route.params?.id || (profile.isKid && KIDS_HOME) || ADULTS_HOME;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  !Platform.isAndroidTV && useHomeScreenAnalytics(id, auth.isAuthenticated);

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <Dynamic
        id={id}
        ref={dynamicRef}
        key={`${id}:${profile.ageRating}:${auth.isAuthenticated}`}
      />
    </FocusView>
  );
};

export default HomeScreen;
