import { Text, View } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';

import Button from 'components/Button';

const EmptyFavorites: React.FC = () => {
  const linkTo = useLinkTo();

  return (
    <View h={calcSizeForTv(600)} alignItems="center" justifyContent="center">
      <Text
        color="white"
        fontSize={calcSizeForTv(42)}
        lineHeight={calcSizeForTv(60)}
        fontFamily={theme.fonts.inter.black}>
        Sua lista está vazia
      </Text>
      <Text
        color="white"
        fontFamily={theme.fonts.inter.regular}
        fontSize={calcSizeForTv(28)}
        lineHeight={calcSizeForTv(40)}
        maxWidth={calcSizeForTv(800)}
        textAlign="center"
        mt={calcSizeForTv(20)}
        mb={calcSizeForTv(40)}>
        Adicione seus conteúdos favoritos em sua lista para assistir quando quiser
      </Text>
      <Button
        useTVFocus
        hasTVPreferredFocus
        textStyle={{ fontSize: calcSizeForTv(24) }}
        onPress={() => linkTo('/')}>
        Tela inicial
      </Button>
    </View>
  );
};

export default EmptyFavorites;
