import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Instagram: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11.8758 16.1695C14.2471 16.1695 16.1695 14.2471 16.1695 11.8758C16.1695 9.5044 14.2471 7.58203 11.8758 7.58203C9.5044 7.58203 7.58203 9.5044 7.58203 11.8758C7.58203 14.2471 9.5044 16.1695 11.8758 16.1695Z"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <Path
      d="M16.5987 2H7.15248C4.30684 2 2 4.30684 2 7.15248V16.5987C2 19.4443 4.30684 21.7512 7.15248 21.7512H16.5987C19.4443 21.7512 21.7512 19.4443 21.7512 16.5987V7.15248C21.7512 4.30684 19.4443 2 16.5987 2Z"
      stroke={color || '#FFF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.4561 7.5821C18.1675 7.5821 18.7442 7.00539 18.7442 6.29398C18.7442 5.58257 18.1675 5.00586 17.4561 5.00586C16.7447 5.00586 16.168 5.58257 16.168 6.29398C16.168 7.00539 16.7447 7.5821 17.4561 7.5821Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default Instagram;
