/* eslint-disable import/order */
import { Text } from '@gluestack-ui/themed';
import { useModalConfig } from 'context/ModalContext';
import { useMemo } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Icons } from 'components/Icons';

type Props = {
  title: string;
  icon: 'home' | 'radio' | 'video' | 'bandmore';
  onFocus: boolean;
};

const TabBarButton: React.FC<Props> = ({ title, icon, onFocus }) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const { isModalVisible } = useModalConfig();

  const tabBarPlainerWidth = useMemo(
    () => (width < 450 ? '100%' : width < 700 ? 120 : width < 1000 ? 150 : 170),
    []
  );

  const Icon = {
    home: <Icons.HouseWindow color={onFocus ? (isModalVisible ? '#808191' : '#FFF') : '#808191'} />,
    radio: <Icons.Radio color={onFocus ? (isModalVisible ? '#808191' : '#FFF') : '#808191'} />,
    video: (
      <Icons.ClapperboardPlay color={onFocus ? (isModalVisible ? '#808191' : '#FFF') : '#808191'} />
    ),
    bandmore: <Icons.BandMore color={isModalVisible ? '#FFF' : '#808191'} />,
  };

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', width: tabBarPlainerWidth }}>
      {Icon[icon]}
      <Text
        size="sm"
        color={
          onFocus
            ? isModalVisible
              ? theme.colors.gray[400]
              : theme.colors.base.white
            : theme.colors.gray[400]
        }>
        {title}
      </Text>
    </View>
  );
};

export default TabBarButton;
