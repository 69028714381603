import React, { PropsWithChildren } from 'react';
import { FocusGuideProps } from 'react-native';

/**
 * A helper component to handle Android TV Navigation
 * @param props PropsWithChildren<FocusViewProps>
 * @returns React.ReactNode
 */
const FocusView = React.forwardRef<any, PropsWithChildren<FocusGuideProps>>((props, ref) => {
  return props.children;
});

export default FocusView;
