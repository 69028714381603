import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const TriangleExclamation: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12 18.0005C11.94 18.0005 11.87 17.9905 11.8 17.9805C11.74 17.9705 11.68 17.9505 11.62 17.9205C11.56 17.9005 11.5 17.8705 11.44 17.8305C11.39 17.7905 11.34 17.7505 11.29 17.7105C11.11 17.5205 11 17.2605 11 17.0005C11 16.7405 11.11 16.4805 11.29 16.2905C11.34 16.2505 11.39 16.2105 11.44 16.1705C11.5 16.1305 11.56 16.1005 11.62 16.0805C11.68 16.0505 11.74 16.0305 11.8 16.0205C11.93 15.9905 12.07 15.9905 12.19 16.0205C12.26 16.0305 12.32 16.0505 12.38 16.0805C12.44 16.1005 12.5 16.1305 12.56 16.1705C12.61 16.2105 12.66 16.2505 12.71 16.2905C12.89 16.4805 13 16.7405 13 17.0005C13 17.2605 12.89 17.5205 12.71 17.7105C12.66 17.7505 12.61 17.7905 12.56 17.8305C12.5 17.8705 12.44 17.9005 12.38 17.9205C12.32 17.9505 12.26 17.9705 12.19 17.9805C12.13 17.9905 12.06 18.0005 12 18.0005Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M18.0585 22.1598H5.93851C3.98851 22.1598 2.49851 21.4498 1.73851 20.1698C0.988511 18.8898 1.08851 17.2398 2.03851 15.5298L8.09851 4.62984C9.09851 2.82984 10.4785 1.83984 11.9985 1.83984C13.5185 1.83984 14.8985 2.82984 15.8985 4.62984L21.9585 15.5398C22.9085 17.2498 23.0185 18.8898 22.2585 20.1798C21.4985 21.4498 20.0085 22.1598 18.0585 22.1598ZM11.9985 3.33984C11.0585 3.33984 10.1385 4.05984 9.40851 5.35984L3.35851 16.2698C2.67851 17.4898 2.56851 18.6098 3.03851 19.4198C3.50851 20.2298 4.54851 20.6698 5.94851 20.6698H18.0685C19.4685 20.6698 20.4985 20.2298 20.9785 19.4198C21.4585 18.6098 21.3385 17.4998 20.6585 16.2698L14.5885 5.35984C13.8585 4.05984 12.9385 3.33984 11.9985 3.33984Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default TriangleExclamation;
