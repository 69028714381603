import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowRight: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M3.99963 12.2742C3.99963 12.6539 4.28179 12.9677 4.64786 13.0173L4.74963 13.0242L19.7496 13.0242C20.1638 13.0242 20.4996 12.6884 20.4996 12.2742C20.4996 11.8945 20.2175 11.5807 19.8514 11.531L19.7496 11.5242L4.74963 11.5242C4.33542 11.5242 3.99963 11.86 3.99963 12.2742Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M13.1709 17.767C12.8774 18.0592 12.8763 18.5341 13.1686 18.8276C13.4343 19.0945 13.8509 19.1196 14.145 18.9024L14.2293 18.8299L20.2793 12.8059C20.5469 12.5394 20.5712 12.1213 20.3523 11.8272L20.2793 11.743L14.2293 5.71802C13.9358 5.42574 13.4609 5.42672 13.1686 5.72022C12.9029 5.98704 12.8796 6.40375 13.0981 6.69691L13.1708 6.78088L18.6869 12.2748L13.1709 17.767Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ArrowRight;
