/* eslint-disable import/order */
import { useNavigation } from '@react-navigation/native';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DimensionValue, Platform, TouchableOpacity, View } from 'react-native';
import { MediaStreamType } from 'react-native-google-cast';

import CastSettingsButton from '../Video/UI/CastSettingsButton';
import VideoPauseButton from '../Video/UI/PauseButton';
import VideoPlayButton from '../Video/UI/PlayButton';
import { usePlayer } from '../context';
import StreamPlay from './UI/StreamPlay';
import {
  PersistenControlButtonContainer,
  PersistenControlTitleContainer,
  PersistentControlContainer,
  PersistentControlImage,
  PersistentControlImageOverlay,
  PersistentControlTitle,
} from './UI/styles';
import { useStream } from './context';

import { Icons } from 'components/Icons';

const StreamPlayer = forwardRef<CustomPlayerRef, CustomPlayerProps>((props, ref) => {
  const { isPlaying, media, reset, recovering, mediaUrl, adTagUrl, startTime, isLive } =
    usePlayer();

  const cachedMediaUrl = useRef('');
  const navigation = useNavigation();
  const recovered = useRef<boolean>();
  const [hasPaddingBottom, setHasPaddingBottom] = useState(false);
  const { client, status, castState, loadMedia, closeMedia, showControls } = useStream();
  useImperativeHandle(ref, () => ({ seek(time: number) {} }), []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', () => {
      const hasPadding = getScreenPadding(getCurrentScreenName());
      setHasPaddingBottom(hasPadding);
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  function calculateStreamPosition() {
    if (status?.mediaInfo) {
      const metaData = status?.mediaInfo;
      const duration = metaData?.streamDuration;

      const position = (0 / duration) * 100;
      return `${position}%`;
    }

    return 0;
  }

  const getCurrentScreenName = () => {
    if (navigation) {
      return props.navigationRef?.current.getCurrentRoute().name;
    }

    return 'UnknownScreen';
  };

  function getScreenPadding(screenName: string) {
    switch (screenName) {
      case 'Home':
      case 'Videos':
      case 'Listen':
        return true;
      default:
        return false;
    }
  }

  useEffect(() => {
    (async function bootstrap() {
      if (client && media && mediaUrl && !cachedMediaUrl.current && !recovering) {
        cachedMediaUrl.current = mediaUrl;

        loadMedia({
          autoplay: true,
          mediaInfo: {
            contentUrl: mediaUrl,
            contentId: mediaUrl,
            contentType: 'application/x-mpegurl',
            streamType: isLive ? MediaStreamType.LIVE : MediaStreamType.BUFFERED,
            metadata: {
              startTime,
              type: 'movie',
              title: media?.title,
              images: [
                { url: media?.image[0], width: 480, height: 270 },
                { url: media?.image[1], width: 780, height: 1200 },
              ],
            },
            customData: {
              adTagUrl,
              startTime,
            },
          },
        });
      }
    })();
  }, [client, media, mediaUrl, recovering]);

  useEffect(() => {
    if (client == null || status == null || !mediaUrl || recovering !== recovered.current) {
      recovered.current = recovering;
      return;
    }

    if (isPlaying) {
      if (status.playerState !== 'playing') client.play();
    } else {
      if (status.playerState !== 'paused') client.pause();
    }
  }, [client, recovering, isPlaying, mediaUrl]);

  const handleCloseMedia = async () => {
    await closeMedia();
    reset({ autoplay: false, media: undefined });
  };

  if (!props.compact) {
    return (
      <View>
        <StreamPlay />
      </View>
    );
  }

  return (
    <PersistentControlContainer
      style={{
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,
        elevation: 7,
        bottom: !hasPaddingBottom
          ? Platform.OS === 'ios'
            ? 40
            : 0
          : Platform.OS === 'ios'
          ? 80
          : 70,
      }}>
      {media !== undefined && castState !== 'connecting' && (
        <View style={{ flexDirection: 'row', marginTop: -3 }}>
          <View
            style={{
              height: 3,
              zIndex: 1,
              backgroundColor: 'red',
              width: calculateStreamPosition() as DimensionValue,
            }}
          />
          <View style={{ height: 3, backgroundColor: '#c3c3c3', width: '100%' }} />
        </View>
      )}

      {media !== undefined && castState !== 'connecting' && (
        <View
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}>
          {media?.image && (
            <TouchableOpacity onPress={showControls}>
              <View style={{ position: 'relative' }}>
                <PersistentControlImageOverlay />
                <PersistentControlImage source={{ uri: media.image[1] }} />
              </View>
            </TouchableOpacity>
          )}

          <TouchableOpacity style={{ flex: 1 }} onPress={showControls}>
            <PersistenControlTitleContainer style={{ flexDirection: 'column' }}>
              <PersistentControlTitle numberOfLines={2}>{media?.title}</PersistentControlTitle>
            </PersistenControlTitleContainer>
          </TouchableOpacity>

          <PersistenControlButtonContainer>
            {status?.playerState === 'paused' ? (
              <VideoPlayButton black />
            ) : (
              <VideoPauseButton black />
            )}
            <CastSettingsButton />
            <TouchableOpacity onPress={handleCloseMedia}>
              <Icons.CastXMarkSolid color="black" />
            </TouchableOpacity>
          </PersistenControlButtonContainer>
        </View>
      )}
    </PersistentControlContainer>
  );
});

StreamPlayer.displayName = 'StreamPlayer';

export default React.memo(StreamPlayer);
