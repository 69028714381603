import { Platform } from 'react-native';

type CropMap = {
  [aspect: string]: {
    [platform: string]: { [size: string]: string };
  };
};

const platformCrops: CropMap = {
  '1x1': {
    native: { sm: '150x150', md: '300x300', lg: '500x500' },
    web: { sm: '300x300', md: '500x500', lg: '500x500' },
    tv: { sm: '300x300', md: '500x500', lg: '500x500' },
  },
  '4x3': {
    native: { sm: '200x150', md: '400x300', lg: '800x600' },
    web: { sm: '400x300', md: '800x600', lg: '1200x900' },
    tv: { sm: '400x300', md: '800x600', lg: '1200x900' },
  },
  '16x9': {
    native: { sm: '300x168', md: '600x337', lg: '800x450' },
    web: { sm: '600x337', md: '800x450', lg: '1200x374' },
    tv: { sm: '600x337', md: '800x450', lg: '1200x374' },
  },
  '3x4': {
    native: { sm: '300x400', md: '600x800', lg: '600x800' },
    web: { sm: '300x400', md: '600x800', lg: '600x800' },
    tv: { sm: '300x400', md: '600x800', lg: '600x800' },
  },
};

export type ImageDynamicCrop = {
  aspect?: '1x1' | '4x3' | '16x9' | '3x4';
  size?: 'sm' | 'md' | 'lg';
  ext?: '.webp' | '.png';
  uri?: string;
};

const getImageDynamicCrop = ({ uri, aspect, size = 'md', ext = '.webp' }: ImageDynamicCrop) => {
  const key = Platform.isTV ? 'tv' : Platform.OS === 'web' ? 'web' : 'native';
  if (!aspect || !uri?.includes('img.band.uol')) return uri;

  const crop = platformCrops[aspect][key][size];
  return uri?.replace(/(?:_\d+x\d+)?\.[^.]+$/, `_${crop}${ext}`);
};

export default getImageDynamicCrop;
