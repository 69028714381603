import { Text, View } from 'react-native';
import theme from 'theme';
import { AgeRatingKey } from 'utils/ageRating';
import { calcSizeForDevice } from 'utils/index';

import { usePlayer } from 'components/Player/context';
import Rating from 'components/Rating';

const VideoInfo: React.FC = () => {
  const { media } = usePlayer();
  return media ? <VideoInfoView {...media} /> : null;
};

export const VideoInfoView: React.FC<PlayerMedia> = ({ title, ratingDescription, ratingValue }) => {
  return (
    <View>
      <Text
        style={{
          color: 'white',
          fontFamily: theme.fonts.inter.bold,
          fontSize: calcSizeForDevice(18, 35),
        }}>
        {title}
      </Text>

      <View style={{ marginTop: 10 }}>
        <Rating
          append="custom"
          customAppendix={ratingDescription}
          ageRatingKey={ratingValue as AgeRatingKey}
        />
      </View>
    </View>
  );
};

export default VideoInfo;
