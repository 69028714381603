/* eslint-disable import/order */
import { Box, HStack, View } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import useFirebaseAnalytics from 'services/analytics';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import Hero from 'components/Hero';
import Player from 'components/Player';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramHeader from 'components/Program/Header';
import ProgramRelated from 'components/Program/Related';
import ProgramShared from 'components/Program/Shared';
import { useProgram } from 'components/Program/context';
import { WebDiv } from 'components/WebDiv';

const LiveScreen: React.FC<RootStackScreenProps<'Program'>> = ({ navigation }) => {
  const {
    isPlaying,
    isFullScreen,
    isStreaming,
    pause,
    play,
    isVisible,
    setIsVisible,
    mute,
    setNextTrack,
  } = usePlayer();

  const program = useProgram();
  const [mediaId, setMediaId] = useState<string>();
  const [isActive, setIsActive] = useState(false);
  const { ads, colors } = useGlobalConfig();
  const { logEventAction } = useFirebaseAnalytics();
  const { setVisible, setLiveMenuItems } = useBottomSheet();

  const { width } = useWindowDimensions();

  useEffect(() => {
    setNextTrack(undefined);
  }, []);

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) {
        pause();
      }
      setVisible(false);
      mute(false);
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);
      return () => {
        if (!isStreaming) {
          pause();
        }
        setIsActive(false);
      };
    }, [isStreaming])
  );

  const playerProps: PlayerProps | undefined = useMemo(() => {
    const media: PlayerMedia = {
      title: program.title,
      image: [program.imageVertical?.url!, program.imageHorizontal?.url!],
      duration: program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: program.mediaId });

    return {
      media,
      isLive: program.type == ProgramType.LIVE || program.type == ProgramType.RADIO,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
              /iu=([^&]+)/,
              (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
            ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId]);

  useEffect(() => {
    if (program?.ads?.disable_autoPlay !== true) {
      setMediaId(program.mediaId);
    }

    setLiveMenuItems?.(program.liveMenu ?? []);
  }, [program]);

  const clickWatchObject = {
    component: 'Botão assistir',
    reference: 'Assistir',
    adUnit:
      ads?.disableAds || program.ads?.disableAds
        ? null
        : { name: program.ads?.adunit?.name, value: program.ads?.adunit?.val },
  };

  const Video = useMemo(
    () =>
      mediaId != undefined && isActive && playerProps != undefined ? (
        <Player {...playerProps} autoplay={!Platform.isAndroidTV} />
      ) : undefined,
    [mediaId, isActive, playerProps]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile !== undefined && (
        <View style={{ marginTop: isFullScreen ? 0 : Platform.isMobileWeb() ? 45 : 80 }}>
          <>
            {program.spallaUrl ? (
              <WebDiv>
                <iframe
                  id="spalla-player"
                  src={program.spallaUrl}
                  title="Spalla Test"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                  style={{
                    width: '98%',
                    height: 250,
                  }}
                  allowFullScreen
                />
              </WebDiv>
            ) : (
              <>{Video}</>
            )}
          </>
        </View>
      )}
      {(Platform.OS === 'web' || !isFullScreen) && (
        <Hero program={program} hideCover={mediaId != undefined}>
          <Hero.Header
            program={program}
            mediaId={mediaId}
            Aside={
              VideoDesktop != undefined && !Platform.isAndroidTV ? (
                <Box width={Platform.isTV ? 0 : '80%'}>
                  <>
                    {program.spallaUrl ? (
                      <WebDiv>
                        <iframe
                          id="spalla-player"
                          src={program.spallaUrl}
                          title="Spalla Test"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                          style={{
                            width: '100%',
                            height: width < 1300 ? 300 : width < 1600 ? 420 : 500,
                          }}
                          allowFullScreen
                        />
                      </WebDiv>
                    ) : (
                      <>{Video}</>
                    )}
                  </>
                </Box>
              ) : undefined
            }>
            <ProgramHeader />
            {Platform.isTV && (
              <View mt={calcSizeForTv(30)}>
                <Button
                  useTVFocus
                  hasTVPreferredFocus
                  style={{ width: calcSizeForTv(300), height: calcSizeForTv(52) }}
                  textStyle={{ fontSize: calcSizeForTv(24) }}
                  iconStyle={{ width: calcSizeForTv(30), height: calcSizeForTv(30) }}
                  iconType="Play"
                  variant="gradient"
                  gradientVariant={colors ? 'newBtnColors' : undefined}
                  size="md"
                  onPress={() => {
                    if (mediaId) {
                      play();
                    }
                    if (program?.ads?.disable_autoPlay === true || !mediaId) {
                      setMediaId(program.mediaId);
                      play();
                    }
                    setIsVisible(true);
                  }}>
                  Assistir
                </Button>
              </View>
            )}
            {program?.ads?.disable_autoPlay && !Platform.isTV && (
              <Button
                style={{
                  ...(Platform.OS === 'web' || Platform.isTV
                    ? {
                        width: calcSizeForDevice(300, 400),
                        display: mediaId && !Platform.isTV ? 'none' : undefined,
                        height: calcSizeForDevice(40, 52),
                      }
                    : { display: mediaId ? 'none' : undefined }),
                }}
                textStyle={{ fontSize: calcSizeForDevice(18, 24) }}
                iconStyle={{
                  width: calcSizeForDevice(24, 30),
                  height: calcSizeForDevice(24, 30),
                }}
                iconType="Play"
                variant="gradient"
                gradientVariant={colors ? 'newBtnColors' : undefined}
                size="md"
                isFullWidth={Platform.OS !== 'web' && !Platform.isTV}
                onPress={() => {
                  logEventAction(clickWatchObject);
                  if (mediaId) {
                    play();
                    return;
                  }
                  setMediaId(program.mediaId);
                  play();
                }}>
                Assistir
              </Button>
            )}

            <HStack alignItems="center" mt={10}>
              <ProgramShared />
            </HStack>
            <ProgramContent />
          </Hero.Header>
          <ProgramRelated />
        </Hero>
      )}
    </>
  );
};

export default LiveScreen;
