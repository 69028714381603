const borderColors = { green: '#00D170', blue: '#001FE6', red: '#E01507', lightBlue: '#7A8CFF' };

export const PROD_ENVIRONMENTS = [
  {
    title: 'PROD',
    url: 'https://app-bandplay-ctv.azurewebsites.net/',
    description: '',
    borderColor: borderColors.green,
  },
  {
    title: 'PRE-PROD',
    url: 'http://app-bandplay-ctv-pre-prod.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: '',
  },
];

export const QA_ENVIRONMENTS = [
  {
    title: 'HML',
    url: 'http://app-bandplay-ctv-hml.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: '',
  },
  {
    title: 'BETA',
    url: 'http://app-bandplay-ctv-beta.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: 'On hold',
    borderColor: '',
  },
];

export const DEV_ENVIRONMENTS = [
  {
    title: 'VED1',
    url: 'http://app-bandplay-ctv-ved1.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: borderColors.green,
  },
  {
    title: 'VED2',
    url: 'http://app-bandplay-ctv-ved2.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: borderColors.green,
  },
  {
    title: 'VED3',
    url: 'http://app-bandplay-ctv-ved3.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: borderColors.green,
  },
  {
    title: 'VED4',
    url: 'http://app-bandplay-ctv-ved4.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: borderColors.green,
  },
  {
    title: 'VED5',
    url: 'http://app-bandplay-ctv-ved5.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: '',
    borderColor: '',
  },
  {
    title: 'VED6',
    url: 'http://app-bandplay-ctv-ved6.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: 'On hold',
    borderColor: '',
  },
  {
    title: 'VED7',
    url: 'http://app-bandplay-ctv-ved7.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: 'On hold',
    borderColor: '',
  },
  {
    title: 'VED8',
    url: 'http://app-bandplay-ctv-ved8.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: 'On hold',
    borderColor: '',
  },
  {
    title: 'VED9',
    url: 'http://app-bandplay-ctv-ved9.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: 'On hold',
    borderColor: '',
  },
  {
    title: 'VED10',
    url: 'http://app-bandplay-ctv-ved10.ambitiousdune-7615a25b.brazilsouth.azurecontainerapps.io',
    description: 'On hold',
    borderColor: '',
  },
];
