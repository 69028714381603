import { HStack, Pressable, Text, VStack, View, styled } from '@gluestack-ui/themed';
import { Text as NativeText } from 'react-native';
import Animated from 'react-native-reanimated';
import theme from 'theme';

import { useCollapseWithAnimation } from 'components/Collapse/animation';
import { Icons } from 'components/Icons';

export type CollapseProps = { title: string; content: string };
const Box = styled(Animated.View, {});

const Collapse: React.FC<CollapseProps> = ({ title, content }) => {
  const { animatedContainerStyle, isOpen, toggleCollapse, contentRef } = useCollapseWithAnimation();
  const headerTransparency = isOpen ? 25 : 10;

  return (
    <VStack gap="$2" px="$4">
      <Pressable
        py="$4"
        px="$4"
        rounded={'$md'}
        onPress={toggleCollapse}
        backgroundColor={`${theme.colors.base.white}${headerTransparency}`}>
        <HStack alignItems="center" justifyContent="space-between" gap="$1">
          <Text flex={1} color={theme.colors.base.white}>
            {title}
          </Text>

          {isOpen ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
        </HStack>
      </Pressable>

      <Box
        px="$4"
        py="$6"
        rounded="$md"
        elevation={10}
        borderWidth="$1"
        ref={contentRef}
        shadowColor="#fff"
        bg={theme.colors.base.darkBlue}
        style={[animatedContainerStyle]}
        borderColor={theme.colors.base.blue}>
        <Text color={theme.colors.base.white}>{content}</Text>
      </Box>
    </VStack>
  );
};

export default Collapse;
