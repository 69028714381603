import Constants from 'expo-constants';
import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Socket, io } from 'socket.io-client';
import { RootState } from 'store/index';

type SocketWatchCallback = { watchedTime: number };

type SocketContextProps = {
  socket: Socket;
  watch: (data: PlayerProps) => Promise<SocketWatchCallback | undefined>;
};

const SocketContext = createContext({} as SocketContextProps);

export const SocketProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const auth = useSelector((state: RootState) => state.auth);

  const state = useMemo(() => {
    const socket: Socket = io(Constants.expoConfig?.extra?.socketUrl, {
      autoConnect: false,
      transports: ['websocket'],
      auth: {
        token: auth.userToken,
      },
    });

    return {
      socket,
      async watch({ media }: PlayerProps) {
        return media
          ? await Promise.race<SocketWatchCallback>([
              new Promise<SocketWatchCallback>((resolve) => {
                socket.connect().emit(
                  'watch',
                  {
                    title: media.title,
                    image: media.image,
                    rating: media.ratingValue,
                    duration: media.duration,
                    trackId: media.videoTracks[0].trackId,
                    programId: media.programId,
                    programTitle: media.programTitle,
                    programUrl: media.programUrl,
                    mediaId: media.mediaId,
                  },
                  resolve
                );
              }),
              new Promise((resolve) => setTimeout(resolve, 2000)),
            ])
          : undefined;
      },
    };
  }, [auth]);

  return <SocketContext.Provider value={state}>{children}</SocketContext.Provider>;
};

export const useSocket = () => useContext(SocketContext);
