import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowDown: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11.7258 3.99966C11.3461 3.99966 11.0323 4.28182 10.9827 4.64789L10.9758 4.74966L10.9758 19.7497C10.9758 20.1639 11.3116 20.4997 11.7258 20.4997C12.1055 20.4997 12.4193 20.2175 12.469 19.8514L12.4758 19.7497L12.4758 4.74966C12.4758 4.33545 12.14 3.99966 11.7258 3.99966Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M6.23302 13.1709C5.94076 12.8774 5.46589 12.8764 5.17236 13.1687C4.90552 13.4344 4.88042 13.851 5.09764 14.145L5.17008 14.2293L11.1941 20.2793C11.4606 20.547 11.8787 20.5713 12.1728 20.3524L12.257 20.2794L18.282 14.2294C18.5743 13.9359 18.5733 13.461 18.2798 13.1687C18.013 12.903 17.5963 12.8796 17.3031 13.0981L17.2191 13.1709L11.7252 18.6869L6.23302 13.1709Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default ArrowDown;
