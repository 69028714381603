import { View } from '@gluestack-ui/themed';
import { SCREEN_HEIGHT, useBottomSheet } from 'context/BottomSheetContext';
import { getDeviceTypeAsync } from 'expo-device';
import React, { useEffect, useState } from 'react';
import { Platform } from 'utils/platform';

import LiveMenu from './LiveMenu';
import { LiveSheet } from './LiveSheet';

type LiveMenuProviderProps = {
  children: React.ReactNode;
  data?: LiveMenuItems[];
};

const LiveMenuProvider: React.FC<LiveMenuProviderProps> = ({ children }) => {
  const [isSmallDevice, setIsMobile] = useState(false);
  const { liveMenuItems } = useBottomSheet();

  useEffect(() => {
    getDeviceTypeAsync().then(async (deviceType: any) => {
      setIsMobile(deviceType === 1 || deviceType === 2);
    });
  }, []);

  const content = (
    <>
      {children}
      <LiveSheet />
    </>
  );

  if (!isSmallDevice || liveMenuItems.length <= 0) {
    return children;
  }

  return Platform.OS === 'web' ? (
    <View style={{ height: '100%', maxHeight: SCREEN_HEIGHT, overflow: 'hidden', zIndex: 3 }}>
      {content}
      <LiveMenu />
    </View>
  ) : (
    <>
      {content}
      <LiveMenu />
    </>
  );
};

export default LiveMenuProvider;
