/*

Referência:

https://github.com/flowplayer/flowplayer-plugin-example/blob/main/snapshot-plugin.ts
https://github.com/flowplayer/flowplayer-vod-quality-selector/blob/master/flowplayer.vod-quality-selector.js
https://github.com/flowplayer/flowplayer-overlay

*/
import { ConfigWith, FlowplayerUMD, Player, PlayerRoot } from '@flowplayer/player';
import { CONFIG } from '@flowplayer/player/core/events';
import { Plugin } from '@flowplayer/player/plugin';

export type FlowplayerASelConfig = ConfigWith<{
  asel: {
    header?: string;
    tracks?: [{ id: string; title: string; selected?: boolean }];
  };
}>;

export default class ASelPlugin implements Plugin {
  container?: PlayerRoot;
  ol?: HTMLOListElement | null;
  summary?: HTMLElement | null;
  header?: HTMLDivElement | null;
  headerTimer?: ReturnType<typeof setTimeout>;
  isShowingControls?: boolean;

  constructor(private umd: FlowplayerUMD, private player: Player) {}

  init(config: FlowplayerASelConfig, container: PlayerRoot, player: Player) {
    this.container = container;

    this.render();

    let prevHeader = '';

    this.player.on<FlowplayerASelConfig>(CONFIG, ({ detail }) => {
      const { asel = {} } = detail;

      this.summary!.innerHTML = '';
      this.ol!.innerHTML = '';

      if (asel.header && prevHeader != asel.header) {
        this.setHeader(asel.header);
        prevHeader = asel.header;
      }

      if (!asel.tracks || asel.tracks.length <= 1) {
        return;
      }

      for (const item of asel.tracks) {
        const li = document.createElement('li');
        li.role = 'menuitem';
        li.innerHTML = `<span>${item.title}</span>`;

        li.addEventListener(
          'click',
          () => {
            this.player.emit('asel:id', item.id);
          },
          false
        );

        if (item.selected) {
          this.summary!.innerHTML = item.title;
          li.className = 'fp-on';
        }

        this.ol?.append(li);
      }
    });

    this.player.on<FlowplayerASelConfig>('state', ({ detail }) => {
      this.isShowingControls = detail['is-hovered'] || detail['is-paused'];
      if (!this.header || this.header.hasAttribute('locked')) {
        return;
      }
      this.header.style.display = this.isShowingControls ? 'block' : 'none';
    });
  }

  setHeader(content: string) {
    if (this.headerTimer) {
      clearTimeout(this.headerTimer);
    }

    this.header!.innerHTML = content;
    this.header!.style.display = 'block';
    this.header?.setAttribute('locked', 'true');

    this.headerTimer = setTimeout(() => {
      this.header?.removeAttribute('locked');
      if (!this.isShowingControls) {
        this.header!.style.display = 'none';
      }
    }, 5000);
  }

  render() {
    const div = document.createElement('div');
    div.innerHTML = `
      <div>
        <details class="fp-menu-container">
          <summary role="button"></summary>
          <div class="fp-menu">
            <h3>Áudio</h3>
            <span class="fp-close">×</span>
            <ol role="menu">
            </ol>
          </div>
        </details>
      </div>
    `;

    this.container?.querySelector('.fp-controls')?.append(div);
    this.summary = div.querySelector('summary');
    this.ol = div.querySelector('ol');

    this.header = document.createElement('div');
    this.container?.querySelector('.fp-header .fp-left')?.append(this.header);
  }
}
