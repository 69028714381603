import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const Screencast: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M3.01562 9.19443V8.32869C3.01562 5.73148 4.7471 4 7.34432 4H16.0017C18.5989 4 20.3304 5.73148 20.3304 8.32869V15.2546C20.3304 17.8518 18.5989 19.5833 16.0017 19.5833H15.136"
      stroke={color || '#FFF'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4.48041 11.541C8.48012 12.0518 11.415 14.9953 11.9344 18.995M3.55407 14.4499C6.48893 14.8222 8.64461 16.9865 9.02554 19.9214M3 17.731C4.4631 17.9215 5.54527 18.995 5.73573 20.4668"
      stroke={color || '#FFF'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Screencast;
