import { View, useBreakpointValue } from '@gluestack-ui/themed';
import { useMemo } from 'react';
import { useGetHelpQuery } from 'services/cms';
import { logHelpPageViewEvent } from 'utils/Help/pageview';
import { chunkArrayIntoN } from 'utils/array';

import CollapseGroup from 'components/Collapse/CollapseGroup';
import HelpPageLayout from 'components/Help/HelpPageLayout';
import IsLoading from 'components/isLoading';

const FAQSectionScreen: React.FC = () => {
  logHelpPageViewEvent('perguntas frequentes');
  const { data, isLoading } = useGetHelpQuery();

  const contentGap = useBreakpointValue({ xl: 0, lg: 0, base: '$2' });
  const viewDirection = useBreakpointValue({ xl: 'row', lg: 'row', base: 'column' });

  const items = useMemo(() => {
    if (!data?.faq?.faqItems) return [];
    return data.faq.faqItems.map((item) => ({ title: item.title, content: item.description }));
  }, [data]);

  if (isLoading) {
    return (
      <View flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </View>
    );
  }

  if (!data) {
    // TODO: definir comportamento ao receber erros da API
    return null;
  }

  return (
    <HelpPageLayout title={data.faq.title}>
      <View flexDirection={viewDirection} gap={contentGap}>
        {chunkArrayIntoN(items, 3).map((chunk) => (
          <CollapseGroup items={chunk} />
        ))}
      </View>
    </HelpPageLayout>
  );
};

export default FAQSectionScreen;
