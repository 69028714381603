import { Pressable, Text } from '@gluestack-ui/themed';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import theme from 'theme';
import { isMobileOrSmallScreen } from 'utils/deviceInfo';

import { DropDownTriggerProps } from 'components/DropDown/interface';
import { Icons } from 'components/Icons';

const DropDownTrigger: React.FC<DropDownTriggerProps> = (props) => {
  const { width } = useWindowDimensions();

  return (
    <Pressable
      px={'$6'}
      py={'$3'}
      mb={isMobileOrSmallScreen(width) ? '$0' : '$5'}
      width={isMobileOrSmallScreen(width) ? '$full' : '20%'}
      rounded={'$md'}
      borderWidth={'$2'}
      flexDirection={'row'}
      disabled={props.disabled}
      justifyContent={'space-between'}
      borderColor={theme.colors.base.blue}
      backgroundColor={theme.colors.base.darkBlue}
      {...props.triggerProps}>
      <Text color="white" fontSize={16}>
        {props.label}
      </Text>
      <Icons.ChevronDown color={theme.colors.base.blue} />
    </Pressable>
  );
};

export default DropDownTrigger;
