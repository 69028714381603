import styled from 'styled-components/native';
import theme from 'theme';

export const PersistentControlContainer = styled.View`
  width: 100%;
  display: flex;
  background-color: ${theme.colors.base.white};
  z-index: 1;
  height: 61px;
`;

export const PersistentControlImage = styled.Image`
  height: 100%;
  width: 100px;
`;

export const PersistentControlImageOverlay = styled.View`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const PersistentControlTitle = styled.Text`
  display: flex;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.inter.regular};
  margin: 0 10px;
  color: ${theme.colors.base.black};
`;

export const PersistenControlTitleContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
`;

export const PersistenControlButtonContainer = styled.View`
  display: flex;
  margin: 0 10px;
  flex-direction: row;
  gap: 12px;
  width: 100px;
`;
