import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SolidCheck: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M9.15858 18C8.75838 18 8.37819 17.8549 8.09805 17.601L2.43522 12.4681C1.85493 11.9421 1.85493 11.0715 2.43522 10.5455C3.01551 10.0195 3.97599 10.0195 4.55628 10.5455L9.15858 14.7171L19.4437 5.39449C20.024 4.8685 20.9845 4.8685 21.5648 5.39449C22.1451 5.92047 22.1451 6.79107 21.5648 7.31706L10.2191 17.601C9.93897 17.8549 9.55878 18 9.15858 18Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default SolidCheck;
