/* eslint-disable import/order */
import { Box, Text, useBreakpointValue } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import withLink from 'hoc/withLink';
import { useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import FastImage from 'components/FastImage';
import { Icons } from 'components/Icons';

const BoxedMidCard: React.FC<CardProps & { isSelected?: boolean; percentageWatched?: number }> = ({
  uri,
  title,
  description,
  onPress,
  duration,
  percentageWatched,
  lines,
  isSelected,
}) => {
  const midBoxedCardWidth = useBreakpointValue({
    base: Platform.isAndroidTV ? 112.5 : 150,
    lg: calcSizeForDevice(150, 225),
  });

  const midBoxedCardHeight = useMemo(
    () => calcHeightWithWidth(midBoxedCardWidth, 3 / 2),
    [midBoxedCardWidth]
  );

  return (
    <Button
      useTVFocus
      variant="invisible"
      role="link"
      onPress={onPress}
      style={{ flexDirection: 'row', flex: 1, height: undefined }}>
      {Boolean(uri) && (
        <FastImage
          source={{ uri }}
          style={{
            borderRadius: 5,
            overflow: 'hidden',
            position: 'relative',
            width: midBoxedCardWidth,
            height: midBoxedCardHeight,
          }}>
          <LinearGradient
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
            locations={[0.1, 0.95]}
            style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
            colors={['#000000', 'transparent']}>
            <Box
              flexDirection="row"
              position="absolute"
              bottom={calcSizeForDevice(18)}
              right={calcSizeForDevice(5)}
              backgroundColor="#33333399"
              paddingHorizontal={calcSizeForDevice(6)}
              borderRadius={6}
              alignItems="center">
              {Boolean(duration) && (
                <Text
                  color="white"
                  fontWeight="700"
                  mr={calcSizeForDevice(6)}
                  fontSize={calcSizeForDevice(12, 14)}>
                  {duration}
                </Text>
              )}
              <Icons.CirclePlay
                width={calcSizeForDevice(16).toString()}
                height={calcSizeForDevice(16).toString()}
              />
            </Box>
            {Boolean(percentageWatched) && (
              <Box w="$full" paddingHorizontal={calcSizeForDevice(8)} mt="auto" position="relative">
                <Box
                  backgroundColor="rgba(255, 255, 255, 0.5)"
                  overflow="hidden"
                  borderBottomLeftRadius={calcSizeForDevice(3)}
                  borderBottomRightRadius={calcSizeForDevice(3)}
                  w="$full"
                  h={calcSizeForDevice(4)}
                  mb={calcSizeForDevice(8)}>
                  <Box
                    backgroundColor="rgba(255, 255, 255, 1)"
                    borderBottomLeftRadius={calcSizeForDevice(3)}
                    w={`${percentageWatched!}%`}
                    h={calcSizeForDevice(4)}
                  />
                </Box>
              </Box>
            )}
          </LinearGradient>
        </FastImage>
      )}
      <Box flexDirection="column" flex={3} ml={calcSizeForDevice(20)}>
        <Box mb={calcSizeForDevice(8)}>
          {Boolean(title) && (
            <Text
              color="white"
              fontWeight="600"
              fontSize={calcSizeForDevice(14, 20)}
              lineHeight={calcSizeForDevice(20, 24)}
              numberOfLines={lines?.title}>
              {title}
            </Text>
          )}
          {isSelected && Platform.isTV && (
            <Box mt={calcSizeForDevice(5)}>
              <Icons.Streaming
                width={calcSizeForDevice(32).toString()}
                height={calcSizeForDevice(32).toString()}
              />
            </Box>
          )}
        </Box>
        {Boolean(description) && (
          <Text
            color="#7D7D7D"
            fontSize={calcSizeForDevice(12, 18)}
            lineHeight={calcSizeForDevice(15, 24)}
            numberOfLines={lines?.description}>
            {description}
          </Text>
        )}
      </Box>
    </Button>
  );
};

export default withLink(BoxedMidCard, 'BoxedMidCard');
