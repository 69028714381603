import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

const Notify: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="175"
    height="175"
    viewBox="0 0 175 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M131.25 58.333C131.25 46.7298 126.641 35.6018 118.436 27.3971C110.231 19.1924 99.1032 14.583 87.5 14.583C75.8968 14.583 64.7688 19.1924 56.5641 27.3971C48.3594 35.6018 43.75 46.7298 43.75 58.333C43.75 109.375 21.875 123.958 21.875 123.958H153.125C153.125 123.958 131.25 109.375 131.25 58.333Z"
      stroke="#7A8CFF"
      stroke-width="11"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Rect x="109.375" y="94.4727" width="58.3333" height="58.3333" rx="29.1667" fill="#FF0000" />
    <Path
      d="M100.116 153.125C98.8339 155.335 96.9939 157.169 94.78 158.444C92.5662 159.72 90.0561 160.391 87.5013 160.391C84.9464 160.391 82.4364 159.72 80.2226 158.444C78.0087 157.169 76.1687 155.335 74.8867 153.125"
      stroke="#7A8CFF"
      stroke-width="11"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default Notify;
