import { useIsFocused } from '@react-navigation/native';
import { PropsWithChildren, forwardRef } from 'react';
import { View } from 'react-native';
import { Platform } from 'utils/platform';

type WebDivProps = PropsWithChildren<{ className?: string; style?: any }>;

export const WebDiv = forwardRef<HTMLDivElement, WebDivProps>((props, ref) => {
  const isFocused = useIsFocused();
  const { className, style, children } = props;
  if (!Platform.isWebTV) return <View style={style}>{children}</View>;
  if (!isFocused) return null;

  return (
    <div ref={ref} style={style} className={className}>
      {children}
    </div>
  );
});
