import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const FaceGrinWide: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M15.5 10.5C14.26 10.5 13.25 9.49 13.25 8.25C13.25 7.01 14.26 6 15.5 6C16.74 6 17.75 7.01 17.75 8.25C17.75 9.49 16.74 10.5 15.5 10.5ZM15.5 7.5C15.09 7.5 14.75 7.84 14.75 8.25C14.75 8.66 15.09 9 15.5 9C15.91 9 16.25 8.66 16.25 8.25C16.25 7.84 15.91 7.5 15.5 7.5Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M8.5 10.5C7.26 10.5 6.25 9.49 6.25 8.25C6.25 7.01 7.26 6 8.5 6C9.74 6 10.75 7.01 10.75 8.25C10.75 9.49 9.74 10.5 8.5 10.5ZM8.5 7.5C8.09 7.5 7.75 7.84 7.75 8.25C7.75 8.66 8.09 9 8.5 9C8.91 9 9.25 8.66 9.25 8.25C9.25 7.84 8.91 7.5 8.5 7.5Z"
      fill={color || '#FFF'}
    />
    <Path
      d="M12 19.4508C9.1 19.4508 6.75 17.0908 6.75 14.2008C6.75 13.2908 7.49 12.5508 8.4 12.5508H15.6C16.51 12.5508 17.25 13.2908 17.25 14.2008C17.25 17.0908 14.9 19.4508 12 19.4508ZM8.4 14.0508C8.32 14.0508 8.25 14.1208 8.25 14.2008C8.25 16.2708 9.93 17.9508 12 17.9508C14.07 17.9508 15.75 16.2708 15.75 14.2008C15.75 14.1208 15.68 14.0508 15.6 14.0508H8.4Z"
      fill={color || '#FFF'}
    />
  </Svg>
);

export default FaceGrinWide;
