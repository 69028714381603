/* eslint-disable import/order */
import {
  Divider,
  Icon,
  Menu,
  MenuItem,
  MenuItemLabel,
  Pressable,
  ScrollView,
} from '@gluestack-ui/themed';
import { Platform } from 'utils/platform';

import { useVideoPlayer } from '../context';

import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';

const languages: Record<string, string> = {
  dub: 'Dublado',
  origin: 'Original',
  en: 'Inglês',
  pt: 'Português',
  fr: 'Francês',
  es: 'Espanhol',
};

const CastSettingsButton = () => {
  const { showSettings, closeSettings } = useVideoPlayer();
  const { media, trackId, textTracks, textTrack, setTextTrack, setTrack, isFullScreen } =
    usePlayer();

  return (
    <Menu
      as={ScrollView}
      isOpen={showSettings}
      onClose={closeSettings}
      defaultIsOpen={showSettings}
      bg="#15161C"
      mt={5}
      mr={showSettings ? (isFullScreen ? 5 : 20) : 0}
      placement={isFullScreen ? 'bottom right' : 'bottom left'}
      px={10}
      width="$0"
      shadowColor={Platform.isAndroidTV ? '$backgroundDark900' : '$backgroundDark500'}
      trigger={(triggerProps) => {
        return (
          <Pressable p={1.5} _pressed={{ bg: 'none' }} {...triggerProps}>
            <Icons.CastClosedCaption width="24" height="24" color="black" />
          </Pressable>
        );
      }}>
      <MenuItem disabled key="tituloAudio" textValue="tituloAudio">
        <Icon as={Icons.SquareMusic} size="sm" mr="$4" />
        <MenuItemLabel color="white">Áudio</MenuItemLabel>
      </MenuItem>

      {media?.videoTracks?.map((item, index) => (
        <MenuItem
          flexDirection="row"
          alignItems="center"
          textValue="dublagens"
          key={index + Math.random()}
          justifyContent="space-between"
          onPress={() => setTrack(index)}>
          <MenuItemLabel
            fontWeight={trackId === item.trackId ? 'bold' : 'normal'}
            color={trackId === item.trackId ? '#fff' : '#696B8C'}
            fontSize={14}>
            {languages[item.language]}
          </MenuItemLabel>
          {trackId === item.trackId && <Icons.SolidCheck width="12" height="12" />}
        </MenuItem>
      ))}

      <MenuItem disabled>
        <Divider />
      </MenuItem>

      <MenuItem disabled key="tituloLegenda" textValue="tituloLegenda">
        <Icon as={Icons.Subtitles} size="sm" mr="$4" />
        <MenuItemLabel aria-label="Titulo Legendas" color="$white" size="sm">
          Legenda
        </MenuItemLabel>
      </MenuItem>

      {textTracks?.length !== 0 && (
        <MenuItem
          w="$full"
          alignItems="center"
          justifyContent="space-between"
          onPress={() => setTextTrack(undefined)}
          key="Desativar Legenda"
          textValue="Desativar Legenda">
          <MenuItemLabel
            fontWeight={!textTrack ? 'bold' : 'normal'}
            color={!textTrack ? '$white' : '#696B8C'}
            fontSize={14}>
            Desativar Legenda
          </MenuItemLabel>
          {!textTrack && <Icons.SolidCheck width="12" height="12" />}
        </MenuItem>
      )}

      {textTracks?.map((item, index) => (
        <MenuItem
          key={index + Math.random()}
          onPress={() => setTextTrack(item)}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          w="$full"
          textValue="Legendas">
          <MenuItemLabel
            aria-label="Legendas"
            fontWeight={textTrack === item.language ? 'bold' : 'normal'}
            color={textTrack === item.language ? '$white' : '#696B8C'}
            fontSize={14}>
            {languages[item.language]}
          </MenuItemLabel>
          {textTrack === item.language && <Icons.SolidCheck width="12" height="12" />}
        </MenuItem>
      ))}

      {textTracks?.length === 0 && (
        <MenuItem disabled key="Settings" textValue="Sem Legendas">
          <MenuItemLabel textDecorationLine="line-through" color="$white">
            Sem Legendas
          </MenuItemLabel>
        </MenuItem>
      )}
    </Menu>
  );
};

export default CastSettingsButton;
