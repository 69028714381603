/* eslint-disable import/order */
import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { FlatList } from 'react-native';
import { AttachStep } from 'react-native-spotlight-tour';
import useFirebaseAnalytics from 'services/analytics';
import { Platform } from 'utils/platform';

import { useHorizontalPagination } from '../helpers/pagination';
import SectionArrows from './SectionArrows';
import SectionHorizontalSkeleton from './SectionHorizontalSkeleton';

import FocusView from 'components/FocusView';

type SectionHorizontalProps = CmsHomeSection & {
  onEndReached?: () => void;
  requestTVFocus?: boolean;
};

const SectionHorizontal: React.FC<SectionHorizontalProps> = (props) => {
  const { logEventActionName } = useFirebaseAnalytics();
  const { ref, onScroll, goForward, renderItem, goBackward, canGoForward, canGoBackward } =
    useHorizontalPagination({
      renderItem: props.renderItem,
      items: props.items,
    });

  if (props.loading) {
    return <SectionHorizontalSkeleton {...props} />;
  }

  if (!props.items || !props.items.length) {
    return null;
  }

  const list = (
    <FocusView>
      <FlatList
        ref={ref}
        focusable={false}
        data={props.items}
        onScroll={onScroll}
        maxToRenderPerBatch={3}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        horizontal={props.variant !== 'section.boxed'}
        keyExtractor={(item, index) => `${item.link}_${index.toString()}`}
      />
    </FocusView>
  );

  const onPress = (side: 'left' | 'right') => {
    side === 'left' ? goBackward() : goForward();

    logEventActionName(
      {
        component: 'SectionArrows',
        reference: props.title,
      },
      'scroll_lateral'
    );
  };

  return (
    <Box position="relative" focusable={false}>
      {props.items.some((item) => item.reference === 'Ao Vivo') ? (
        <AttachStep
          index={2}
          children={
            <Box borderWidth={1} borderColor="transparent">
              {list}
            </Box>
          }
        />
      ) : (
        list
      )}

      {!Platform.isTV && (
        <SectionArrows side="left" onPress={() => onPress('left')} isVisible={canGoBackward} />
      )}

      {!Platform.isTV && (
        <SectionArrows side="right" onPress={() => onPress('right')} isVisible={canGoForward} />
      )}
    </Box>
  );
};

export default SectionHorizontal;
