import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

const Number02: React.FC<SvgProps & { innerColor?: string }> = ({
  color,
  innerColor,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 495 638.5" {...props}>
    <Path
      fill={innerColor || 'none'}
      d="M10.4,638v-121.6l235.7-191.4c12.6-10,25.2-20.7,37.6-31.7,12.4-11,22.7-23.3,30.9-36.8,8.2-13.5,12.3-29.2,12.3-46.7s-3.4-28.6-10-39.9c-6.6-11.3-15.9-20.2-27.6-26.6-11.7-6.3-25.6-9.6-41.4-9.6s-31.9,3.9-44.6,11.5c-12.7,7.6-22.7,18.8-29.7,33.3-7,14.4-10.5,32.1-10.6,52.7H2.8c0-47.3,10.1-88.7,29.9-122.9,19.8-34.4,48.4-61.2,84.8-79.8C154,9.9,197.9.5,247.9.5s89.1,8.1,125.3,24c36.2,15.9,64.9,39,85.2,68.6,20.4,29.6,30.7,65.3,30.7,106.1s-5.7,56.5-17,80.6c-11.3,24.2-28.3,48.1-50.5,71.2-22.3,23.1-49.9,48.1-82,74.4l-92.2,75.3v2.4h244.8v134.9H10.4Z"
    />
    <Path
      fill={color || 'white'}
      d="M247.9,1c46.9,0,89,8.1,125.1,24,36.1,15.9,64.7,38.9,85,68.4,20.3,29.5,30.6,65.1,30.6,105.8s-5.7,56.3-17,80.4c-11.3,24.1-28.3,48-50.5,71-22.2,23.1-49.8,48.1-82,74.4l-92,75.1-.4.3v3.2h244.8v133.9H10.9v-120.9l235.5-191.3c12.6-10.1,25.2-20.7,37.6-31.7,12.4-11,22.8-23.4,31-36.9,8.2-13.6,12.4-29.4,12.4-46.9s-3.4-28.8-10.1-40.2c-6.7-11.4-16-20.4-27.8-26.7-11.8-6.4-25.8-9.6-41.6-9.6s-32.1,3.9-44.8,11.5c-12.8,7.7-22.8,19-29.9,33.5-7,14.3-10.5,31.9-10.6,52.4H3.3c.1-47,10.2-88.1,29.8-122.1,19.8-34.3,48.2-61,84.6-79.6C154.2,10.4,198,1,247.9,1M247.9,0C197.6,0,154,9.4,117.3,28.1c-36.7,18.7-65.1,45.4-85,80C12.3,142.6,2.3,183.9,2.3,231.7h161.2c0-20.8,3.5-38.5,10.6-53,7-14.5,16.9-25.5,29.5-33.1,12.7-7.6,27.4-11.4,44.3-11.4s29.5,3.2,41.1,9.5c11.7,6.3,20.8,15.1,27.4,26.4,6.6,11.3,9.9,24.5,9.9,39.7s-4.1,32.9-12.2,46.4c-8.2,13.5-18.4,25.7-30.8,36.7-12.4,11-24.9,21.5-37.6,31.6L9.9,516.1v122.4h482.8v-135.9h-244.8v-1.7l92-75.1c32.3-26.4,59.7-51.3,82.1-74.5,22.4-23.2,39.2-47,50.6-71.3,11.4-24.3,17.1-51.3,17.1-80.8s-10.3-76.5-30.8-106.3c-20.5-29.8-49-52.8-85.5-68.8C337,8,295.2,0,247.9,0h0Z"
    />
    <Path
      fill={color || 'white'}
      d="M495,638.5H7.4v-133.3l232.2-188.6c12.1-9.7,24.2-19.9,36.1-30.4,11.2-9.9,20.6-21.1,27.9-33.3,6.9-11.4,10.4-24.8,10.4-39.8s-2.8-24.1-8.2-33.4c-5.5-9.4-12.9-16.5-22.7-21.8-9.8-5.3-21.6-8-35.1-8s-27.1,3.2-37.8,9.6c-10.7,6.4-18.8,15.5-24.8,27.9-6.1,12.6-9.2,28.4-9.2,46.9v10H0v-10c0-47.9,10.2-89.9,30.4-124.8,20.2-35.1,49.3-62.4,86.5-81.4C153.7,9.5,197.8,0,247.9,0s89.2,8.1,125.6,24.1c36.8,16.2,66.1,39.8,87,70.1,21,30.5,31.6,67,31.6,108.7s-5.9,57.7-17.5,82.5c-11.5,24.5-28.6,48.6-50.9,71.8-21.8,22.7-48.9,47.2-80.4,73l-69.4,56.7h221v151.6ZM27.4,618.5h447.7v-111.6h-237.1v-16.4l92.8-75.8c30.9-25.2,57.3-49.2,78.6-71.3,20.8-21.6,36.7-43.9,47.2-66.4,10.4-22.1,15.6-47,15.6-74s-9.4-70.3-28.1-97.3c-18.7-27.2-45.2-48.4-78.6-63.2-33.9-14.9-73.4-22.5-117.6-22.5s-88,8.8-122,26.1c-33.7,17.2-60,41.9-78.2,73.6-17,29.4-26.2,64.6-27.5,104.8h136.3c1-17.4,4.7-32.7,10.9-45.7,7.6-15.7,18.6-28,32.5-36.3,13.8-8.3,30-12.5,48.1-12.5s31.9,3.5,44.6,10.4c12.9,7,23.1,16.8,30.4,29.3,7.3,12.4,11,27.1,11,43.5s-4.5,35.5-13.3,50.1c-8.4,13.9-19.1,26.6-31.8,37.9-12.1,10.7-24.5,21.2-36.8,31L27.4,514.7v103.8Z"
    />
  </Svg>
);

export default Number02;
