import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const CastPauseSolid: React.FC<SvgProps> = ({ color, ...props }) => (
  <Svg viewBox="0 0 320 512" {...props}>
    <Path d="M48 64C21.5 64 0 85.5 0 112L0 400c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48L48 64zm192 0c-26.5 0-48 21.5-48 48l0 288c0 26.5 21.5 48 48 48l32 0c26.5 0 48-21.5 48-48l0-288c0-26.5-21.5-48-48-48l-32 0z" />
  </Svg>
);

export default CastPauseSolid;
