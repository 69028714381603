import { View } from '@gluestack-ui/themed';
import { useGlobalConfig } from 'context/GlobalContext';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useRef } from 'react';
import { Image as RNImage, ScrollView, SectionList, useWindowDimensions } from 'react-native';
import theme from 'theme';
import { ProgramType } from 'utils/enum';

import { HeroHeaderProps, HeroProgramProps } from '.';

const getImageCrop = (image: string, crop: string) => {
  if (crop && image && image.includes('/img.band') && !image.includes('/s/')) {
    return image.replace(/(_\d+x\d+)?.(png|webp|jpe?g)$/, `_${crop}.webp`);
  }

  return image;
};

const aspectRatio = 0.85;

const HeroNative: React.FC<HeroProgramProps> = ({
  children,
  program,
  hideCover,
  numItems = 0,
  renderItem,
}) => {
  const scrollRef = useRef<typeof SectionList>(null);
  const sections = Array.from({ length: numItems }).map(() => ({ data: [null] }));
  const { colors } = useGlobalConfig();

  const dynamicCmsColors = program?.ads?.remove_mask
    ? ['transparent', 'transparent']
    : ['transparent', colors];

  return (
    <View flex={1} backgroundColor={colors || theme.colors.base.darkBlue}>
      <RNImage
        source={{
          uri: program?.imageBg?.url
            ? getImageCrop(program?.imageBg?.url ?? '', '300x400')
            : program?.imageVertical?.url,
        }}
        style={{
          display: hideCover ? 'none' : undefined,
          width: '100%',
          aspectRatio: 0.75,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <SectionList
        ref={scrollRef}
        style={{ flex: 1 }}
        sections={sections}
        keyExtractor={(_, index) => index.toString()}
        renderSectionHeader={renderItem}
        renderItem={() => null}
        stickySectionHeadersEnabled={false}
        ListHeaderComponent={
          <>
            <View
              style={{
                display: hideCover ? 'none' : undefined,
                width: '100%',
                aspectRatio,
              }}>
              {colors ? (
                <LinearGradient
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0, y: 1 }}
                  locations={[0.9, 1]}
                  tvParallaxShiftDistanceX={10}
                  colors={dynamicCmsColors}
                  style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                <LinearGradient
                  start={{ x: 0, y: 0.3 }}
                  end={{ x: 0, y: 0.9 }}
                  tvParallaxShiftDistanceX={10}
                  colors={['transparent', theme.colors.base.darkBlue]}
                  style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </View>
            <View px="$4" backgroundColor={colors || theme.colors.base.darkBlue}>
              {children}
            </View>
          </>
        }
      />
    </View>
  );
};

const HeroHeaderNative: React.FC<HeroHeaderProps> = ({
  children,
  program,
  mediaId,
  isVideoOpen,
}) => {
  const { width } = useWindowDimensions();

  const isRadioOrPodcast = program.type == ProgramType.RADIO || program.type == ProgramType.PODCAST;
  const radioOrPodcastOffset = width * aspectRatio * -1;
  const othersOffset = mediaId ? 10 : width * aspectRatio * -0.1;
  const marginTopOffset = isRadioOrPodcast && !isVideoOpen ? radioOrPodcastOffset : othersOffset;

  return <ScrollView style={{ marginTop: marginTopOffset }}>{children}</ScrollView>;
};

export default Object.assign(HeroNative, { Header: HeroHeaderNative });
