import { Box, HStack, ScrollView, VStack } from '@gluestack-ui/themed';
import React, { ComponentProps } from 'react';
import { ImageProps, useWindowDimensions } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import FocusView from 'components/FocusView';
import HeroImage from 'components/Hero/HeroImage';
import { HeroHeaderProps, HeroProgramProps } from 'components/Hero/index';
import CarouselGradient from 'components/NewCarrousel/carouselGradient';

type RefHeroImage = ImageProps & HTMLElement;

const ScrollComponent = ({ children }: ComponentProps<typeof Box>) => {
  if (Platform.OS === 'web') return children;

  return <ScrollView>{children}</ScrollView>;
};

const HeroWeb: React.FC<HeroProgramProps> = ({ program, children, numItems = 0, renderItem }) => {
  const { width, height } = useWindowDimensions();
  const refImage = React.useRef<RefHeroImage>(null);
  const vstackSpaceForTv = Platform.isTV && width < 1920 ? 'sm' : '2xl';

  if (refImage.current) {
    const divElement = refImage.current.querySelector('div');
    divElement ? (divElement.style.backgroundPositionY = 'top') : null;
  }

  return (
    <FocusView>
      <Box position="absolute" w="$full" top="$0" left="$0" h={height} zIndex={-10}>
        <HeroImage
          program={program}
          title={program.title}
          source={program.imageBgHorizontal?.url! || program.imageHorizontal?.url!}
        />
        <CarouselGradient />
      </Box>

      <ScrollComponent>
        <Box
          height="$full"
          width="$full"
          position="relative"
          pt={calcSizeForDevice(150, 80)}
          px={calcSizeForDevice(64)}>
          <HStack w="$full" justifyContent="space-around" zIndex={1000}>
            <VStack w="$full" space={vstackSpaceForTv}>
              {children}
              {Array.from({ length: numItems }).map(() => renderItem?.())}
            </VStack>
          </HStack>
        </Box>
      </ScrollComponent>
    </FocusView>
  );
};

const HeroHeaderWeb: React.FC<HeroHeaderProps> = ({ children, Aside }) => {
  const { width } = useWindowDimensions();
  const vstackSpaceForTv = Platform.isTV && width < 1920 ? 'sm' : '2xl';

  return (
    <VStack space={vstackSpaceForTv} justifyContent="space-between">
      <HStack space={vstackSpaceForTv}>
        <Box sx={{ '@base': { width: '50%' }, '@xl': { width: '40%' } }}>{children}</Box>
        <Box flexGrow={1} alignItems="center" justifyContent="center">
          {Aside}
        </Box>
      </HStack>
    </VStack>
  );
};

export default Object.assign(HeroWeb, {
  Header: HeroHeaderWeb,
});
