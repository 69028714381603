import { Pressable, Text, VStack } from '@gluestack-ui/themed';
import { Linking } from 'react-native';
import theme from 'theme';
import { useToast } from 'utils/useToast';

import { Icons } from 'components/Icons';

type AvailableIcons =
  | 'instagram'
  | 'facebook'
  | 'twitter'
  | 'bluesky'
  | 'tiktok'
  | 'youtube'
  | 'email'
  | 'wpp';

const IconMap: Record<AvailableIcons, React.ReactNode> = {
  instagram: <Icons.Instagram />,
  facebook: <Icons.Facebook />,
  twitter: <Icons.XTwitter />,
  bluesky: <Icons.Bluesky />,
  tiktok: <Icons.Tiktok />,
  youtube: <Icons.Youtube />,
  email: <Icons.Envelope />,
  wpp: <Icons.Whatsapp />,
};

export type ContactBlockConfig = {
  icon: AvailableIcons;
  description?: string;
  title: string;
  link: string;
};

const ContactBlock: React.FC<ContactBlockConfig> = (props) => {
  const { icon, link, title, description } = props;
  const toast = useToast();

  const handleOnPress = () => {
    Linking.openURL(link).catch((e) => {
      console.error(e);

      return toast.show({
        mode: 'error',
        title: 'Ops 😢',
        description: 'Ocorreu um erro inexperado!',
      });
    });
  };

  if (!IconMap[icon] || !title || !link) return null;

  return (
    <Pressable flex={1} onPress={handleOnPress}>
      <VStack flex={1} gap="$1" bg={`${theme.colors.base.white}10`} rounded="$lg" py="$8" px="$4">
        {IconMap[icon]}
        {description && <Text color={theme.colors.base.white}>{description}</Text>}
        <Text fontWeight="bold" color={theme.colors.base.purple}>
          {title}
        </Text>
      </VStack>
    </Pressable>
  );
};

export default ContactBlock;
