import { registerRootComponent } from 'expo';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import 'utils/ports/keyboard';

import App from './App.web';

const firebaseConfig = {
  apiKey: 'AIzaSyBE0tpZ6Cd0dcSN0Tlf5GV3Gsq7FSt_2YA',
  authDomain: 'bandplay-oficial.firebaseapp.com',
  projectId: 'bandplay-oficial',
  storageBucket: 'bandplay-oficial.appspot.com',
  messagingSenderId: '1028401505084',
  appId: '1:1028401505084:web:accb1cf34c4ea0fbdbb439',
  measurementId: 'G-G3V4ZB38HT',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

registerRootComponent(App);
