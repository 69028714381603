const fonts = {
  inter: {
    thin: 'Inter_100Thin',
    regular: 'Inter_400Regular',
    bold: 'Inter_700Bold',
    black: 'Inter_900Black',
  },
};

const headingSizes = {
  h1: 0.8421,
  h2: 0.8181,
  h3: 0.7777,
  h4: 0.7142,
  h5: 0.75,
  h6: 0.8,
};

const fontSizes = {
  large: 2.25,
  medium: 1.75,
  small: 1.25,
  body: 1.125,
  paragraph: 1,
  text: 0.875,
  mini: 0.75,
  xs: 0.625,
};

const lineHeights = {
  normal: 'normal',
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: 2,
  '3': 0.75,
  '4': 1,
  '5': 1.25,
  '6': 1.5,
  '7': 1.75,
  '8': 2,
  '9': 2.25,
  '10': 2.5,
};

const letterSpacings = {
  tighter: -0.05,
  tight: -0.025,
  normal: 0,
  wide: 0.025,
  wider: 0.05,
  widest: 0.1,
};

const colors = {
  primary: {
    '100': '#C8DDFF',
    '200': '#A7B3FF',
    '300': '#7A8CFF',
    '400': '#4E66FF',
    '500': '#2240FF',
    '600': '#182DB3',
  },
  secondary: {
    '100': '#E2FFF2',
    '200': '#C5FFE4',
    '300': '#A7FFD7',
    '400': '#8AFFC9',
    '500': '#6DFFBC',
    '600': '#4CB384',
  },
  success: {
    '600': '#115B43',
    '500': '#1B6E53',
    '400': '#009262',
    '300': '#32C997',
    '200': '#84DFC1',
    '100': '#D1DBF8',
  },
  warning: {
    '600': '#E48900',
    '500': '#FFA826',
    '400': '#FFB240',
    '300': '#FFC670',
    '200': '#FFD596',
    '100': '#FFF8EC',
  },
  error: {
    '700': '#910808',
    '600': '#C33025',
    '500': '#E01507',
    '400': '#E02B1D',
    '300': '#FF5A4F',
    '200': '#F0857D',
    '100': '#FFF1F0',
  },
  gray: {
    '700': '#7D7D7D',
    '600': '#555555',
    '500': '#676767',
    '400': '#888888',
    '300': '#E4E4E4',
    '200': '#B5B5B5',
    '100': '#E2E2E2',
  },
  base: {
    white: '#FFFFFF',
    gray: '#808191',
    silver: 'F5F7FA',
    blue: '#2240FF',
    darkBlue: '#070D31',
    green: '#6DFFBC',
    button: '#FF7A8A',
    black: '#000000',
    backgroundTab: '#252836',
    purple: '#839CFF',
  },
};

const gradient_colors = {
  primary: ['#153c8e', '#194173', '#183954', '#142c44'],
  secondary: ['#19285e', '#1e2c5e', '#293360', '#353d60'],
};

const spacing = {
  s1: '4px',
  s2: '8px',
  s3: '12px',
  s4: '16px',
  s5: '20px',
  s6: '24px',
  s8: '32px',
  s10: '40px',
  s12: '48px',
  s16: '64px',
  s20: '80px',
  s24: '96px',
};

export default {
  fonts,
  fontSizes,
  lineHeights,
  letterSpacings,
  headingSizes,
  colors,
  spacing,
  gradient_colors,
};
