import { Box, HStack, Heading, Text, View } from '@gluestack-ui/themed';
import { useWindowDimensions } from 'react-native';
import theme from 'theme';
import { AgeRatingKey } from 'utils/ageRating';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice, hourFormatterBySeconds } from 'utils/index';
import { Platform } from 'utils/platform';

import { useProgram } from './context';

import Rating from 'components/Rating';

const ProgramHeader: React.FC = () => {
  const program = useProgram();

  const notShowOnLive = program?.type !== ProgramType.LIVE;
  const numSeasons = program.seasons?.length;
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 767;

  return (
    <View style={{ marginTop: ProgramType.PROGRAM === program?.type ? 10 : 0, marginBottom: 10 }}>
      {Platform.OS !== 'web' && !Platform.isTV ? (
        <View pt="$4">
          {program?.title && (
            <Heading color="$white" fontWeight="$bold" fontSize="$2xl">
              {program?.title}
            </Heading>
          )}

          <HStack my={4} alignItems="center">
            <Box flexDirection="row">
              {program?.genre?.[0].name && notShowOnLive && !program.hideSeasonsLabel && (
                <Text mr={3} fontWeight="bold" color={theme.colors.base.white}>
                  {numSeasons
                    ? `${numSeasons} TEMPORADA${numSeasons > 1 ? 'S' : ''}`
                    : program?.genre?.[0].name}
                </Text>
              )}
              {notShowOnLive && program?.year && (
                <Text mr={5} ml={8} fontWeight="bold" color={theme.colors.base.white}>
                  {program?.year}
                </Text>
              )}
              {notShowOnLive && program?.duration && (
                <Text mr={8} fontWeight="bold" color={theme.colors.base.white}>
                  {hourFormatterBySeconds(program?.duration)}
                </Text>
              )}
            </Box>

            {program?.rating && <Rating ageRatingKey={program?.rating as AgeRatingKey} />}
          </HStack>
          {program.rateDescription?.length > 0 && (
            <Text color="white" mb={20} fontFamily={theme.fonts.inter.regular}>
              {program.rateDescription}
            </Text>
          )}
        </View>
      ) : (
        <View flexDirection="column">
          <View mb={calcSizeForDevice(8)}>
            {program?.title && (
              <Heading
                sx={{
                  '@base': {
                    fontSize: calcSizeForDevice(32),
                  },
                  '@xs': {
                    fontSize: calcSizeForDevice(48, 64),
                    lineHeight: '$4xl',
                  },
                  '@lg': {
                    fontSize: calcSizeForDevice(64),
                    lineHeight: calcSizeForDevice(70),
                  },
                }}
                marginBottom={calcSizeForDevice(10, 0)}
                color="white">
                {program?.title}
              </Heading>
            )}
            {program?.genre?.[0].name && notShowOnLive && !program.hideSeasonsLabel && (
              <Text fontWeight="bold" color="white" fontSize={calcSizeForDevice(16)}>
                {numSeasons
                  ? `${numSeasons} TEMPORADA${numSeasons > 1 ? 'S' : ''}`
                  : program?.genre?.[0].name}
              </Text>
            )}
          </View>
          <View flexDirection="row" alignItems="center" mb={calcSizeForDevice(20)}>
            {program?.rating && (
              <View mr={calcSizeForDevice(16)}>
                <Rating ageRatingKey={program?.rating as AgeRatingKey} />
              </View>
            )}

            {program.rateDescription?.length > 0 && (
              <Text
                fontSize={calcSizeForDevice(18, 24)}
                lineHeight={calcSizeForDevice(24, 28)}
                marginRight={calcSizeForDevice(15)}
                color="white">
                {program.rateDescription}
              </Text>
            )}
            {notShowOnLive && program?.year && (
              <Text fontWeight="bold" color="white" fontSize={calcSizeForDevice(16, 24)}>
                {program?.year}
              </Text>
            )}
          </View>

          {!isSmallScreen && program?.summary && (
            <Text
              fontSize={calcSizeForDevice(18, 24)}
              lineHeight={calcSizeForDevice(24, 30)}
              marginBottom={calcSizeForDevice(10, 30)}
              maxWidth={Platform.isTV ? '100%' : '80%'}
              textAlign="justify"
              color="white">
              {program?.summary}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

export default ProgramHeader;
