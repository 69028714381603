import { AnimationResolver } from '@gluestack-style/animation-plugin';
import { styled } from '@gluestack-style/react';
import { createMenu } from '@gluestack-ui/menu';
import { ScrollView } from '@gluestack-ui/themed';
import * as MenuStyles from '@gluestack-ui/themed/build/components/Menu/styled-components';
import { Motion, createMotionAnimatedComponent } from '@legendapp/motion';

const MotionScrollview = createMotionAnimatedComponent(ScrollView) as typeof Motion.ScrollView;

const Root = styled(
  MotionScrollview,
  {
    //@ts-ignore
    ':initial': { opacity: 0 },
    ':animate': { opacity: 1 },
    ':exit': { opacity: 0 },
    ':transition': {
      type: 'spring',
      damping: 18,
      stiffness: 250,
      opacity: { type: 'timing', duration: 200 },
    },
    minWidths: 200,
    rounded: '$sm',
    defaultProps: { softShadow: '3' },
  },
  { componentName: 'Menu' },
  { plugins: [new AnimationResolver({})] }
);

export const Menu = createMenu({
  Root,
  Item: MenuStyles.Item,
  Label: MenuStyles.Label,
  Backdrop: MenuStyles.Backdrop,
  AnimatePresence: (Root as any).AnimatePresence,
});
