import { Button } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Platform } from 'utils/platform';

import { WebDiv } from 'components/WebDiv';

type GradientButtonProps = React.ComponentProps<typeof Button> & {
  gradientVariant?: 'primary' | 'secondary';
  useTVFocus?: boolean;
  children: React.ReactNode;
  onBtnFocus?: Function;
  onBtnBlur?: Function;
};

const GRADIENT_COLORS = {
  primary: ['#00AD5C', '#6DFFBC'],
  secondary: ['#2240FFF2', '#6DFFBCF2'],
  tertiary: ['#19285e', '#1e2c5e'],
};

const NewGradientButton = React.forwardRef<any, GradientButtonProps>(
  ({ gradientVariant = 'primary', children, useTVFocus = false, ...rest }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const baseOnFocus = () => (Platform.isAndroidTV && useTVFocus ? setIsFocused(true) : null);
    const baseOnBlur = () => (Platform.isAndroidTV && useTVFocus ? setIsFocused(false) : null);

    const onFocus = () => {
      baseOnFocus();
      rest.onBtnFocus?.();
    };

    const onBlur = () => {
      baseOnBlur();
      rest.onBtnBlur?.();
    };

    return (
      <WebDiv className="rounded-6">
        <LinearGradient
          colors={GRADIENT_COLORS[gradientVariant]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
          style={styles.gradient}>
          <Button
            id="linearGradientButton"
            ref={ref}
            role="button"
            onFocus={onFocus}
            onBlur={onBlur}
            variant="outline"
            borderWidth={isFocused ? 2 : 0}
            borderColor={isFocused ? 'red' : 'transparent'}
            borderRadius={styles.gradient.borderRadius}
            alignItems="center"
            justifyContent="center"
            backgroundColor="transparent"
            {...rest}>
            {children}
          </Button>
        </LinearGradient>
      </WebDiv>
    );
  }
);

const styles = StyleSheet.create({
  gradient: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    overflow: 'hidden',
  },
});

export default NewGradientButton;
