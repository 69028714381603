import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import {
  setCleanObject,
  setScreenAnalytics,
  setScreenAnalyticsClientId,
} from 'store/slices/screenAnalyticsSlice';
import { Platform } from 'utils/platform';

import { ComponentEventType, EventsTypesEnum, PageviewType } from './types';
import { useAnalyticsDriver } from './useAnalyticsDriver';

const useFirebaseAnalytics = () => {
  const dispatch = useDispatch();
  const analytics = useAnalyticsDriver();
  const user = useSelector((state: RootState) => state.auth);
  const clientId = useSelector<RootState>((state) => state.screenAnalytics.client_id);
  const defaultScreenObject = useSelector((state: RootState) => state.screenAnalytics);
  const platform = Platform.isTV ? 'ctv' : Platform.OS;

  const getPageLocation = (pageName: string) => `https://bandplay.com/${pageName}`;

  function buildComponentEventObject(
    params: ComponentEventType,
    screenObject?: Record<string, unknown>
  ) {
    const { adUnit, button_title, component, label, reference, target } = params;

    return {
      action: 'clique',
      target,
      client_id: clientId,
      user_id: user.bandId,
      component,
      reference,
      adUnit,
      button_title,
      label,
      platform,
      ...(screenObject || defaultScreenObject),
    };
  }

  function buildPageEventObject(params: PageviewType, screenObject?: Record<string, unknown>) {
    const { page_location, ...otherParams } = params;
    const formattedPageLocation = page_location ? getPageLocation(page_location) : undefined;

    const objFormatted = {
      ...otherParams,
      central: 'Band SP',
      channel: 'Band SP',
      user_id: user.bandId,
      client_id: clientId,
      page_location: formattedPageLocation,
      platform,
      ...(screenObject || defaultScreenObject),
    };
    otherParams?.season && (objFormatted.season = otherParams.season);
    otherParams?.midia && (objFormatted.midia = otherParams.midia);
    return objFormatted;
  }

  async function sendEventToServer(type: any, data: any) {
    const enableLog = false;

    if (__DEV__ && enableLog) {
      const obj = {
        ...data,
        type,
        date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      };

      await fetch('http://localhost:3008/logs', options);
    }
  }

  const logEventAction = (params: ComponentEventType) => {
    const payload = buildComponentEventObject(params);
    const payloadWithIdentification = { ...payload, client_id: clientId, user_id: user.bandId };
    sendEventToServer(EventsTypesEnum.ACTION, payloadWithIdentification);
    analytics.logEvent(EventsTypesEnum.ACTION, payloadWithIdentification);
  };

  const logEventActionName = (params: ComponentEventType, eventName: string) => {
    const payload = buildComponentEventObject(params);
    const payloadWithIdentification = { ...payload, client_id: clientId, user_id: user.bandId };
    sendEventToServer(eventName, payloadWithIdentification);
    analytics.logEvent(eventName, payloadWithIdentification);
  };

  const logEventActionWithScreen = (params: ComponentEventType, screenObject: any) => {
    const payload = buildComponentEventObject(params, screenObject);
    const payloadWithIdentification = { ...payload, client_id: clientId, user_id: user.bandId };
    sendEventToServer(EventsTypesEnum.ACTION, payloadWithIdentification);
    analytics.logEvent(EventsTypesEnum.ACTION, payloadWithIdentification);
  };

  const logEventPageView = (params: PageviewType) => {
    const payload = buildPageEventObject(params);
    const payloadWithIdentification = { ...payload, client_id: clientId, user_id: user.bandId };
    sendEventToServer(EventsTypesEnum.ACTION, payloadWithIdentification);
    analytics.logEvent(EventsTypesEnum.PAGEVIEW, payloadWithIdentification);
    dispatch(setScreenAnalytics(payloadWithIdentification));
  };

  const logEventPageCleanView = (params: PageviewType) => {
    const payload = buildPageEventObject(params, {});
    const payloadWithIdentification = { ...payload, client_id: clientId, user_id: user.bandId };
    sendEventToServer(EventsTypesEnum.ACTION, payloadWithIdentification);
    analytics.logEvent(EventsTypesEnum.PAGEVIEW, payloadWithIdentification);
    dispatch(setCleanObject(payloadWithIdentification));
  };

  async function setUserAppInstanceId() {
    const id = await analytics.getAppInstanceId();
    await analytics.setUserGaId(id!);
    dispatch(setScreenAnalyticsClientId(id));
  }

  return {
    setUserAppInstanceId,
    getPageLocation,
    logEventPageView,
    logEventAction,
    logEventPageCleanView,
    logEventActionWithScreen,
    logEventActionName,
  };
};

export default useFirebaseAnalytics;
