import { ButtonGroup, Pressable } from '@gluestack-ui/themed';
import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import BackButton from './BackButton';
import CleanButton from './CleanButton';
import GhostButton from './GhostButton';
import GradientButton from './GradientButton';
import OutlineButton from './OutlineButton';
import SidebarMenuButton, { MenuButtonProps } from './SidebarMenuButton';
import SolidButton from './SolidButton';

export type ButtonProps = TouchableOpacityProps &
  MenuButtonProps & {
    useTVFocus?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    children?: React.ReactNode;
    gradientVariant?: 'primary' | 'secondary' | 'newBtnColors';
    iconStyle?: object;
    iconType?: IconsName | undefined;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    isLoading?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    style?: object;
    textStyle?: object;
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | undefined;
    variant?:
      | 'back'
      | 'ghost'
      | 'gradient'
      | 'sidebar-menu'
      | 'solid'
      | 'outline'
      | 'invisible'
      | 'clean'
      | undefined;
  };

const Button = React.forwardRef<any, ButtonProps>(({ variant, ...props }, ref) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const baseOnFocus = () => (Platform.isAndroidTV && props.useTVFocus ? setIsFocused(true) : null);
  const baseOnBlur = () => (Platform.isAndroidTV && props.useTVFocus ? setIsFocused(false) : null);
  const extendedStyle = { borderWidth: 2, borderColor: isFocused ? 'red' : 'transparent' };

  const onFocus = (e: any) => {
    baseOnFocus();
    props?.onFocus?.(e);
  };

  const onBlur = (e: any) => {
    baseOnBlur();
    props?.onBlur?.(e);
  };

  const allProps = {
    ...props,
    onFocus,
    onBlur,
    style: {
      ...(Platform.isWebTV && { height: calcSizeForDevice(44, 70) }),
      ...props.style,
      ...extendedStyle,
    },
  };

  const renderButtonVariant = () => {
    switch (variant) {
      case 'back':
        return <BackButton ref={ref} {...allProps} />;
      case 'ghost':
        return <GhostButton ref={ref} {...allProps} />;
      case 'clean':
        return <CleanButton ref={ref} {...allProps} />;
      case 'outline':
        return <OutlineButton ref={ref} {...allProps} />;
      case 'sidebar-menu':
        return <SidebarMenuButton ref={ref} {...allProps} />;
      case 'solid':
        return <SolidButton ref={ref} {...allProps} />;
      case 'invisible':
        return <Pressable {...allProps} />;
      case 'gradient':
      default:
        return <GradientButton ref={ref} {...allProps} />;
    }
  };

  return <ButtonGroup>{renderButtonVariant()}</ButtonGroup>;
});

export default Button;
